import { defineMessages } from '@sevenrooms/core/locales'

export const autoTagSlideOutMessages = defineMessages({
  autoTagName: {
    id: 'autoTagSlideOut.autoTagName',
    defaultMessage: 'Auto-tag Name',
  },
  autoTagType: {
    id: 'autoTagSlideOut.autoTagType',
    defaultMessage: 'Auto-tag Type',
  },
  marketSegmentationCheckboxLabel: {
    id: 'autoTagSlideOut.marketSegmentationCheckboxLabel',
    defaultMessage: 'Use only for Marketing Segmentation',
  },
  marketSegmentationCheckboxDescription: {
    id: 'autoTagSlideOut.marketSegmentationCheckboxDescription',
    defaultMessage:
      'Any Auto-tag created in Admin will automatically be used for only marketing segmentation. The venue can change this on their specific venues if needed.',
  },
  general: {
    id: 'autoTagSlideOut.general',
    defaultMessage: 'General',
  },
  conditions: {
    id: 'autoTagSlideOut.conditions',
    defaultMessage: 'Conditions',
  },
  lastUpdated: {
    id: 'autoTagSlideOut.lastUpdated',
    defaultMessage: 'Last Updated {updated}',
  },
  createCustom: {
    id: 'autoTagSlideOut.createCustom',
    defaultMessage: 'Create Custom Auto-tag',
  },
  editCustom: {
    id: 'autoTagSlideOut.editCustom',
    defaultMessage: 'Edit Custom Auto-tag',
  },
  editDefault: {
    id: 'autoTagSlideOut.editDefault',
    defaultMessage: 'Edit Default Auto-tags',
  },
  updateSuccess: {
    id: 'autoTagSlideOut.updateSuccess',
    defaultMessage: 'Successfully updated auto-tag.',
  },
  updateError: {
    id: 'autoTagSlideOut.updateError',
    defaultMessage: 'Error updating auto-tag.',
  },
})
