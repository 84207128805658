import { useCallback } from 'react'
import type { EmailCampaignTemplate } from '@sevenrooms/core/api'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { VStack, HStack, Box, Modal, ModalHeader, ModalTitle, ModalFooter, ModalActions, ModalBody } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { adminOneTimeEmailCampaignTemplatesMessages } from '../../locales'

export interface AdminOneTimeEmailCampaignTemplateDeactivateModalProps {
  closeHref: string
  emailCampaignTemplate: EmailCampaignTemplate
  onDeactivate: (emailCampaignTemplate: EmailCampaignTemplate) => void
  isLoading: boolean
}

export function AdminOneTimeEmailCampaignTemplateDeactivateModal({
  emailCampaignTemplate,
  onDeactivate,
  closeHref,
  isLoading,
}: AdminOneTimeEmailCampaignTemplateDeactivateModalProps) {
  const { formatMessage } = useLocales()

  const onDeactivateConfirm = useCallback(() => {
    onDeactivate(emailCampaignTemplate)
  }, [emailCampaignTemplate, onDeactivate])

  return (
    <Modal data-test="deactivate-modal" ariaLabel={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.deactivateModalTitle)}>
      <ModalHeader closeHref={closeHref}>
        <VStack spacing="s">
          <HStack spacing="s">
            <Box>
              <Icon name="VMSWeb-warning" color="error" size="lg" />
            </Box>
            <Text color="error" fontWeight="bold">
              {formatMessage(commonMessages.warning)}
            </Text>
          </HStack>
          <ModalTitle title={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.deactivateModalTitle)} />
        </VStack>
      </ModalHeader>

      <ModalBody>
        <Text>{formatMessage(adminOneTimeEmailCampaignTemplatesMessages.deactivateModalBody)}</Text>
      </ModalBody>

      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" href={closeHref} data-test="deactivate-modal-cancel">
            {formatMessage(commonMessages.cancel)}
          </Button>
          <Button data-test="deactivate-modal-confirm" variant="primary-warning" onClick={onDeactivateConfirm} disabled={isLoading}>
            {formatMessage(adminOneTimeEmailCampaignTemplatesMessages.deactivateTemplate)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
