import { defineMessages } from '@sevenrooms/core/locales'

export const adminSyncBlobsMessages = defineMessages({
  title: {
    id: 'admin.syncBlobs.title',
    defaultMessage: 'Sync blobs',
  },
  venueConfigurationBreadcrumb: {
    id: 'admin.syncBlobs.venueConfigurationBreadcrumb',
    defaultMessage: 'Venue configuration',
  },
  syncBlobsBreadcrumb: {
    id: 'admin.syncBlobs.syncBlobsBreadcrumb',
    defaultMessage: 'Sync blobs',
  },
  date: {
    id: 'admin.syncBlobs.date',
    defaultMessage: 'Date',
  },
  model: {
    id: 'admin.syncBlobs.model',
    defaultMessage: 'Model',
  },
  search: {
    id: 'admin.syncBlobs.search',
    defaultMessage: 'Search',
  },
  showDeletedModels: {
    id: 'admin.syncBlobs.showDeletedModels',
    defaultMessage: 'Show deleted models',
  },
  useFancyDisplay: {
    id: 'admin.syncBlobs.useFancyDisplay',
    defaultMessage: 'Use fancy JSON viewer',
  },
  sectionCountsTitle: {
    id: 'admin.syncBlobs.sectionCountsTitle',
    defaultMessage: 'Counts',
  },
  sectionSyncBlobsTitle: {
    id: 'admin.syncBlobs.sectionSyncBlobsTitle',
    defaultMessage: 'Sync blobs',
  },
  reportCountsTitle: {
    id: 'admin.syncBlobs.reportCountsTitle',
    defaultMessage: 'Total models',
  },
  reportDeletedCountsTitle: {
    id: 'admin.syncBlobs.reportDeletedCountsTitle',
    defaultMessage: 'Deleted models',
  },
  rebuildDayModels: {
    id: 'admin.syncBlobs.rebuildDayModels',
    defaultMessage: 'Rebuild day models',
  },
  rebuildStaticModels: {
    id: 'admin.syncBlobs.rebuildStaticModels',
    defaultMessage: 'Rebuild static models',
  },
  rebuildTooltipTitle: {
    id: 'admin.syncBlobs.rebuildTooltipTitle',
    defaultMessage: "What's the difference?",
  },
  rebuildDayModelsTooltip: {
    id: 'admin.syncBlobs.rebuildDayModelsTooltip',
    defaultMessage:
      'Day models are data relevant only to the current day that is open in the app. These include Reservations, Clients, Shift Overrides, Access Rule Overrides, Waitlist Entries, SMS Messages, etc.',
  },
  rebuildStaticModelsTooltip: {
    id: 'admin.syncBlobs.rebuildStaticModelsTooltip',
    defaultMessage:
      'Static models are data relevant to all days, no matter which is open in the app. These include Base Shifts, Base Access Rules, Blocks, Floorplans, Venue Users, etc.',
  },
  dateTooltip: {
    id: 'admin.syncBlobs.dateTooltip',
    defaultMessage: 'Date is only relevant for day models. Static models are visible for all dates.',
  },
  showDeletedModelsTooltip: {
    id: 'admin.syncBlobs.showDeletedModelsTooltip',
    defaultMessage: 'Models that are soft-deleted continue to live in sync blobs. Uncheck this checkbox to only show non-deleted models.',
  },
  useFancyDisplayTooltip: {
    id: 'admin.syncBlobs.useFancyDisplayTooltip',
    defaultMessage:
      'Use the fancy JSON viewer to show sync blobs in a more readable format with syntax highlighting and block collapsing/expanding. Uncheck this checkbox to show raw JSON, which uses significantly less memory and can be useful if the browser tab crashes from too much data.',
  },
  searchTooltip: {
    id: 'admin.syncBlobs.searchTooltip',
    defaultMessage:
      'Search for a specific string in the sync blobs. This is a case-sensitive and very naive search. Depending on the search query and size of sync blobs, this might be slow - use with caution.',
  },
  allModels: {
    id: 'admin.syncBlobs.allModels',
    defaultMessage: 'All models',
  },
  noDataBannerTitle: {
    id: 'admin.syncBlobs.noDataBannerTitle',
    defaultMessage: 'Not seeing any data below?',
  },
  noDataBannerDescription: {
    id: 'admin.syncBlobs.noDataBannerDescription',
    defaultMessage:
      'Check that you have at least one model selected above and that your filter criteria are not too restrictive. If you still see no data, there may be no sync blobs for the selected date and model(s).',
  },
  datePlaceholder: {
    id: 'admin.syncBlobs.datePlaceholder',
    defaultMessage: 'Select date (required)',
  },
  modelPlaceholder: {
    id: 'admin.syncBlobs.modelPlaceholder',
    defaultMessage: 'Select model(s) (required)',
  },
  searchPlaceholder: {
    id: 'admin.syncBlobs.searchPlaceholder',
    defaultMessage: 'Enter search criteria (optional)',
  },
  fetchBlobs: {
    id: 'admin.syncBlobs.fetchBlobs',
    defaultMessage: 'Fetch blobs',
  },
  rebuildToastTitle: {
    id: 'admin.syncBlobs.rebuildToastTitle',
    defaultMessage: 'Sync rebuild in progress',
  },
  rebuildToastDescriptionStatic: {
    id: 'admin.syncBlobs.rebuildToastDescriptionStatic',
    defaultMessage:
      'Starting clean rebuild of static data. This may take a few minutes. Please wait to see updated data in the app. This page will need to be refreshed to see updated data.',
  },
  rebuildToastDescriptionDay: {
    id: 'admin.syncBlobs.rebuildToastDescriptionDay',
    defaultMessage:
      'Starting clean rebuild of day data for {date}. This may take a few minutes. Please wait to see updated data in the app. This page will need to be refreshed to see updated data.',
  },
})
