import React from 'react'
import { Helmet } from 'react-helmet'

export interface AdminPageMetaProps {
  title: string
}

export function AdminPageMeta({ title }: React.PropsWithChildren<AdminPageMetaProps>) {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  )
}
