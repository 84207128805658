import { Box, SimpleSection } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import type { LogItem } from './interfaces'

export function DisplayChangelogItem(props: { item: LogItem }) {
  const { item } = props
  return (
    <SimpleSection title={item.user} description={item.datetime}>
      <Box p="lm">
        <Text>{item.change}</Text>
      </Box>
    </SimpleSection>
  )
}
