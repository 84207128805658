import { useState, useMemo, useEffect } from 'react'
import * as React from 'react'
import {
  type ApiIntegrationType,
  useGetIntegrationTypesQuery,
  useAddIntegrationTypeMutation,
  useUpdateIntegrationTypeMutation,
  useDeleteIntegrationTypeMutation,
} from '@sevenrooms/core/api'
import { useForm, useWatchMany, z, type ZodSchema } from '@sevenrooms/core/form'
import { Button, FormInput, Label, SwitchSet } from '@sevenrooms/core/ui-kit/form'
import {
  Loader,
  Modal,
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  NotificationContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  VStack,
  Window,
  notify,
} from '@sevenrooms/core/ui-kit/layout'

const addIntegrationTypeForm = z.object({
  name: z.string(),
  type: z.string(),
})
export type addIntegrationTypeFormType = ZodSchema<typeof addIntegrationTypeForm>
const integrationTypes: { [index: string]: string } = {
  POS: 'Point of sale',
  '3RD_PARTY': 'Third party application',
}
const formDefault = { name: '', type: 'POS' }

export function AddUpdateIntegrationType(props: { updateItem?: ApiIntegrationType; children: React.ReactElement }) {
  const [showAddAudienceModal, setShowAddAudienceModal] = useState(false)
  const { updateItem, children } = props
  const isUpdate = !!updateItem

  const [updateIntegrationType, { isLoading: isUpdatingIntegrationType }] = useUpdateIntegrationTypeMutation()
  const [addIntegrationType, { isLoading: isAddingIntegrationType }] = useAddIntegrationTypeMutation()

  const form = useForm(addIntegrationTypeForm, {
    defaultValues: useMemo(() => (isUpdate ? { ...updateItem } : { ...formDefault }), [updateItem, isUpdate]),
  })

  const { field, handleSubmit, reset, formState } = form

  const onSubmit = async (data: addIntegrationTypeFormType) => {
    if (isUpdate) {
      await updateIntegrationType({ integrationTypeId: updateItem?.id, data })
    } else {
      await addIntegrationType({ data })
    }
    setShowAddAudienceModal(false)
    if (isUpdate) {
      reset()
    } else {
      reset(formDefault)
    }
  }

  const { isSubmitSuccessful } = formState
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(formDefault)
    } else if (isUpdate) {
      reset({ ...updateItem })
    }
  }, [isSubmitSuccessful, updateItem, reset, isUpdate])

  const [type] = useWatchMany(field, ['type'])

  return (
    <>
      {React.cloneElement(children, { onClick: () => setShowAddAudienceModal(true) })}

      <Window active={showAddAudienceModal} setIsActive={setShowAddAudienceModal}>
        <Modal ariaLabel="Base">
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader
              onClose={() => {
                setShowAddAudienceModal(false)
                reset()
              }}
            >
              <VStack spacing="s">
                <ModalTitle title={isUpdate ? 'Update integration type' : 'Add new integration type'} />
              </VStack>
            </ModalHeader>
            <ModalBody>
              {isUpdate ? (
                type
              ) : (
                <>
                  <SwitchSet
                    name="type"
                    choices={Object.keys(integrationTypes).map(key => ({
                      value: key,
                      label: integrationTypes[key] as string,
                    }))}
                    selected={type}
                    onChange={({ value }) => {
                      field.prop('type').set(value)
                    }}
                  />
                </>
              )}

              <Label width="100%" primary="Integration type name">
                <FormInput required field={field.prop('name')} />
              </Label>
            </ModalBody>
            <ModalFooter>
              <ModalActions>
                <Button
                  data-test="dismiss-button"
                  variant="secondary"
                  onClick={() => {
                    setShowAddAudienceModal(false)
                    reset()
                  }}
                >
                  Dismiss
                </Button>
                <Button
                  data-test="update-add-button"
                  disabled={isUpdatingIntegrationType || isAddingIntegrationType}
                  variant="primary"
                  type="submit"
                >
                  {isUpdate ? 'Update' : 'Add'}
                </Button>
              </ModalActions>
            </ModalFooter>
          </form>
        </Modal>
      </Window>
    </>
  )
}

export function ApiIntegrationTypesManager() {
  const getDataQuery = useGetIntegrationTypesQuery()

  const { isError, isFetching, data } = getDataQuery
  const [deleteId, setDeleteId] = useState('')
  const [showDeleteIntegrationTypeModal, setShowDeleteIntegrationTypeModal] = useState(false)

  const [deleteIntegrationType, { isLoading: isDeleting }] = useDeleteIntegrationTypeMutation()

  if (isFetching) {
    return <Loader />
  }

  return (
    <>
      {isError && 'Error happened'}

      <AddUpdateIntegrationType>
        <Button data-test="add-button">Add</Button>
      </AddUpdateIntegrationType>

      <Window active={showDeleteIntegrationTypeModal} setIsActive={setShowDeleteIntegrationTypeModal}>
        <Modal ariaLabel="Base">
          <ModalHeader
            onClose={() => {
              setShowDeleteIntegrationTypeModal(false)
            }}
          >
            <VStack spacing="s">
              <ModalTitle title="Delete integration type" />
            </VStack>
          </ModalHeader>
          <ModalBody />
          <ModalFooter>
            <ModalActions>
              <Button
                data-test="dismiss-integration-button"
                variant="secondary"
                onClick={() => {
                  setShowDeleteIntegrationTypeModal(false)
                }}
              >
                Dismiss
              </Button>
              <Button
                data-test="delete-integration-button"
                disabled={isDeleting}
                onClick={async () => {
                  const result = await deleteIntegrationType({ integrationTypeId: deleteId })
                  setShowDeleteIntegrationTypeModal(false)

                  if ('data' in result && !result?.data.status) {
                    notify({ content: 'Could not delete integration type. Client App is linked to it or the error happened' })
                  }
                }}
                variant="primary"
                type="submit"
              >
                Delete
              </Button>
            </ModalActions>
          </ModalFooter>
        </Modal>
      </Window>

      <Table>
        <thead>
          <tr>
            <TableCell isHeader>Name</TableCell>
            <TableCell isHeader>Type</TableCell>
            <TableCell isHeader />
          </tr>
        </thead>

        <TableBody>
          {data &&
            data.map((integrationType: ApiIntegrationType) => (
              <TableRow key={integrationType.id}>
                <TableCell>{integrationType.name}</TableCell>
                <TableCell>{integrationTypes[integrationType.type]}</TableCell>

                <TableCell>
                  {isDeleting && integrationType.id === deleteId ? (
                    <Loader />
                  ) : (
                    <VStack height="60px" justifyContent="space-between">
                      <AddUpdateIntegrationType updateItem={integrationType}>
                        <Button data-test="edit-integration-button" size="xs" variant="primary">
                          Edit
                        </Button>
                      </AddUpdateIntegrationType>
                      <Button
                        data-test="delete-integration-button"
                        size="xs"
                        variant="primary-warning"
                        onClick={() => {
                          setDeleteId(integrationType.id)
                          setShowDeleteIntegrationTypeModal(true)
                        }}
                      >
                        Delete
                      </Button>
                    </VStack>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <NotificationContainer />
    </>
  )
}
