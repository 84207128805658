import { defineMessages } from '@sevenrooms/core/locales'

export const customAutoTagConditionTreeEditorMessages = defineMessages({
  days: {
    id: 'customAutoTag.days',
    defaultMessage: 'day(s)',
  },
  times: {
    id: 'customAutoTag.times',
    defaultMessage: 'time(s)',
  },
  mins: {
    id: 'customAutoTag.mins',
    defaultMessage: 'mins(s)',
  },
  true: {
    id: 'customAutoTag.true',
    defaultMessage: 'True',
  },
  false: {
    id: 'customAutoTag.false',
    defaultMessage: 'False',
  },
  addOrCondition: {
    id: 'customAutoTag.addOrCondition',
    defaultMessage: 'Add "OR" Condition',
  },
  addAndCondition: {
    id: 'customAutoTag.addAndCondition',
    defaultMessage: 'Add "AND" Condition',
  },
} as const)
