import camelcaseKeys from 'camelcase-keys'
import { cleanNullish } from '@sevenrooms/core/api'
import type { BulkUpdateSettingsData as BulkUpdateSettingsDataApi } from '../../api'
import type { BulkUpdateSettingsData } from '../../domain'

export namespace BulkUpdateSettingsDataAdapter {
  export function toClient(data: BulkUpdateSettingsDataApi): BulkUpdateSettingsData {
    return cleanNullish(camelcaseKeys(data))
  }
}
