import type { OneTimeEmailCampaignTemplate } from '@sevenrooms/core/api'
import { OneTimeEmailCampaignStatusEnum, type EmailCampaignTemplateCategoryEnum } from '@sevenrooms/core/domain'
import type { Field, z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { FormInput, FormMultiSelect, Label } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { SecondaryText, StatusLabel, Text } from '@sevenrooms/core/ui-kit/typography'
import { useEmailCampaignTemplateStatusLabel } from '../../hooks'
import { adminOneTimeEmailCampaignTemplatesMessages } from '../../locales'
import type { adminOneTimeEmailCampaignTemplateDetailsForm } from './adminOneTimeEmailCampaignTemplateDetailsForm'

export interface AdminOneTimeEmailCampaignTemplateDetailsProps {
  field: Field<z.TypeOf<typeof adminOneTimeEmailCampaignTemplateDetailsForm>>
  emailCampaignTemplate: OneTimeEmailCampaignTemplate | undefined
  disabled: boolean
  isDuplicate: boolean
  categories: SelectOption<EmailCampaignTemplateCategoryEnum>[]
}

export function AdminOneTimeEmailCampaignTemplateDetails({
  emailCampaignTemplate,
  disabled,
  field,
  categories,
  isDuplicate,
}: AdminOneTimeEmailCampaignTemplateDetailsProps) {
  const { formatMessage } = useLocales()
  const statusLabel = useEmailCampaignTemplateStatusLabel(emailCampaignTemplate)
  const showStatusBanner = emailCampaignTemplate && statusLabel && !isDuplicate
  const showLastPublishedDate =
    emailCampaignTemplate &&
    OneTimeEmailCampaignStatusEnum.ACTIVE === emailCampaignTemplate.status &&
    emailCampaignTemplate?.lastPublished &&
    !isDuplicate

  return (
    <BaseSection title={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.templateDetails)}>
      <Box p="lm">
        <VStack spacing="lm">
          {showStatusBanner && (
            <VStack p="m" spacing="s" backgroundColor="secondaryBackground">
              <Box>
                <Text>{formatMessage(adminOneTimeEmailCampaignTemplatesMessages.templateStatus)}</Text>
                &nbsp;
                <StatusLabel variant={statusLabel.variant}>{statusLabel?.text}</StatusLabel>
              </Box>

              {showLastPublishedDate && (
                <Box>
                  <SecondaryText>
                    {formatMessage(adminOneTimeEmailCampaignTemplatesMessages.lastChangesPushed)}&nbsp;
                    {emailCampaignTemplate?.lastPublished?.formatNYearNMonthNDaySTime()}
                  </SecondaryText>
                </Box>
              )}
            </VStack>
          )}

          <HStack spacing="lm" alignItems="flex-start">
            <VStack spacing="lm" width="50%">
              <Label
                primary={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.templateNameTitle)}
                secondary={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.templateNameDescription)}
              >
                <FormInput autoFocus field={field?.prop('campaignName')} autoComplete="none" disabled={disabled} />
              </Label>
            </VStack>

            <VStack spacing="lm" width="50%" justifyContent="flex-end">
              <Label
                primary={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.templateCategoryTitle)}
                secondary={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.templateCategoryDescription)}
              >
                <FormMultiSelect
                  options={categories}
                  field={field.prop('templateCategories')}
                  scrollLock
                  placeholder={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.categoryFilterPlaceholder)}
                  disabled={disabled}
                  usePills={false}
                  useCheckboxItems
                  searchable
                  showRemoveAllButton
                />
              </Label>
            </VStack>
          </HStack>
        </VStack>
      </Box>
    </BaseSection>
  )
}
