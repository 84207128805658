import {
  EmailCampaignCategoryEnum,
  MarketingCampaignProductTypeEnum,
  MarketingCampaignRegionEnum,
  MarketingCampaignVenueTypeEnum,
} from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { FormSelect, FormInput, Label } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { adminOngoingEmailCampaignTemplateMessages } from '../../locales'
import type { AdminEmailDetailsFormField } from './AdminEmailDetailsForm.zod'

export interface EmailDetailsProps {
  field: AdminEmailDetailsFormField
  disabled?: boolean
}

export function AdminEmailDetails({ field, disabled }: EmailDetailsProps) {
  const { formatMessage } = useLocales()

  return (
    <BaseSection title={formatMessage(adminOngoingEmailCampaignTemplateMessages.emailDetails)}>
      <Box p="lm">
        <VStack spacing="lm">
          <HStack spacing="lm">
            <Label
              width="50%"
              primary={
                <>
                  {formatMessage(adminOngoingEmailCampaignTemplateMessages.campaignName)}
                  <Text color="error">&nbsp;*</Text>
                </>
              }
            >
              <FormInput field={field.prop('campaignName')} autoFocus disabled={disabled} />
            </Label>
            <Label
              width="50%"
              primary={
                <>
                  {formatMessage(adminOngoingEmailCampaignTemplateMessages.campaignDescription)}
                  <Text color="error">&nbsp;*</Text>
                </>
              }
            >
              <FormInput field={field.prop('campaignDescription')} disabled={disabled} />
            </Label>
          </HStack>

          <HStack spacing="lm">
            <Label
              width="50%"
              primary={
                <>
                  {formatMessage(adminOngoingEmailCampaignTemplateMessages.emailReplyTo)}
                  <Text color="error">&nbsp;*</Text>
                </>
              }
            >
              <FormInput field={field.prop('emailReplyTo')} autoFocus />
            </Label>
          </HStack>

          <HStack spacing="lm">
            <Label
              width="50%"
              primary={
                <>
                  {formatMessage(adminOngoingEmailCampaignTemplateMessages.productType)}
                  <Text color="error">&nbsp;*</Text>
                </>
              }
            >
              <FormSelect
                options={Object.entries(MarketingCampaignProductTypeEnum).map(([id, label]) => ({ id, label }))}
                field={field.prop('campaignProductType')}
                withEmpty
                hideEmptyOption
                disabled={disabled}
              />
            </Label>
            <Label
              width="50%"
              primary={
                <>
                  {formatMessage(adminOngoingEmailCampaignTemplateMessages.category)}
                  <Text color="error">&nbsp;*</Text>
                </>
              }
            >
              <FormSelect
                options={Object.entries(EmailCampaignCategoryEnum).map(([id, label]) => ({ id, label }))}
                field={field.prop('campaignCategory')}
                withEmpty
                hideEmptyOption
                disabled={disabled}
              />
            </Label>
          </HStack>

          <HStack spacing="lm">
            <Label
              width="50%"
              primary={
                <>
                  {formatMessage(adminOngoingEmailCampaignTemplateMessages.venueType)}
                  <Text color="error">&nbsp;*</Text>
                </>
              }
            >
              <FormSelect
                options={Object.entries(MarketingCampaignVenueTypeEnum).map(([id, label]) => ({ id, label }))}
                field={field.prop('campaignVenueType')}
                withEmpty
                hideEmptyOption
                disabled={disabled}
              />
            </Label>
            <Label
              width="50%"
              primary={
                <>
                  {formatMessage(adminOngoingEmailCampaignTemplateMessages.region)}
                  <Text color="error">&nbsp;*</Text>
                </>
              }
            >
              <FormSelect
                options={Object.entries(MarketingCampaignRegionEnum).map(([_id, label]) => ({ id: label, label }))}
                field={field.prop('campaignRegion')}
                withEmpty
                hideEmptyOption
                disabled={disabled}
              />
            </Label>
          </HStack>
        </VStack>
      </Box>
    </BaseSection>
  )
}
