import { srGet, srPostJson, throwFetchError } from '@sevenrooms/core/api'
import { BulkUpdateSettingsDataAdapter, SaveBulkUpdateSettingsAdapter } from '../adapters'
import type { BulkUpdateSettingsData, BulkUpdateSettingsResponse } from '../api'
import type { BulkUpdateSettingsRequest } from '../domain'

export namespace BulkUpdateSettingsService {
  export async function getBulkUpdateSettingsData() {
    const { data } = await srGet<BulkUpdateSettingsData>(`/api-yoa/admin/bulk_update_settings`).then(throwFetchError)
    return BulkUpdateSettingsDataAdapter.toClient(data)
  }

  export async function saveBulkUpdateSettings(args: BulkUpdateSettingsRequest) {
    const data = await srPostJson<BulkUpdateSettingsResponse>(
      `/api-yoa/admin/bulk_update_settings`,
      SaveBulkUpdateSettingsAdapter.toServer(args)
    ).then(throwFetchError)
    return data.data.success
  }
}
