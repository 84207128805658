import { useDispatch } from 'react-redux'
import { z, useForm } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { FormSelect } from '@sevenrooms/core/ui-kit/form'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { customAutoTagConditionTreeEditorMessages } from '../../../../../../../locales'
import { updateConditionTree } from '../../../../autoTagSlideOutSlice'

enum booleanSelectOptionIdEnum {
  TRUE = 'TRUE',
  FALSE = 'FALSE',
}

export function CustomAutoTagBooleanSelect({ path, selectedBoolean }: { path: (string | number)[]; selectedBoolean: boolean }) {
  const dispatch = useDispatch()
  const { formatMessage } = useLocales()

  const options: [SelectOption, SelectOption] = [
    { id: booleanSelectOptionIdEnum.TRUE, label: formatMessage(customAutoTagConditionTreeEditorMessages.true) },
    { id: booleanSelectOptionIdEnum.FALSE, label: formatMessage(customAutoTagConditionTreeEditorMessages.false) },
  ]

  const optionToValueMap = {
    [`${booleanSelectOptionIdEnum.TRUE}`]: true,
    [`${booleanSelectOptionIdEnum.FALSE}`]: false,
  }

  const formValues = z.object({
    value: z.string(),
  })
  const form = useForm(formValues, { defaultValues: { value: selectedBoolean ? options[0].id : options[1].id } })
  const { field } = form

  return (
    <Box width="260px">
      <FormSelect
        options={options}
        field={field.prop('value')}
        onChange={selected => {
          if (!selected || optionToValueMap[selected] === selectedBoolean) {
            return
          }

          const data = { value: selected === booleanSelectOptionIdEnum.TRUE }
          dispatch(updateConditionTree({ path, data }))
        }}
      />
    </Box>
  )
}
