import { render } from 'react-dom'
import { AdminPage } from '@sevenrooms/admin'
import { Switch, Route } from '@sevenrooms/core/navigation'
import { svrExport } from '@sevenrooms/core/ui-kit/utils'
import { routes } from '@sevenrooms/routes'
import { AdminOngoingEmailCampaignTemplate, AdminOngoingEmailCampaignTemplates } from './components'

function renderAdminEmailCampaignTemplates(id: string) {
  return render(
    <AdminPage>
      <Switch>
        <Route path={routes.admin.ongoingEmailCampaignTemplates.create.path}>
          <AdminOngoingEmailCampaignTemplate />
        </Route>

        <Route path={routes.admin.ongoingEmailCampaignTemplates.duplicate.path}>
          <AdminOngoingEmailCampaignTemplate isDuplicate />
        </Route>

        <Route path={routes.admin.ongoingEmailCampaignTemplates.edit.path}>
          <AdminOngoingEmailCampaignTemplate isEdit />
        </Route>

        <Route path={routes.admin.ongoingEmailCampaignTemplates.path}>
          <AdminOngoingEmailCampaignTemplates />
        </Route>
      </Switch>
    </AdminPage>,
    document.getElementById(id)
  )
}

svrExport('AdminOngoingEmailCampaignTemplates', 'render', renderAdminEmailCampaignTemplates)
