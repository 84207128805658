import { render } from 'react-dom'
import { AdminPage } from '@sevenrooms/admin'
import { svrExport } from '@sevenrooms/core/ui-kit/utils'
import { AdminBulkUpdateSettings } from './components'

function renderBulkUpdateVenueSettings(elementId: string) {
  return render(
    <AdminPage>
      <AdminBulkUpdateSettings />
    </AdminPage>,
    document.getElementById(elementId)
  )
}

svrExport('AdminBulkUpdateVenueSettings', 'render', renderBulkUpdateVenueSettings)
