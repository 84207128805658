import { useGetVenuePackageProvisioningDataQuery } from '@sevenrooms/core/api'
import { Loader } from '@sevenrooms/core/ui-kit/layout'
import { VenuePackageProvisioning } from './VenuePackageProvisioning'

interface VenuePackageProvisioningContainerProps {
  venueId: string
  venueName: string
}

export function VenuePackageProvisioningContainer({ venueId, venueName }: VenuePackageProvisioningContainerProps) {
  const { data, isLoading } = useGetVenuePackageProvisioningDataQuery({ venueId })

  return (
    <>
      {isLoading && <Loader />}
      {data && <VenuePackageProvisioning venueId={venueId} venueName={venueName} data={data} />}
    </>
  )
}
