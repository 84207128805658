import { useMemo } from 'react'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { adminSyncBlobsMessages } from '../../locales'

export type SyncBlobForm = ZodSchema<typeof useSyncBlobForm>

export function useSyncBlobForm() {
  return useMemo(
    () =>
      z.object({
        date: z.date(),
        modelNames: z.array(z.string()).refine(value => value.length > 0),
        useFancyDisplay: z.boolean(),
        showDeletedModels: z.boolean(),
        search: z.string(),
      }),
    []
  )
}

export function useSyncModelOptions(modelNames: string[]) {
  const { formatMessage } = useLocales()
  return [
    {
      id: 'ALL',
      label: formatMessage(adminSyncBlobsMessages.allModels),
    },
    ...modelNames.map(model => ({
      id: model,
      label: model,
    })),
  ]
}

interface GetDefaultSyncBlobFormProps {
  dateStart: Date
  modelNamesStart: string
  useFancyDisplayStart: boolean
  showDeletedModelsStart: boolean
  searchStart: string
}

export function getDefaultSyncBlobForm({
  dateStart,
  modelNamesStart,
  useFancyDisplayStart,
  showDeletedModelsStart,
  searchStart,
}: GetDefaultSyncBlobFormProps) {
  return {
    date: dateStart,
    modelNames: modelNamesStart.split(','),
    useFancyDisplay: useFancyDisplayStart,
    showDeletedModels: showDeletedModelsStart,
    search: searchStart,
  }
}
