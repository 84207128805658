import { z, type ZodSchema } from '@sevenrooms/core/form'

export type PackageProvisioningForm = ZodSchema<typeof usePackageProvisioningForm>

const packageProvisioningSchema = z.object({
  packageProvisioning: z.string(),
  addonApi: z.boolean(),
  addonOnlineOrdering: z.boolean(),
  addonEmailCampaigns: z.boolean(),
  addonPms: z.boolean(),
  addonSmsMarketing: z.boolean(),
  addonPremiumIntegrations: z.array(z.string()),
  betas: z.array(z.string()),
})

export function usePackageProvisioningForm() {
  return packageProvisioningSchema
}

export type PackageProvisioningFormSchema = z.infer<typeof packageProvisioningSchema>
