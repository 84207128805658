import { useMemo } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { Box, HStack, ReportLine, ReportPart, Report } from '@sevenrooms/core/ui-kit/layout'
import { adminSyncBlobsMessages } from '../../locales'

interface AdminSyncBlobsCountsProps {
  counts: Record<string, number>
  deletedCounts: Record<string, number>
  modelNames: string[]
  validModelNames: string[]
}

export function AdminSyncBlobCounts({ counts, deletedCounts, modelNames, validModelNames }: AdminSyncBlobsCountsProps) {
  const { formatMessage } = useLocales()

  const keys = useMemo(() => {
    const keys = modelNames.includes('ALL') ? validModelNames : Object.keys(counts)
    keys.sort()
    return keys
  }, [counts, modelNames, validModelNames])

  return (
    <HStack spacing="m" p="m">
      <Box width="50%">
        <Report>
          <ReportPart caption={formatMessage(adminSyncBlobsMessages.reportCountsTitle)}>
            {keys.map(key => (
              <ReportLine name={key} key={key}>
                {counts[key] ?? 0}
              </ReportLine>
            ))}
          </ReportPart>
        </Report>
      </Box>
      <Box width="50%">
        <Report>
          <ReportPart caption={formatMessage(adminSyncBlobsMessages.reportDeletedCountsTitle)}>
            {keys.map(key => (
              <ReportLine name={key} key={key}>
                {deletedCounts[key] ?? 0}
              </ReportLine>
            ))}
          </ReportPart>
        </Report>
      </Box>
    </HStack>
  )
}
