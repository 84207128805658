import { defineMessages } from '@sevenrooms/core/locales'

export const adminTimeslotViewerMessages = defineMessages({
  title: {
    id: 'admin.timeslotViewer.title',
    defaultMessage: 'Timeslot viewer',
  },
  venueConfigurationBreadcrumb: {
    id: 'admin.timeslotViewer.venueConfigurationBreadcrumb',
    defaultMessage: 'Venue configuration',
  },
  timeslotViewerBreadcrumb: {
    id: 'admin.timeslotViewer.timeslotViewerBreadcrumb',
    defaultMessage: 'Timeslot viewer',
  },
  searchForATimeslot: {
    id: 'admin.timeslotViewer.searchForATimeslot',
    defaultMessage: 'Search for a timeslot',
  },
  date: {
    id: 'admin.timeslotViewer.date',
    defaultMessage: 'Date',
  },
  time: {
    id: 'admin.timeslotViewer.time',
    defaultMessage: 'Time',
  },
  partySize: {
    id: 'admin.timeslotViewer.partySize',
    defaultMessage: 'Party size',
  },
  audience: {
    id: 'admin.timeslotViewer.audience',
    defaultMessage: 'Audience',
  },
  manuallyEnterAudience: {
    id: 'admin.timeslotViewer.manuallyEnterAudience',
    defaultMessage: 'Manually enter audience',
  },
  search: {
    id: 'admin.timeslotViewer.search',
    defaultMessage: 'Search',
  },
  noTimeslotsFoundMessage: {
    id: 'admin.timeslotViewer.noTimeslotsFoundMessage',
    defaultMessage: 'No timeslots found for the selected criteria',
  },
  timeslotsFoundMessage: {
    id: 'admin.timeslotViewer.timeslotsFoundMessage',
    defaultMessage: '{count, plural, one {1 timeslot found} other {{count} timeslots found}}',
  },
  timeslotTitle: {
    id: 'admin.timeslotViewer.timeslotTitle',
    defaultMessage: 'Timeslot {num}',
  },
  bannerTitle: {
    id: 'admin.timeslotViewer.bannerTitle',
    defaultMessage: 'Not seeing a timeslot you expect?',
  },
  bannerDescription: {
    id: 'admin.timeslotViewer.bannerDescription',
    defaultMessage: 'Use Availability Quick View to debug availability issues with more granular search criteria.',
  },
  bannerAction: {
    id: 'admin.timeslotViewer.bannerAction',
    defaultMessage: 'Go to Availability Quick View',
  },
})
