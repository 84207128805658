import { useLocales } from '@sevenrooms/core/locales'
import { Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Link } from '@sevenrooms/core/ui-kit/typography'
import { paymentsDebuggerMessages } from '../../locales'
import type { DebugData } from './interfaces'

export function DisplayLogExplorer(props: { data: DebugData }) {
  const { data } = props
  const appId = data?.app.APP_ID
  const { formatMessage } = useLocales()

  return (
    <VStack>
      {data?.app.IS_DEVELOPMENT && (
        <p>
          <em>{formatMessage(paymentsDebuggerMessages.devWarning)}</em>
        </p>
      )}
      <Box>
        <Link
          isExternal
          to={`https://console.cloud.google.com/logs/query;query="${data?.venue.id}"%0Aresource.type%3D"gae_app"%0Aresource.labels.module_id%3D"default"?project=${appId}`}
          target="_blank"
          rel="noreferrer"
        >
          {formatMessage(paymentsDebuggerMessages.goToLogs)}
        </Link>
      </Box>
      <Box>
        <div>{formatMessage(paymentsDebuggerMessages.possibleIpFilters)}</div>
        {data?.usefulIps?.map((ip: string) => (
          <pre key={ip}>protoPayload.ip="{ip}"</pre>
        ))}

        <div>{formatMessage(paymentsDebuggerMessages.possibleUrlFilters)}</div>

        <pre>"{data?.venue.id}"</pre>
        <pre>"{data?.venue.urlKey}"</pre>
      </Box>
    </VStack>
  )
}
