import { defineMessages } from '@sevenrooms/core/locales'

export const defaultAutoTagTierDescriptions = defineMessages({
  ANNIVERSARY_LOCAL: {
    id: 'defaultAutoTag.tierDescription.anniversaryLocal',
    defaultMessage: "This tag will be automatically applied when the client's anniversary is within a certain time window.",
  },
  ANNIVERSARY: {
    id: 'defaultAutoTag.tierDescription.anniversary',
    defaultMessage: "This tag will be automatically applied when the client's anniversary is within a certain time window.",
  },
  BIRTHDAY_LOCAL: {
    id: 'defaultAutoTag.tierDescription.birthdayLocal',
    defaultMessage: "This tag will be automatically applied when the client's birthday is within a certain time window.",
  },
  BIRTHDAY: {
    id: 'defaultAutoTag.tierDescription.birthday',
    defaultMessage: "This tag will be automatically applied when the client's birthday is within a certain time window.",
  },
  FIRST_TIMER: {
    id: 'defaultAutoTag.tierDescription.firstTimer',
    defaultMessage: "This tag will be automatically be applied when it is the guest's first time visiting your location.",
  },
  FIRST_TIMER_GLOBAL: {
    id: 'defaultAutoTag.tierDescription.firstTimerGlobal',
    defaultMessage: "This tag will be automatically be applied when it is the guest's first time visiting a location within the group.",
  },
  REVIEWER: {
    id: 'defaultAutoTag.tierDescription.reviewer',
    defaultMessage: 'This tag will automatically appear on a guest profile when a review is linked or feedback submitted.',
  },
  SPEND_LOCAL: {
    id: 'defaultAutoTag.tierDescription.spendLocal',
    defaultMessage:
      'This tag will be automatically be applied when a guest has reached a certain threshold for total spend at this location.',
  },
  SPEND_GLOBAL: {
    id: 'defaultAutoTag.tierDescription.spendGlobal',
    defaultMessage:
      'This tag will be automatically be applied when a guest has reached a certain threshold for total spend across all of your locations.',
  },
  VISITS_GLOBAL: {
    id: 'defaultAutoTag.tierDescription.visitsGlobal',
    defaultMessage: 'Set up multiple tiers to make sure you are getting the most information about your guests.',
  },
  VISITS_LOCAL: {
    id: 'defaultAutoTag.tierDescription.visitsLocal',
    defaultMessage: 'Set up multiple tiers to make sure you are getting the most information about your guests.',
  },
  WE_MISS_YOU: {
    id: 'defaultAutoTag.tierDescription.weMissYou',
    defaultMessage:
      "This tag will be automatically applied when a certain amount of time has passed since the guest's last visit to your specific venue.",
  },
  WE_MISS_YOU_GLOBAL: {
    id: 'defaultAutoTag.tierDescription.weMissYouGlobal',
    defaultMessage:
      "This tag will be automatically applied when a certain amount of time has passed since the guest's last visit to any venue in the group.",
  },
  BOOKED_BY_THIRD_PARTY: {
    id: 'defaultAutoTag.tierDescription.bookedByThirdParty',
    defaultMessage: 'This tag will be automatically applied to guests who booked through a third-party app.',
  },
  MISSED_VISITS: {
    id: 'defaultAutoTag.tierDescription.missedVisits',
    defaultMessage: 'This tag will be automatically applied to guests who missed their visit.',
  },
  UPCOMING_RESERVATIONS: {
    id: 'defaultAutoTag.tierDescription.upcomingReservations',
    defaultMessage: 'This tag will be automatically applied to guests who have an upcoming reservation.',
  },
  REFERRER: {
    id: 'defaultAutotag.tierDescription.referrer',
    defaultMessage:
      'This tag will be automatically applied when a guest has generated a certain threshold of new guest referrals. A new guest is a guest that has never dined in at any venue in the group before.',
  },
  RESERVATION_REQUESTS: {
    id: 'defaultAutotag.tierDescription.reservationRequests',
    defaultMessage:
      'This tag will be automatically applied to guests who have made a certain number of reservation requests which did not result in a completed reservation.',
  },
  VISITS_LOCAL_MARKETING_SEGMENTATION: {
    id: 'defaultAutotag.tierDescription.visitsLocalMarketingSegmentation',
    defaultMessage: 'This tag will be automatically applied to guests who have a certain number of completed reservations.',
  },
  CUSTOM_GLOBAL: {
    id: 'defaultAutoTag.tierDescription.customGlobal',
    defaultMessage: '',
  },
  CUSTOM_LOCAL: {
    id: 'defaultAutoTag.tierDescription.customLocal',
    defaultMessage: '',
  },
  CUSTOM_GLOBAL_MARKETING_SEGMENTATION: {
    id: 'defaultAutoTag.tierDescription.customGlobalMarketingSegmentation',
    defaultMessage: '',
  },
  CUSTOM_LOCAL_MARKETING_SEGMENTATION: {
    id: 'defaultAutoTag.tierDescription.customLocalMarketingSegmentation',
    defaultMessage: '',
  },
} as const)
