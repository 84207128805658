import { createApi } from '@reduxjs/toolkit/query/react'
import { RTKUtils } from '@sevenrooms/core/api'
import { TimeslotViewerService, type GetTimeslotsParams } from '../services'

export const timeslotViewerApi = createApi({
  reducerPath: 'admin.timeslotViewer',
  tagTypes: ['TimeslotViewer'],
  baseQuery: RTKUtils.noBaseQuery,
  endpoints: builder => ({
    getTimeslots: builder.query<Record<string, unknown>[], GetTimeslotsParams>({
      providesTags: ['TimeslotViewer'],
      queryFn: RTKUtils.rtkQuery(TimeslotViewerService.getTimeslots),
    }),
  }),
})

export const { useLazyGetTimeslotsQuery } = timeslotViewerApi
