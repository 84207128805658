import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface AdminOngoingEmailCampaignTemplatesConfirmDeleteModalState {
  templateId: string
  rowIndex: number
}

const initialState = { templateId: '', rowIndex: -1 }

const adminOngoingEmailCampaignTemplatesConfirmDeleteModalSlice = createSlice({
  name: 'adminOngoingEmailCampaignTemplatesConfirmDeleteModal',
  initialState,
  reducers: {
    setState: (state, action: PayloadAction<AdminOngoingEmailCampaignTemplatesConfirmDeleteModalState>) => {
      Object.assign(state, action.payload)
    },
    resetState: () => initialState,
  },
})

const { actions } = adminOngoingEmailCampaignTemplatesConfirmDeleteModalSlice
export const { setState, resetState } = actions
export const {
  reducer: adminOngoingEmailCampaignTemplatesConfirmDeleteModalReducer,
  name: adminOngoingEmailCampaignTemplatesConfirmDeleteModalName,
} = adminOngoingEmailCampaignTemplatesConfirmDeleteModalSlice
