import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AdminCustomDomainsService, RTKUtils } from '@sevenrooms/core/api'
import type { CustomDomain } from '@sevenrooms/core/domain'

export const customDomainApi = createApi({
  reducerPath: 'admin.customDomains',
  baseQuery: fetchBaseQuery({ baseUrl: '/api-yoa/' }),
  tagTypes: ['CustomDomain'],
  endpoints: builder => ({
    getCustomDomains: builder.query<CustomDomain[], void>({
      providesTags: ['CustomDomain'],
      queryFn: RTKUtils.rtkQuery(AdminCustomDomainsService.getCustomDomains),
    }),
    refreshCustomDomains: builder.mutation<CustomDomain[], void>({
      invalidatesTags: ['CustomDomain'],
      queryFn: RTKUtils.rtkQuery(AdminCustomDomainsService.refreshCustomDomains),
    }),
  }),
})

export const { useGetCustomDomainsQuery, useRefreshCustomDomainsMutation } = customDomainApi
