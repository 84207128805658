import { createSlice } from '@reduxjs/toolkit'
import type { EmailCampaignStatus } from '@sevenrooms/core/domain'

export interface AdminOngoingEmailCampaignTemplatesToggleStatusModalState {
  emailCampaignTemplateIds: string[]
  updatedStatus: EmailCampaignStatus
}

const adminOngoingEmailCampaignTemplatesToggleStatusModalSlice = createSlice({
  name: 'adminOngoingEmailCampaignTemplatesToggleStatusModal',
  initialState: null,
  reducers: {
    setAdminOngoingEmailCampaignTemplatesToggleStatusModalState: (_, param) => ({ ...param.payload }),
    clearAdminOngoingEmailCampaignTemplatesToggleStatusModalState: () => null,
  },
})

const { actions } = adminOngoingEmailCampaignTemplatesToggleStatusModalSlice
export const {
  setAdminOngoingEmailCampaignTemplatesToggleStatusModalState,
  clearAdminOngoingEmailCampaignTemplatesToggleStatusModalState,
} = actions
export const { reducer: adminOngoingEmailCampaignTemplatesToggleStatusModalReducer } =
  adminOngoingEmailCampaignTemplatesToggleStatusModalSlice
