import type { OneTimeEmailCampaignTemplate } from '@sevenrooms/core/api'
import { OneTimeEmailCampaignStatusEnum } from '@sevenrooms/core/domain'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { ActionsButton, Button } from '@sevenrooms/core/ui-kit/form'
import { routes } from '@sevenrooms/routes'
import { AdminOneTimeEmailCampaignTemplatesTabs } from '../../const'
import { adminOneTimeEmailCampaignTemplatesMessages } from '../../locales'

export interface AdminOneTimeEmailCampaignTemplateActionsProps {
  emailCampaignTemplate?: OneTimeEmailCampaignTemplate
  onSaveAsDraft: () => void
  onSaveInAdmin: () => void
  disabled: boolean
  isDirty: boolean
  isDuplicate: boolean
}

export function AdminOneTimeEmailCampaignTemplateActions({
  isDuplicate,
  emailCampaignTemplate,
  onSaveAsDraft,
  onSaveInAdmin,
  disabled,
  isDirty,
}: AdminOneTimeEmailCampaignTemplateActionsProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()

  if (emailCampaignTemplate && !isDuplicate) {
    const templateId = emailCampaignTemplate?.id

    if (emailCampaignTemplate.status === OneTimeEmailCampaignStatusEnum.DRAFT) {
      return (
        <>
          <Button
            variant="tertiary"
            data-test="sr-admin-one-time-email-campaign-template-cancel"
            onClick={() => {
              if (isDirty) {
                nav.push(routes.admin.oneTimeEmailCampaignsTemplates.edit.unsavedModal, {
                  params: { templateId },
                })
              } else {
                nav.push(routes.admin.oneTimeEmailCampaignsTemplates, {
                  queryMode: 'replace',
                  query: { tab: AdminOneTimeEmailCampaignTemplatesTabs.DRAFT },
                })
              }
            }}
          >
            {formatMessage(commonMessages.cancel)}
          </Button>

          <ActionsButton
            disabled={disabled}
            actions={[
              {
                id: 'sr-admin-one-time-email-campaign-template-save-as-draft',
                title: formatMessage(commonMessages.saveAsDraft),
                onClick: onSaveAsDraft,
              },
              {
                id: 'sr-admin-one-time-email-campaign-template-save-and-publish',
                title: formatMessage(commonMessages.saveAndPublish),
                onClick: () => {
                  nav.push(routes.admin.oneTimeEmailCampaignsTemplates.create.publishModal)
                },
              },
              {
                id: 'sr-admin-one-time-email-campaign-template-duplicate',
                title: formatMessage(commonMessages.duplicate),
                onClick: () => {
                  if (isDirty) {
                    nav.push(routes.admin.oneTimeEmailCampaignsTemplates.edit.duplicateModal, { params: { templateId } })
                  } else {
                    nav.push(routes.admin.oneTimeEmailCampaignsTemplates.duplicate, {
                      params: { templateId },
                      queryMode: 'clear',
                    })
                  }
                },
              },
              {
                id: 'sr-admin-one-time-email-campaign-template-archive',
                title: formatMessage(commonMessages.archive),
                onClick: () => {
                  nav.push(routes.admin.oneTimeEmailCampaignsTemplates.edit.archiveModal, { params: { templateId } })
                },
              },
            ]}
            data-test="admin-one-time-email-campaign-template-actions"
          />
        </>
      )
    } else if (emailCampaignTemplate.status === OneTimeEmailCampaignStatusEnum.ACTIVE) {
      return (
        <>
          <Button
            variant="tertiary"
            data-test="sr-admin-one-time-email-campaign-template-cancel"
            onClick={() => {
              if (isDirty) {
                nav.push(routes.admin.oneTimeEmailCampaignsTemplates.edit.unsavedModal, {
                  params: { templateId },
                })
              } else {
                nav.push(routes.admin.oneTimeEmailCampaignsTemplates, {
                  queryMode: 'replace',
                  query: { tab: AdminOneTimeEmailCampaignTemplatesTabs.ACTIVE },
                })
              }
            }}
          >
            {formatMessage(commonMessages.cancel)}
          </Button>

          <Button
            data-test="sr-admin-one-time-email-campaign-template-deactivate"
            variant="secondary-warning"
            type="submit"
            disabled={disabled}
            onClick={() => {
              nav.push(routes.admin.oneTimeEmailCampaignsTemplates.edit.deactivateModal, { params: { templateId } })
            }}
          >
            {formatMessage(commonMessages.deactivate)}
          </Button>

          <ActionsButton
            disabled={disabled}
            actions={[
              {
                id: 'sr-admin-one-time-email-campaign-template-save-changes',
                title: formatMessage(adminOneTimeEmailCampaignTemplatesMessages.saveChangesInAdmin),
                onClick: onSaveInAdmin,
              },
              {
                id: 'sr-admin-one-time-email-campaign-template-save-and-publish',
                title: formatMessage(commonMessages.saveAndPublish),
                onClick: () => {
                  nav.push(routes.admin.oneTimeEmailCampaignsTemplates.create.publishModal)
                },
              },
              {
                id: 'sr-admin-one-time-email-campaign-template-duplicate',
                title: formatMessage(commonMessages.duplicate),
                onClick: () => {
                  if (isDirty) {
                    nav.push(routes.admin.oneTimeEmailCampaignsTemplates.edit.duplicateModal, { params: { templateId } })
                  } else {
                    nav.push(routes.admin.oneTimeEmailCampaignsTemplates.duplicate, {
                      params: { templateId },
                      queryMode: 'clear',
                    })
                  }
                },
              },
            ]}
            data-test="admin-one-time-email-campaign-template-actions"
          />
        </>
      )
    } else if (emailCampaignTemplate?.status === OneTimeEmailCampaignStatusEnum.INACTIVE) {
      return (
        <>
          <Button
            variant="tertiary"
            data-test="sr-admin-one-time-email-campaign-template-cancel"
            onClick={() => {
              nav.push(routes.admin.oneTimeEmailCampaignsTemplates, {
                queryMode: 'replace',
                query: { tab: AdminOneTimeEmailCampaignTemplatesTabs.INACTIVE },
              })
            }}
          >
            {formatMessage(commonMessages.cancel)}
          </Button>

          <Button
            data-test="sr-admin-one-time-email-campaign-template-unarchive"
            variant="secondary"
            type="button"
            disabled={disabled}
            onClick={() => {
              nav.push(routes.admin.oneTimeEmailCampaignsTemplates.edit.unarchiveModal, { params: { templateId } })
            }}
          >
            {formatMessage(commonMessages.unarchive)}
          </Button>

          <Button
            data-test="sr-admin-one-time-email-campaign-template-duplicate-as-draft"
            variant="primary"
            type="submit"
            disabled={disabled}
            onClick={() => {
              nav.push(routes.admin.oneTimeEmailCampaignsTemplates.duplicate, {
                params: { templateId },
              })
            }}
          >
            {formatMessage(adminOneTimeEmailCampaignTemplatesMessages.duplicateAsDraft)}
          </Button>
        </>
      )
    }
  }

  return (
    <>
      <Button
        variant="secondary-warning"
        data-test="sr-admin-one-time-email-campaign-template-cancel"
        onClick={() => {
          nav.push(routes.admin.oneTimeEmailCampaignsTemplates, { queryMode: 'clear' })
        }}
      >
        {formatMessage(adminOneTimeEmailCampaignTemplatesMessages.discardDraft)}
      </Button>

      <ActionsButton
        disabled={disabled}
        actions={[
          {
            id: 'sr-admin-one-time-email-campaign-template-save-as-draft',
            title: formatMessage(commonMessages.saveAsDraft),
            onClick: onSaveAsDraft,
          },
          {
            id: 'sr-admin-one-time-email-campaign-template-save-and-publish',
            title: formatMessage(commonMessages.saveAndPublish),
            onClick: () => {
              nav.push(routes.admin.oneTimeEmailCampaignsTemplates.create.publishModal)
            },
          },
        ]}
        data-test="sr-admin-one-time-email-campaign-template-actions"
      />
    </>
  )
}
