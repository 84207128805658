import { Checkbox } from '@sevenrooms/core/ui-kit/form'
import { FluidControl, type FluidControlProps } from './FluidControl'

interface FluidCheckboxProps extends Omit<FluidControlProps<boolean>, 'primary' | 'secondary'> {
  text: string
  info: string
  disabled?: boolean
}

export function FluidCheckbox({ field, text, info, disabled }: FluidCheckboxProps) {
  return (
    <FluidControl field={field} primary={text}>
      <Checkbox field={field} info={<>{info}</>} disabled={disabled} />
    </FluidControl>
  )
}
