import _ from 'lodash'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useGetAdminOngoingEmailCampaignTemplatesQuery, AdminOngoingEmailCampaignTemplateFilterContext } from '@sevenrooms/core/api'
import { EmailCampaignCategoryEnum, MarketingCampaignProductTypeEnum, MarketingCampaignVenueTypeEnum } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { format } from '@sevenrooms/core/timepiece'
import { Loader } from '@sevenrooms/core/ui-kit/layout'
import { adminOngoingEmailCampaignTemplateMessages } from '../../locales'
import {
  AdminOngoingEmailCampaignTemplatesTable,
  type AdminOngoingEmailCampaignTemplatesTableRow,
} from './AdminOngoingEmailCampaignTemplatesTable'
import { setAdminOngoingEmailCampaignTemplatesTableRows } from './adminOngoingEmailCampaignTemplatesTableSlice'

export function AdminOngoingEmailCampaignTemplates() {
  const { data, isSuccess, isLoading } = useGetAdminOngoingEmailCampaignTemplatesQuery({ campaignCategory: undefined })
  const { formatMessage } = useLocales()
  const dispatch = useDispatch()

  useMemo(() => {
    if (isSuccess && data) {
      const campaignTemplatesData: AdminOngoingEmailCampaignTemplatesTableRow[] = []
      const { results } = data
      const sortedCampaignTemplates = _.orderBy(results, [
        template => _.toLower(template?.campaignName),
        template => template.updated?.toJsDate(),
      ])

      for (const template of sortedCampaignTemplates) {
        const templateData = {
          templateName: template.campaignName,
          venueType: template.campaignVenueType ? MarketingCampaignVenueTypeEnum[template.campaignVenueType] : undefined,
          region: template.campaignRegion,
          lastUpdated: template.updated?.toJsDate() && format(template.updated?.toJsDate(), 'Pp'),
          defaultStatus: template.copyByDefault
            ? formatMessage(adminOngoingEmailCampaignTemplateMessages.on)
            : formatMessage(adminOngoingEmailCampaignTemplateMessages.off),
          status: template.status,
          productType: MarketingCampaignProductTypeEnum[template.campaignProductType],
          campaignCategory: template.campaignCategory ? EmailCampaignCategoryEnum[template.campaignCategory] : undefined,
          id: template.id,
          hasNewUpdates: template.hasNewUpdates,
        }
        campaignTemplatesData.push(templateData)
      }
      dispatch(setAdminOngoingEmailCampaignTemplatesTableRows(campaignTemplatesData))
    }
  }, [isSuccess, data, formatMessage, dispatch])

  return (
    <AdminOngoingEmailCampaignTemplateFilterContext>
      {isLoading ? <Loader /> : <AdminOngoingEmailCampaignTemplatesTable />}
    </AdminOngoingEmailCampaignTemplateFilterContext>
  )
}
