import { render } from 'react-dom'
import { AdminPage } from '@sevenrooms/admin'
import { svrExport } from '@sevenrooms/core/ui-kit/utils'
import { VenueGroupHistoryTracking } from './components'

function renderVenueGroupHistoryTracking(id: string, venueGroupId: string, venueGroupName: string) {
  return render(
    <AdminPage>
      <VenueGroupHistoryTracking venueGroupId={venueGroupId} venueGroupName={venueGroupName} />
    </AdminPage>,
    document.getElementById(id)
  )
}

svrExport('VenueGroupHistoryTracking', 'render', renderVenueGroupHistoryTracking)
