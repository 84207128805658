import { useState } from 'react'
import { useGetPaymentsDebuggerDataQuery } from '@sevenrooms/core/api'
import { useForm, z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { Button, Form, FormSelect, Label, FormInput } from '@sevenrooms/core/ui-kit/form'
import { Box, Loader, StackResponsive } from '@sevenrooms/core/ui-kit/layout'
import { Status } from '@sevenrooms/core/ui-kit/typography'
import { routes } from '@sevenrooms/routes'
import { paymentsDebuggerMessages } from '../../locales'
import { PaymentsDebuggerResults } from './PaymentsDebuggerResults'

const options: SelectOption<string>[] = ['Actual ID', 'Reference code', 'Charge ID', 'Invoice ID'].map((label, index) => ({
  id: `${index + 1}`,
  label,
}))

const formValues = z.object({
  searchString: z.string(),
  searchBy: z.string(),
})

export function PaymentsDebugger(props: { venueId: string }) {
  const nav = useNavigation()

  const { searchBy, searchString } = {
    ...{
      searchBy: options[0]?.id ?? '',
      searchString: '',
    },
    ...nav.matchQuery(routes.admin.paymentsDebugger),
  }
  const { formatMessage } = useLocales()

  const formDefault = {
    searchBy,
    searchString,
  }

  const [state, setState] = useState({
    selectedIndex: 0,
    ...formDefault,
  })

  const { venueId } = props

  const form = useForm(formValues, { defaultValues: formDefault })
  const { field } = form

  const getDataQuery = useGetPaymentsDebuggerDataQuery({
    venueId,
    searchString,
    searchBy,
  })
  const { isFetching, isError, error } = getDataQuery
  let { data } = getDataQuery
  if (isError) {
    data = undefined
  }

  const hasActuals = !!data?.actuals.length
  let errString = formatMessage(paymentsDebuggerMessages.errorString)
  if (error instanceof Error) {
    errString = formatMessage(paymentsDebuggerMessages.errorStringWithMessage, { error: error.message })
  }

  return (
    <>
      <Form
        {...form}
        onInvalid={() => {}}
        onSubmit={(data: { searchString: string; searchBy: string }) => {
          setState({
            ...state,
            searchString: data.searchString,
            searchBy: data.searchBy,
          })
          nav.push(routes.admin.paymentsDebugger, {
            params: { venueId },
            query: {
              searchString: data.searchString,
              searchBy: data.searchBy,
            },
          })
        }}
      >
        <StackResponsive width="100%" spacing="s">
          <Label width="100%" primary={formatMessage(paymentsDebuggerMessages.searchBy)}>
            <FormInput required field={field.prop('searchString')} />
          </Label>
          <Label width="100%" primary={formatMessage(paymentsDebuggerMessages.searchBy)}>
            <FormSelect field={field.prop('searchBy')} options={options} searchable />
          </Label>
          <Label width="100px" primary="&nbsp;">
            <Button type="submit" data-test="go-button">
              {formatMessage(paymentsDebuggerMessages.go)}
            </Button>
          </Label>
        </StackResponsive>
      </Form>

      {isError && (
        <Box p="m">
          <Status kind="warning">{errString}</Status>
        </Box>
      )}
      {data?.error && (
        <Box p="m">
          <Status kind="warning">{data?.error}</Status>
        </Box>
      )}
      {!isError && !hasActuals && !isFetching && state.searchBy && (
        <Box p="m">
          <Status kind="warning">{formatMessage(paymentsDebuggerMessages.nothingFound)}</Status>
        </Box>
      )}

      {isFetching || (isError && !data) ? <Loader /> : <PaymentsDebuggerResults data={data} />}
    </>
  )
}
