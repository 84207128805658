import { useState, useCallback } from 'react'
import { AdminPageContent, AdminPageMeta } from '@sevenrooms/admin/components'
import { useLazyGetEmailStatusQuery, type BlockStatus, useUnblockEmailMutation } from '@sevenrooms/admin-lib-manage-blocklist'
import { useLocales } from '@sevenrooms/core/locales'
import { Input } from '@sevenrooms/core/ui-kit/core'
import { Button, Label, LoaderButton } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, HStack, VStack, NotificationContainer, notify, Loader } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { adminManageBlocklistMessages } from '../locales'

export function AdminManageBlocklist() {
  const { formatMessage } = useLocales()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [blockStatus, setBlockStatus] = useState<BlockStatus | null>(null)
  const [email, setEmail] = useState<string>('')

  const [getEmailStatus, { isLoading: isEmailStatusLoading }] = useLazyGetEmailStatusQuery()
  const [unblockEmailMutation] = useUnblockEmailMutation()

  const doGetEmailStatus = useCallback(async () => {
    const sanitizedEmail = email.trim()
    if (!sanitizedEmail) {
      return
    }
    setBlockStatus(await getEmailStatus(sanitizedEmail).unwrap())
  }, [email, getEmailStatus])

  const doUnblockEmail = useCallback(async () => {
    setIsLoading(true)
    try {
      const blockStatus = await unblockEmailMutation(email).unwrap()
      setBlockStatus(blockStatus)
      notify({
        content: formatMessage(adminManageBlocklistMessages.toastUnblockSuccess, { email }),
        type: 'success',
        position: 'bottom-right',
        autoClose: false,
      })
    } catch (error) {
      notify({
        content: formatMessage(adminManageBlocklistMessages.toastError),
        type: 'error',
        position: 'bottom-right',
        autoClose: false,
      })
    }
    setIsLoading(false)
  }, [unblockEmailMutation, email, formatMessage])

  return (
    <>
      <AdminPageMeta title={formatMessage(adminManageBlocklistMessages.title)} />
      <AdminPageContent title={formatMessage(adminManageBlocklistMessages.title)}>
        <VStack spacing="m">
          <BaseSection>
            <HStack spacing="m" p="m">
              <Label primary={formatMessage(adminManageBlocklistMessages.emailLabel)} width="100%">
                <Input
                  fullWidth
                  data-test="sr-admin-blocklist-email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      doGetEmailStatus()
                    }
                  }}
                />
              </Label>
              <Label primary="&nbsp;">
                <LoaderButton loading={isEmailStatusLoading} onClick={() => doGetEmailStatus()} data-test="sr-admin-blocklist-check-status">
                  {formatMessage(adminManageBlocklistMessages.checkBlockStatusButton)}
                </LoaderButton>
              </Label>
            </HStack>
          </BaseSection>
          {blockStatus && (
            <>
              {isLoading ? (
                <BaseSection>
                  <VStack spacing="m" p="m">
                    <Loader />
                  </VStack>
                </BaseSection>
              ) : (
                <BaseSection
                  title={
                    blockStatus.isBlocked
                      ? formatMessage(adminManageBlocklistMessages.blockStatusLabel, { email: blockStatus.email })
                      : formatMessage(adminManageBlocklistMessages.unblockedStatusLabel, { email: blockStatus.email })
                  }
                  subCaption={
                    blockStatus.isBlocked
                      ? formatMessage(adminManageBlocklistMessages.blockStatusSublabel)
                      : formatMessage(adminManageBlocklistMessages.unblockedStatusSublabel)
                  }
                >
                  <VStack spacing="m" p="m">
                    {blockStatus.isBlocked && (
                      <>
                        <Text>
                          {formatMessage(adminManageBlocklistMessages.blockUpdatedAt, {
                            date: blockStatus.updatedAt?.formatNYearNMonthNDaySTime() ?? 'NEVER',
                          })}
                          <br />
                          {formatMessage(adminManageBlocklistMessages.blockExpiresAt, {
                            date: blockStatus.expiresAt?.formatNYearNMonthNDaySTime() ?? 'NEVER',
                          })}
                        </Text>
                        <HStack spacing="m">
                          <Button variant="secondary" data-test="sr-admin-blocklist-unblock" onClick={doUnblockEmail}>
                            {formatMessage(adminManageBlocklistMessages.unblockButton, { email: blockStatus.email })}
                          </Button>
                        </HStack>
                      </>
                    )}
                  </VStack>
                </BaseSection>
              )}
            </>
          )}
        </VStack>
        <NotificationContainer />
      </AdminPageContent>
    </>
  )
}
