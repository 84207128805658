import { useCallback } from 'react'
import type { EmailCampaignTemplate } from '@sevenrooms/core/api'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { VStack, HStack, Box, Modal, ModalHeader, ModalTitle, ModalFooter, ModalActions } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { adminOneTimeEmailCampaignTemplatesMessages } from '../../locales'

export interface AdminOneTimeEmailCampaignTemplateUnarchiveModalProps {
  closeHref: string
  emailCampaignTemplate: EmailCampaignTemplate
  onUnarchive: (emailCampaignTemplate: EmailCampaignTemplate) => void
  isLoading: boolean
}

export function AdminOneTimeEmailCampaignTemplateUnarchiveModal({
  emailCampaignTemplate,
  onUnarchive,
  closeHref,
  isLoading,
}: AdminOneTimeEmailCampaignTemplateUnarchiveModalProps) {
  const { formatMessage } = useLocales()

  const onUnarchiveConfirm = useCallback(() => {
    onUnarchive(emailCampaignTemplate)
  }, [emailCampaignTemplate, onUnarchive])

  return (
    <Modal data-test="unarchive-modal" ariaLabel={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.unarchiveModalTitle)}>
      <ModalHeader closeHref={closeHref}>
        <VStack spacing="s">
          <HStack spacing="s">
            <Box>
              <Icon name="VMSWeb-warning" color="error" size="lg" />
            </Box>
            <Text color="error" fontWeight="bold">
              {formatMessage(commonMessages.warning)}
            </Text>
          </HStack>
          <ModalTitle title={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.unarchiveModalTitle)} />
        </VStack>
      </ModalHeader>

      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" href={closeHref} data-test="unarchive-modal-cancel">
            {formatMessage(commonMessages.cancel)}
          </Button>
          <Button data-test="unarchive-modal-confirm" variant="primary" onClick={onUnarchiveConfirm} disabled={isLoading}>
            {formatMessage(commonMessages.unarchive)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
