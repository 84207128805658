import _ from 'lodash'
import { useState } from 'react'
import { AdminPageContent, AdminPageMeta } from '@sevenrooms/admin/components'
import type { VenueHistoryTrackingResponse } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { DateTime } from '@sevenrooms/core/timepiece'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Tabs, Box, Breadcrumbs, Table, TableBody, TableRow, TableCell, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { routes } from '@sevenrooms/routes'
import { adminVenueHistoryTrackingMessages } from '../../locales'

interface HistoryTrackingProps {
  history: VenueHistoryTrackingResponse
  venueId: string
  venueName: string
}

export function HistoryTrackingForm({ history, venueId, venueName }: HistoryTrackingProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const isEmpty =
    history.payoutProfile.length + history.venue.length + history.venueSettings.length + history.venueIntegrationSettings.length === 0
  const concatedHistories = _.orderBy(
    [...history.payoutProfile, ...history.venue, ...history.venueSettings, ...history.venueIntegrationSettings],
    ['created'],
    ['desc']
  )
  return (
    <>
      <AdminPageMeta title={formatMessage(adminVenueHistoryTrackingMessages.title)} />
      <AdminPageContent
        breadcrumbs={
          <Breadcrumbs>
            <Button
              href={nav.href(routes.admin.venueEdit, { params: { venueId } })}
              variant="tertiary"
              target="_self"
              isExternal
              noPadding
              data-test="venue-configuration-breadcrumb"
            >
              {formatMessage(adminVenueHistoryTrackingMessages.venueConfigurationBreadcrumb)}
            </Button>
            <Text>{formatMessage(adminVenueHistoryTrackingMessages.title)}</Text>
          </Breadcrumbs>
        }
        title={venueName}
      >
        <VStack pt="m" spacing="lm">
          {isEmpty ? <Box m="l">{formatMessage(adminVenueHistoryTrackingMessages.noHistory)}</Box> : null}
          <Tabs onSelect={setSelectedIndex} selectedIndex={selectedIndex}>
            <Table>
              <thead>
                <tr>
                  <TableCell isHeader isInFirstRow>
                    {formatMessage(adminVenueHistoryTrackingMessages.who)}
                  </TableCell>
                  <TableCell isHeader isInFirstRow>
                    {formatMessage(adminVenueHistoryTrackingMessages.when)}
                  </TableCell>
                  <TableCell isHeader isInFirstRow>
                    {formatMessage(adminVenueHistoryTrackingMessages.what)}
                  </TableCell>
                </tr>
              </thead>
              <TableBody>
                {concatedHistories.map((el, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <TableRow key={index}>
                    <TableCell key="who">{el.email ? `${el.firstName} ${el.lastName} ${el.email}` : ''}</TableCell>
                    <TableCell key="when">{new DateTime(new Date(el.created)).formatNYearNMonthNDaySTime()}</TableCell>
                    <TableCell key="what">{el.systemText}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Tabs>
        </VStack>
      </AdminPageContent>
    </>
  )
}
