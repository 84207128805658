import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { z, useForm } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { DateTime } from '@sevenrooms/core/timepiece'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { FormSelect, datePickerMessages } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { updateConditionTree } from '../../../../autoTagSlideOutSlice'

const getTimeSelectOptions = (): SelectOption[] => {
  const minuteStrings = _.range(0, 60, 15).map(minute => `${minute}`.padStart(2, '0'))
  const times: string[] = []
  _.range(24).forEach(hour => {
    const xm = hour >= 12 ? 'PM' : 'AM'
    const adjustedHour = hour % 12
    const hourString = `${adjustedHour || 12}`.padStart(2, '0')
    minuteStrings.forEach(minuteString => {
      times.push(`${hourString}:${minuteString} ${xm}`)
    })
  })
  return times.map(time => ({ id: time, label: time }))
}

const options: SelectOption[] = getTimeSelectOptions()

const getOptionIdFromISOString = (isoString: string) => {
  const date = new Date(isoString)
  let hours = date.getHours()
  let minutes: number | string = date.getMinutes()
  const xm = hours >= 12 ? 'PM' : 'AM'
  hours %= 12
  hours = hours || 12
  minutes = minutes < 10 ? `0${minutes}` : minutes
  return `${hours}:${minutes} ${xm}`
}

const getISOStringFromOptionId = (optionId: string): string | null => {
  const [hourString, minuteString, amPmString] = optionId.split(/[:\s]/)
  if (hourString === undefined || minuteString === undefined || amPmString === undefined) {
    return null
  }
  let hours = parseInt(hourString)
  const minutes = parseInt(minuteString)
  if (amPmString === 'PM' && hours < 12) {
    hours += 12
  }
  return new DateTime(new Date()).setValue('hours', hours).setValue('minutes', minutes).toIso()
}

/**
 * TODO
 * This component doesn't quite work right now. It doesn't quite handle its own state properly,
 * likely due to me mishandling the form mechanisms. I'm spinning this off into its own ticket.
 */
export function CustomAutoTagTimeRangePicker({ path, selectedTimes }: { path: (string | number)[]; selectedTimes?: [string, string] }) {
  const dispatch = useDispatch()
  const { formatMessage } = useLocales()

  const formValues = z.object({
    startTime: z.string(),
    endTime: z.string(),
  })
  const form = useForm(formValues)
  const { field } = form

  let initialStartTime
  let initialEndTime
  if (selectedTimes) {
    initialStartTime = getOptionIdFromISOString(selectedTimes[0])
    initialEndTime = getOptionIdFromISOString(selectedTimes[1])
  }

  if (options[0]) {
    form.setValue('startTime', initialStartTime || options[0].id)
    form.setValue('endTime', initialEndTime || options[0].id)
  }

  return (
    <>
      <HStack spacing="sm">
        <FormSelect
          field={field.prop('startTime')}
          options={options}
          onChange={selected => {
            if (!selected) {
              return
            }
            const newStartTime = getISOStringFromOptionId(`${selected}`)
            const endTime = getISOStringFromOptionId(form.getValues('endTime'))
            if (!(newStartTime && endTime)) {
              return
            }
            const data = { value: [newStartTime, endTime] }
            dispatch(updateConditionTree({ path, data }))
          }}
        />

        <Box mt="sm">
          <Text>{formatMessage(datePickerMessages.to)}</Text>
        </Box>

        <FormSelect
          field={field.prop('endTime')}
          options={options}
          onChange={selected => {
            if (!selected) {
              return
            }
            const newEndTime = getISOStringFromOptionId(`${selected}`)
            const startTime = getISOStringFromOptionId(form.getValues('startTime'))
            if (!(newEndTime && startTime)) {
              return
            }

            const data = { value: [startTime, newEndTime] }
            dispatch(updateConditionTree({ path, data }))
          }}
        />
      </HStack>
    </>
  )
}
