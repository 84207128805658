import { useMemo } from 'react'
import type { OneTimeEmailCampaignTemplate } from '@sevenrooms/core/api'
import { OneTimeEmailCampaignStatusEnum } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import type { StatusLabelVariant } from '@sevenrooms/core/ui-kit/typography'
import { marketingCommonMessages } from '@sevenrooms/marketing'
import { adminOneTimeEmailCampaignTemplatesMessages } from '../locales'

export const useEmailCampaignTemplateStatusLabel = (
  emailCampaignTemplate: OneTimeEmailCampaignTemplate | undefined
): { text: string; variant: StatusLabelVariant } | undefined => {
  const { formatMessage } = useLocales()

  const statuses = useMemo<{ [status in OneTimeEmailCampaignStatusEnum]?: { text: string; variant: StatusLabelVariant } }>(
    () => ({
      ACTIVE: { text: formatMessage(adminOneTimeEmailCampaignTemplatesMessages.live), variant: 'success' },
      DRAFT: { text: formatMessage(marketingCommonMessages.draft), variant: 'status' },
      INACTIVE: { text: formatMessage(marketingCommonMessages.archived), variant: 'inactive' },
    }),
    [formatMessage]
  )

  return useMemo(() => {
    if (emailCampaignTemplate) {
      if (emailCampaignTemplate.unpublishedChanges && emailCampaignTemplate.status === OneTimeEmailCampaignStatusEnum.ACTIVE) {
        return { text: formatMessage(adminOneTimeEmailCampaignTemplatesMessages.unpublished), variant: 'warning' as StatusLabelVariant }
      }

      return statuses[emailCampaignTemplate.status]
    }

    return undefined
  }, [statuses, emailCampaignTemplate, formatMessage])
}
