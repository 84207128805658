import { Box, type BoxProps, Grid } from '@sevenrooms/core/ui-kit/layout'
import type { PropsWithChildren } from 'react'

export function InputGroup({ children }: PropsWithChildren<BoxProps>) {
  return (
    <Box pl="lm" pr="lm" pt="m" pb="m">
      <Grid gridTemplateColumns="repeat(12, 1fr)" gap="lm" gridAutoRows="min-content">
        {children}
      </Grid>
    </Box>
  )
}
