import { defineMessages } from '@sevenrooms/core/locales'

export const adminBulkUpdateSettingsMessages = defineMessages({
  title: {
    id: 'admin.bulkUpdateSettings.title',
    defaultMessage: 'Bulk Update Venue Settings',
  },
  venueSelectByUrlkeysLabel: {
    id: 'admin.bulkUpdateSettings.venueSelectByUrlkeysLabel',
    defaultMessage: 'Select venues by url keys or IDs',
  },
  venueSelectByPackageLabel: {
    id: 'admin.bulkUpdateSettings.venueSelectByPackageLabel',
    defaultMessage: 'Select venues by package',
  },
  venueListLabel: {
    id: 'admin.bulkUpdateSettings.venueListLabel',
    defaultMessage: 'Enter list of venues',
  },
  venueListSubLabel: {
    id: 'admin.bulkUpdateSettings.venueListSubLabel',
    defaultMessage: 'Url keys or IDs only, each on its own line',
  },
  venuePackageLabel: {
    id: 'admin.bulkUpdateSettings.venuePackageLabel',
    defaultMessage: 'Select one or more package',
  },
  settingLabel: {
    id: 'admin.bulkUpdateSettings.settingLabel',
    defaultMessage: 'Venue setting',
  },
  settingSubLabel: {
    id: 'admin.bulkUpdateSettings.settingSubLabel',
    defaultMessage: "If you don't see the readable name of the setting here, use the internal name and ask an engineer to add it",
  },
  settingValueTypeLabel: {
    id: 'admin.bulkUpdateSettings.settingValueTypeLabel',
    defaultMessage: 'Value type',
  },
  settingValueTypeSublabel: {
    id: 'admin.bulkUpdateSettings.settingValueTypeSublabel',
    defaultMessage: 'On/off settings are booleans; text entries and most dropdowns are strings',
  },
  boolean: {
    id: 'admin.bulkUpdateSettings.boolean',
    defaultMessage: 'Boolean',
  },
  string: {
    id: 'admin.bulkUpdateSettings.string',
    defaultMessage: 'String',
  },
  settingValueLabel: {
    id: 'admin.bulkUpdateSettings.settingValueLabel',
    defaultMessage: 'New value',
  },
  settingValueSublabel: {
    id: 'admin.bulkUpdateSettings.settingValueSublabel',
    defaultMessage: 'The setting will be assigned this value for all venues',
  },
  saveButton: {
    id: 'admin.bulkUpdateSettings.saveButton',
    defaultMessage: 'Save changes',
  },
  warningTitle: {
    id: 'admin.bulkUpdateSettings.warningTitle',
    defaultMessage: 'Warning',
  },
  warningMessage: {
    id: 'admin.bulkUpdateSettings.warningMessage',
    defaultMessage:
      'This page is used to update venue settings for multiple venues at a time. Many settings appear here that probably should not be changed through this tool. Please be careful and double check your changes before saving.',
  },
  successMessage: {
    id: 'admin.bulkUpdateSettings.successMessage',
    defaultMessage:
      'Updates to venues are queued. There might be a short delay depending on the number of venues. Please manually check on a venue to confirm changes.',
  },
  errorMessage: {
    id: 'admin.bulkUpdateSettings.errorMessage',
    defaultMessage: 'Something went wrong...',
  },
  step1Title: {
    id: 'admin.bulkUpdateSettings.step1Title',
    defaultMessage: 'Step 1 - Choose Venues',
  },
  step2Title: {
    id: 'admin.bulkUpdateSettings.step2Title',
    defaultMessage: 'Step 2 - Choose Setting',
  },
  step3Title: {
    id: 'admin.bulkUpdateSettings.step3Title',
    defaultMessage: 'Step 3 - Save Changes',
  },
  step3Description: {
    id: 'admin.bulkUpdateSettings.step3Description',
    defaultMessage: 'Double check your changes before saving!',
  },
})
