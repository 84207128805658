import { useMemo } from 'react'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { useLocales, commonMessages } from '@sevenrooms/core/locales'

export type BulkUpdateSettingsForm = ZodSchema<typeof useBulkUpdateSettingsForm>

export function useBulkUpdateSettingsForm() {
  const { formatMessage } = useLocales()

  return useMemo(
    () =>
      z
        .object({
          venueSelectType: z.string().default('URL_KEYS_OR_IDS'),
          venues: z.string(),
          packages: z.array(z.string()),
          settingName: z.string(),
          settingType: z.string().default('BOOLEAN'),
          booleanValue: z.boolean(),
          stringValue: z.string(),
        })
        .superRefine((formData, ctx) => {
          if (formData.venueSelectType === 'URL_KEYS_OR_IDS' && !(formData.venues ?? '').trim()) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(commonMessages.required),
              path: ['venues'],
            })
          }
          if (formData.venueSelectType === 'PACKAGE' && !formData.packages?.length) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(commonMessages.required),
              path: ['packages'],
            })
          }
          if (!formData.settingName) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: formatMessage(commonMessages.required),
              path: ['settingName'],
            })
          }
        }),
    [formatMessage]
  )
}
