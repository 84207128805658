import * as React from 'react'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalActions, ModalFooter, ModalHeader, ModalTitle, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Status } from '@sevenrooms/core/ui-kit/typography'
import { routes } from '@sevenrooms/routes'
import { venueInteractiveFloorplanImagesMessages } from './locales'

interface CancelEditWarningModalProps {
  closeExitWithoutChangesModal: () => void
  venueId: string
}

export function CancelEditWarningModal({ closeExitWithoutChangesModal, venueId }: CancelEditWarningModalProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  return (
    <Modal ariaLabel={formatMessage(venueInteractiveFloorplanImagesMessages.cancelEditWarning)}>
      <ModalHeader onClose={closeExitWithoutChangesModal}>
        <VStack spacing="s">
          <Status kind="warning">{formatMessage(commonMessages.unsavedChanges)}</Status>
          <ModalTitle title={formatMessage(venueInteractiveFloorplanImagesMessages.cancelEditWarning)} />
        </VStack>
      </ModalHeader>
      <ModalFooter>
        <ModalActions>
          <Button data-test="back-to-editing" variant="secondary" onClick={() => closeExitWithoutChangesModal()}>
            {formatMessage(venueInteractiveFloorplanImagesMessages.backToEditing)}
          </Button>
          <Button
            data-test="exit-without-saving"
            variant="primary-warning"
            href={nav.href(routes.admin.venueEdit, { params: { venueId } })}
            target="_self"
            isExternal
          >
            {formatMessage(venueInteractiveFloorplanImagesMessages.exitWithoutSaving)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
