import { defineMessages } from '@sevenrooms/core/locales'

export const venueInteractiveFloorplanImagesMessages = defineMessages({
  title: {
    id: 'venueInteractiveFloorplanImages.title',
    defaultMessage: '3D Interactive Floorplan Images',
  },
  venueConfigurationBreadcrumb: {
    id: 'venueInteractiveFloorplanImages.venueConfiguration',
    defaultMessage: 'Venue configuration',
  },
  noHistory: {
    id: 'venueInteractiveFloorplanImages.noHistory',
    defaultMessage: 'This venue has no saved Floorplan Images.',
  },
  addImage: {
    id: 'venueInteractiveFloorplanImages.addImage',
    defaultMessage: 'Add Image',
  },
  saveChanges: {
    id: 'venueInteractiveFloorplanImages.saveChanges',
    defaultMessage: 'Save Changes',
  },
  sureToDelete: {
    id: 'venueInteractiveFloorplanImages.sureToDelete',
    defaultMessage: "Are you sure you want to delete this image, it's used in {eventCount} events?",
  },
  sureToDeleteTemplate: {
    id: 'venueInteractiveFloorplanImages.sureToDeleteTemplate',
    defaultMessage: 'Are you sure you want to delete this image',
  },
  notDelete: {
    id: 'venueInteractiveFloorplanImages.notDelete',
    defaultMessage: 'Keep',
  },
  continueDelete: {
    id: 'venueInteractiveFloorplanImages.continueDelete',
    defaultMessage: 'Delete',
  },
  cancelEditWarning: {
    id: 'experience.cancelEditWarning',
    defaultMessage: 'Are you sure you want to leave without saving changes?',
  },
  backToEditing: {
    id: 'venueInteractiveFloorplanImages.backToEditing',
    defaultMessage: 'Back to Editing',
  },
  exitWithoutSaving: {
    id: 'venueInteractiveFloorplanImages.exitWithoutSaving',
    defaultMessage: 'Exit Without Saving',
  },
  cancel: {
    id: 'venueInteractiveFloorplanImages.cancel',
    defaultMessage: 'Cancel',
  },
  imageUploadCaption: {
    id: 'venueInteractiveFloorplanImages.imageUploadCaption',
    defaultMessage: 'Drag an interactive image here to upload, or <span>select a file</span> from your device',
  },
})
