import { LIMITED_BUTTONS } from '@sevenrooms/core/domain/constants'
import { useFieldArray, useFormContext } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Button, Checkbox, FormInput, Label, FormNumberInput } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, Box, DividerLine, Flex, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { routes } from '@sevenrooms/routes'
import { adminOngoingEmailCampaignTemplateMessages } from '../../locales'
import { AdminEmailEditor } from '../AdminEmailEditor'
import type { AdminEmailContentFormField } from './AdminEmailContentForm.zod'

type AdminEmailContentSectionName =
  | 'emailSender'
  | 'emailSubject'
  | 'previewText'
  | 'greeting'
  | 'signature'
  | 'signoff'
  | 'footer'
  | 'emailBodySections'

interface AdminEmailContentProps {
  field: AdminEmailContentFormField
}

export function AdminEmailContent({ field }: AdminEmailContentProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { trigger } = useFormContext()

  const { fields: emailBodySectionsFieldArray, remove, append } = useFieldArray(field, 'emailBodySections')

  const renderContentSectionFields = ({
    editorLabel,
    removeEditor,
    sectionName,
    index = 0,
    required,
    key,
    editorProps,
    isInput,
  }: {
    editorLabel: string
    removeEditor?: () => void
    sectionName: AdminEmailContentSectionName
    index?: number
    required?: boolean
    key?: string
    editorProps?: object
    isInput?: boolean
  }) => {
    const editorField =
      sectionName === 'emailBodySections' ? field.prop(`${sectionName}.${index}.value`) : field.prop(`${sectionName}.value`)
    const maxLenField =
      sectionName === 'emailBodySections' ? field.prop(`${sectionName}.${index}.maxLen`) : field.prop(`${sectionName}.maxLen`)
    const isEditableField =
      sectionName === 'emailBodySections' ? field.prop(`${sectionName}.${index}.isEditable`) : field.prop(`${sectionName}.isEditable`)
    const maxLenOnBlur =
      sectionName === 'emailBodySections' ? () => trigger(`${sectionName}.${index}.value`) : () => trigger(`${sectionName}.value`)
    return (
      <HStack key={key} spacing="m" mb="m">
        <Box mr="m" width="50%">
          <Flex justifyContent="space-between" pr="s">
            <Label
              primary={
                <>
                  {editorLabel}
                  {required && <Text color="error">&nbsp;*</Text>}
                </>
              }
            />
            {removeEditor && (
              <Box>
                <Button variant="tertiary-warning" onClick={removeEditor} noPadding data-test="delete-section-button">
                  {formatMessage(adminOngoingEmailCampaignTemplateMessages.deleteSection)}
                </Button>
              </Box>
            )}
          </Flex>

          {isInput ? (
            <FormInput field={editorField} />
          ) : (
            <AdminEmailEditor data-test={editorField.name} field={editorField} {...editorProps} />
          )}
        </Box>
        <VStack>
          <Label primary={formatMessage(adminOngoingEmailCampaignTemplateMessages.maxCharacterCount)} />

          <HStack spacing="m">
            <FormNumberInput field={maxLenField} greaterThanZero decimalScale={0} onBlur={maxLenOnBlur} />
            <Checkbox field={isEditableField}>{formatMessage(adminOngoingEmailCampaignTemplateMessages.isUserEditable)}</Checkbox>
          </HStack>
        </VStack>
      </HStack>
    )
  }

  return (
    <BaseSection
      title={formatMessage(adminOngoingEmailCampaignTemplateMessages.createNewEmailTemplate)}
      actions={
        <Button
          variant="secondary"
          onClick={() => {
            nav.push(routes.admin.ongoingEmailCampaignTemplates.sendTestEmailModal)
          }}
          data-test="preview-email-button"
        >
          {formatMessage(adminOngoingEmailCampaignTemplateMessages.previewEmail)}
        </Button>
      }
    >
      <Box p="lm">
        <VStack spacing="lm">
          {renderContentSectionFields({
            editorLabel: formatMessage(adminOngoingEmailCampaignTemplateMessages.emailSender),
            sectionName: 'emailSender',
            required: true,
            editorProps: {
              buttons: LIMITED_BUTTONS,
              config: { pastePlain: true },
            },
          })}

          {renderContentSectionFields({
            editorLabel: formatMessage(adminOngoingEmailCampaignTemplateMessages.subjectLine),
            sectionName: 'emailSubject',
            required: true,
            editorProps: {
              buttons: LIMITED_BUTTONS,
              config: { pastePlain: true },
            },
          })}

          {renderContentSectionFields({
            editorLabel: formatMessage(adminOngoingEmailCampaignTemplateMessages.previewText),
            sectionName: 'previewText',
            isInput: true,
          })}

          <DividerLine color="dividerLines" />

          {renderContentSectionFields({
            editorLabel: formatMessage(adminOngoingEmailCampaignTemplateMessages.greeting),
            sectionName: 'greeting',
          })}

          {emailBodySectionsFieldArray.map((_, index) =>
            renderContentSectionFields({
              editorLabel: `${formatMessage(adminOngoingEmailCampaignTemplateMessages.body)} ${index + 1}`,
              required: true,
              key: `emailBodySection-${index}`,
              sectionName: 'emailBodySections',
              index,
              removeEditor: index !== 0 ? () => remove(index) : undefined,
            })
          )}
          <Box>
            <Button
              variant="tertiary"
              onClick={() => append({ value: '', maxLen: null, isEditable: false })}
              icon="VMSWeb-add"
              noPadding
              data-test="add-section-button"
            >
              {formatMessage(adminOngoingEmailCampaignTemplateMessages.addSection)}
            </Button>
          </Box>

          <DividerLine color="dividerLines" />

          {renderContentSectionFields({
            editorLabel: formatMessage(adminOngoingEmailCampaignTemplateMessages.signature),
            sectionName: 'signature',
          })}

          {renderContentSectionFields({
            editorLabel: formatMessage(adminOngoingEmailCampaignTemplateMessages.signoff),
            sectionName: 'signoff',
          })}

          {renderContentSectionFields({
            editorLabel: formatMessage(adminOngoingEmailCampaignTemplateMessages.footer),
            sectionName: 'footer',
          })}
        </VStack>
      </Box>
    </BaseSection>
  )
}
