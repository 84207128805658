import { defineMessages } from '@sevenrooms/core/locales'

export const adminVenuePackageProvisioningMessages = defineMessages({
  title: {
    id: 'VenuePackageProvisioningMessages.packageProvisioningSettings',
    defaultMessage: 'Package Provisioning Settings',
  },
  venueConfigurationBreadcrumb: {
    id: 'VenuePackageProvisioningMessages.venueConfiguration',
    defaultMessage: 'Venue configuration',
  },
  venueGroupConfigurationBreadcrumb: {
    id: 'VenuePackageProvisioningMessages.venueGroupConfigurationBreadcrumb',
    defaultMessage: 'Venue group configuration',
  },
  wizardBreadcrumb: {
    id: 'VenuePackageProvisioningMessages.wizardBreadcrumb',
    defaultMessage: 'Legacy Package Wizard',
  },
  wizardDetails: {
    id: 'VenuePackageProvisioningMessages.wizardDetails',
    defaultMessage: 'Download a report that shows the recommended package for this venue.',
  },
  wizardNoReports: {
    id: 'VenuePackageProvisioningMessages.wizardNoReports',
    defaultMessage: 'No reports have been generated.',
  },
  wizardTitleVenue: {
    id: 'VenuePackageProvisioningMessages.wizardTitleVenue',
    defaultMessage: 'Download a Venue Report',
  },
  wizardTitleVenueGroup: {
    id: 'VenuePackageProvisioningMessages.wizardTitleVenueGroup',
    defaultMessage: 'Generate a Report for the {venueGroupName} Venue Group',
  },
  wizardDownloadDescription: {
    id: 'VenuePackageProvisioningMessages.wizardDownloadDescription',
    defaultMessage: "Reports may take a few minutes to generate. If you don't see your report in the list, refresh the page.",
  },
  wizardDownloadButton: {
    id: 'VenuePackageProvisioningMessages.wizardDownloadButton',
    defaultMessage: 'Download Report',
  },
  wizardStartButton: {
    id: 'VenuePackageProvisioningMessages.wizardStartButton',
    defaultMessage: 'Generate a Report',
  },
  wizardStartSuccess: {
    id: 'VenuePackageProvisioningMessages.wizardStartSuccess',
    defaultMessage: 'The report is being generated.',
  },
  selectPackage: {
    id: 'VenuePackageProvisioningMessages.selectPackage',
    defaultMessage: 'Select Package',
  },
  saveSuccess: {
    id: 'VenuePackageProvisioningMessages.saveSuccess',
    defaultMessage: 'Provisioning Settings Saved',
  },
  saveError: {
    id: 'VenuePackageProvisioningMessages.saveError',
    defaultMessage: 'There was an error saving the provisioning settings',
  },
  package: {
    id: 'VenuePackageProvisioningMessages.package',
    defaultMessage: 'Package',
  },
  packageWarning: {
    id: 'VenuePackageProvisioningMessages.packageWarning',
    defaultMessage: 'Ensure you are aware of how this venue will be impacted',
  },
  viewPackageBreakdown: {
    id: 'VenuePackageProvisioningMessages.viewPackageBreakdown',
    defaultMessage: 'View Package Breakdown',
  },
  addons: {
    id: 'VenuePackageProvisioningMessages.addons',
    defaultMessage: 'Add-ons',
  },
  viewAddonBreakdown: {
    id: 'VenuePackageProvisioningMessages.viewAddonBreakdown',
    defaultMessage: 'View Add-on Breakdown',
  },
  betas: {
    id: 'VenuePackageProvisioningMessages.betas',
    defaultMessage: 'Betas',
  },
  viewBetasBreakdown: {
    id: 'VenuePackageProvisioningMessages.viewBetasBreakdown',
    defaultMessage: 'View Beta Breakdown',
  },
  betasAvailable: {
    id: 'VenuePackageProvisioningMessages.betasAvailable',
    defaultMessage: 'Betas Available in Package',
  },
  betaUnavailable: {
    id: 'VenuePackageProvisioningMessages.betaUnavailable',
    defaultMessage: "{name} is not available in the package you've selected",
  },
  addonApi: {
    id: 'VenuePackageProvisioningMessages.addonApi',
    defaultMessage: 'API',
  },
  addonOnlineOrdering: {
    id: 'VenuePackageProvisioningMessages.addonOnlineOrdering',
    defaultMessage: 'Online Ordering',
  },
  addonEmailCampaigns: {
    id: 'VenuePackageProvisioningMessages.addonEmailCampaigns',
    defaultMessage: 'Email Marketing',
  },
  addonPms: {
    id: 'VenuePackageProvisioningMessages.addonPms',
    defaultMessage: 'Property Management System (PMS)',
  },
  addonSmsMarketing: {
    id: 'VenuePackageProvisioningMessages.addonSmsMarketing',
    defaultMessage: 'Text Marketing',
  },
  addonPremiumIntegrations: {
    id: 'VenuePackageProvisioningMessages.addonPremiumIntegrations',
    defaultMessage: 'Premium Integrations',
  },
  selectMultiple: {
    id: 'VenuePackageProvisioningMessages.selectMultiple',
    defaultMessage: 'Select Multiple',
  },
  modifyPageTitle: {
    id: 'VenuePackageProvisioningMessages.modifyPageTitle',
    defaultMessage: 'Product Provisioning: Modify',
  },
  reviewPageTitle: {
    id: 'VenuePackageProvisioningMessages.reviewPageTitle',
    defaultMessage: 'Product Provisioning: Review',
  },
  reviewPageDescription: {
    id: 'VenuePackageProvisioningMessages.reviewPageDescription',
    defaultMessage: 'Review your provisioning updates and ensure they are correct before initiating reprovisioning',
  },
  updateButtonText: {
    id: 'VenuePackageProvisioningMessages.updateButtonText',
    defaultMessage: 'Update Provisioning',
  },
  reviewButtonText: {
    id: 'VenuePackageProvisioningMessages.reviewButtonText',
    defaultMessage: 'Review Changes',
  },
  enabled: {
    id: 'VenuePackageProvisioningMessages.enabled',
    defaultMessage: 'Enabled',
  },
  disabled: {
    id: 'VenuePackageProvisioningMessages.disabled',
    defaultMessage: 'Disabled',
  },
  venueLabel: {
    id: 'VenuePackageProvisioningMessages.venueLabel',
    defaultMessage: 'Venue',
  },
  featureChangeCountLabel: {
    id: 'VenuePackageProvisioningMessages.featureChangeCountLabel',
    defaultMessage: 'Feature Changes',
  },
  betaChangeCountLabel: {
    id: 'VenuePackageProvisioningMessages.betaChangeCountLabel',
    defaultMessage: 'Beta Changes',
  },
  addonChangeCountLabel: {
    id: 'VenuePackageProvisioningMessages.addonChangeCountLabel',
    defaultMessage: 'Add-on Changes',
  },
  packageChangeLabel: {
    id: 'VenuePackageProvisioningMessages.packageChangeLabel',
    defaultMessage: 'Package',
  },
})
