import { useCallback, useState } from 'react'
import { AdminPageContent, AdminPageMeta } from '@sevenrooms/admin'
import { useGetAdminVenueGroupsQuery, useSubmitVenueGroupMergeMutation, type VenueGroupMergePayload } from '@sevenrooms/core/api'
import { useForm } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Button, Checkbox, FormSelect, Label } from '@sevenrooms/core/ui-kit/form'
import { Box, Flex, Loader, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Anchor, ErrorText } from '@sevenrooms/core/ui-kit/typography'
import { adminVenueGroupMergeMessages } from '../../locales'
import { useAdminVenueGroupMergeForm } from './AdminVenueGroupMerge.zod'

export function AdminVenueGroupMerge() {
  const { formatMessage } = useLocales()

  const schema = useAdminVenueGroupMergeForm()
  const {
    field,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm(schema, {
    defaultValues: {
      sourceVenueGroupId: '',
      destinationVenueGroupId: '',
      completedTracker: false,
      agreedToScheduledMerge: false,
    },
  })

  const [venueGroupMergeSuccess, setVenueGroupMergeSuccess] = useState(false)
  const [submitVenueGroupMerge] = useSubmitVenueGroupMergeMutation()

  const trySubmitVenueGroupMerge = useCallback(
    async (formData: VenueGroupMergePayload) => {
      if (isSubmitting) {
        return
      }
      const response = await submitVenueGroupMerge(formData)
      if ('data' in response && response.data.success_message) {
        setVenueGroupMergeSuccess(true)
      }
    },
    [isSubmitting, submitVenueGroupMerge]
  )

  const getVenueGroupNameById = (venueGroupId: string | null) => {
    const venueGroupOption = venueGroupOptions.find(venueGroupOption => venueGroupOption.id === venueGroupId)
    return venueGroupOption ? venueGroupOption.label : ''
  }

  const { data, isLoading } = useGetAdminVenueGroupsQuery()
  const venueGroupOptions = data?.results ?? []
  const selectedSourceVenueGroupId = getValues('sourceVenueGroupId')
  const selectedDestinationVenueGroupId = getValues('destinationVenueGroupId')
  const selectedSourceVenueGroupName = getVenueGroupNameById(selectedSourceVenueGroupId)
  const selectedDestinationVenueGroupName = getVenueGroupNameById(selectedDestinationVenueGroupId)

  const renderVenueGroupMergeFormView = () => (
    <>
      <Flex pb="lm" justifyContent="space-between">
        <Label primary="Source Venue Group" width="45%">
          <FormSelect field={field.prop('sourceVenueGroupId')} withEmpty options={venueGroupOptions} searchable />
        </Label>
        <Label primary="Destination Venue Group" width="45%">
          <FormSelect field={field.prop('destinationVenueGroupId')} withEmpty options={venueGroupOptions} searchable />
        </Label>
      </Flex>
      <Box pb="m">
        <ErrorText>{formatMessage(adminVenueGroupMergeMessages.venueGroupMergeWarning)}</ErrorText>
      </Box>
      <Box pb="m">
        <ErrorText>
          {formatMessage(adminVenueGroupMergeMessages.readThingsToKnowLabel)}
          <Anchor href="https://docs.google.com/spreadsheets/d/1AGRcBrCumtHr1fxcl_G720NBFU3tA6k7_mtkycQ1GEg/edit#gid=1635396137">
            {formatMessage(adminVenueGroupMergeMessages.venueGroupMergeTrackerLink)}
          </Anchor>
        </ErrorText>
      </Box>
      <Box pb="lm">
        <ErrorText>{formatMessage(adminVenueGroupMergeMessages.carryOnText)}</ErrorText>
      </Box>
      <Box pb="lm">
        <VStack spacing="s">
          <Checkbox field={field.prop('completedTracker')}>{formatMessage(adminVenueGroupMergeMessages.completedTrackerLabel)}</Checkbox>
          <Checkbox field={field.prop('agreedToScheduledMerge')}>
            {formatMessage(adminVenueGroupMergeMessages.agreedToScheduledMergeLabel)}
          </Checkbox>
        </VStack>
      </Box>
      <Flex justifyContent="right">
        <Box mb="m">
          <Button variant="primary-warning" onClick={handleSubmit(trySubmitVenueGroupMerge)} data-test="merge-venue-groups-button">
            {formatMessage(adminVenueGroupMergeMessages.venueGroupMergeButtonText)}
          </Button>
        </Box>
      </Flex>
    </>
  )

  const renderVenueGroupMergeSuccessView = () => (
    <>
      <Box>
        Successfully kicked off venue group merge for source venue group "{selectedSourceVenueGroupName}" to destination venue group "
        {selectedDestinationVenueGroupName}".
      </Box>
    </>
  )
  const renderVenueGroupMergeView = () => (venueGroupMergeSuccess ? renderVenueGroupMergeSuccessView() : renderVenueGroupMergeFormView())

  return (
    <>
      <AdminPageMeta title={formatMessage(adminVenueGroupMergeMessages.venueGroupMergeTitle)} />
      <AdminPageContent title={formatMessage(adminVenueGroupMergeMessages.venueGroupMergeTitle)}>
        {isLoading ? <Loader /> : renderVenueGroupMergeView()}
      </AdminPageContent>
    </>
  )
}
