import { render } from 'react-dom'
import { AdminPage } from '@sevenrooms/admin'
import { svrExport } from '@sevenrooms/core/ui-kit/utils'
import { AdminCustomDomains } from './components'

function renderAdminCustomDomains(id: string) {
  return render(
    <AdminPage>
      <AdminCustomDomains />
    </AdminPage>,
    document.getElementById(id)
  )
}

svrExport('AdminCustomDomains', 'render', renderAdminCustomDomains)
