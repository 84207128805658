import type { Field } from '@sevenrooms/core/form'
import { Label } from '@sevenrooms/core/ui-kit/form'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import type { PropsWithChildren } from 'react'

export interface FluidControlProps<T> {
  field: Field<T>
  primary: string
  secondary?: string
}

export function FluidControl<T>({ field, primary, secondary, children }: PropsWithChildren<FluidControlProps<T>>) {
  const isMobile = useMaxWidthBreakpoint('s')
  return (
    <Box maxWidth="100%" gridColumn={isMobile ? 'auto / span 12' : 'auto / span 6'}>
      <Label primary={primary} secondary={secondary} htmlFor={field.name}>
        {children}
      </Label>
    </Box>
  )
}
