import { useDispatch } from 'react-redux'
import type { CustomAutoTagOperatorType } from '@sevenrooms/core/domain'
import { z, useForm } from '@sevenrooms/core/form'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { FormSelect } from '@sevenrooms/core/ui-kit/form'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { updateConditionTree } from '../../../autoTagSlideOutSlice'

export function CustomAutoTagOperatorSelect({
  path,
  options,
  selectedOperatorType,
}: {
  path: (string | number)[]
  options: SelectOption[]
  selectedOperatorType?: CustomAutoTagOperatorType
}) {
  const dispatch = useDispatch()

  const formValues = z.object({
    operatorType: z.string(),
  })
  const form = useForm(formValues)
  const { field } = form

  form.setValue('operatorType', selectedOperatorType || options[0]?.id || '')

  return (
    <Box width="260px">
      <FormSelect
        options={options}
        field={field.prop('operatorType')}
        onChange={selected => {
          if (!selected || selected === selectedOperatorType) {
            return
          }

          const data = { operator: selected as CustomAutoTagOperatorType }
          dispatch(updateConditionTree({ path, data }))
        }}
      />
    </Box>
  )
}
