import { useDispatch } from 'react-redux'
import { useStoreSelector } from '@sevenrooms/admin/components'
import { useDeleteAdminEmailCampaignTemplateMutation } from '@sevenrooms/core/api'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import {
  VStack,
  HStack,
  Box,
  notify,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalFooter,
  ModalActions,
  type ModalHeaderProps,
} from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { adminOngoingEmailCampaignTemplateMessages } from '../../../locales'
import { AdminOngoingEmailCampaignTemplatesConfirmDeleteModalTestId } from './AdminOngoingEmailCampaignTemplatesConfirmDeleteModal.testIds'
import {
  adminOngoingEmailCampaignTemplatesConfirmDeleteModalName,
  resetState as resetConfirmDeleteModalState,
} from './adminOngoingEmailCampaignTemplatesConfirmDeleteModalSlice'

export interface AdminOngoingEmailCampaignTemplatesConfirmDeleteModalProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
  removeRowByIndex: (index: number) => void // Pass this in as an arg instead of importing to avoid circular dependency
}

export function AdminOngoingEmailCampaignTemplatesConfirmDeleteModal({
  closeHref,
  removeRowByIndex,
}: AdminOngoingEmailCampaignTemplatesConfirmDeleteModalProps) {
  const dispatch = useDispatch()
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { templateId: emailCampaignTemplateId, rowIndex } = useStoreSelector(
    state => state[adminOngoingEmailCampaignTemplatesConfirmDeleteModalName]
  )

  const [deleteEmailCampaignTemplate, { isLoading }] = useDeleteAdminEmailCampaignTemplateMutation()

  const tryDeleteCampaignTemplate = async () => {
    if (isLoading) {
      return
    }
    try {
      await deleteEmailCampaignTemplate({ emailCampaignTemplateId })
      notify({
        content: formatMessage(adminOngoingEmailCampaignTemplateMessages.deleteCampaignTemplateSuccess),
        type: 'success',
      })
      removeRowByIndex(rowIndex)
      nav.closeSurface(closeHref)
      dispatch(resetConfirmDeleteModalState())
    } catch {
      notify({
        content: formatMessage(adminOngoingEmailCampaignTemplateMessages.deleteCampaignTemplateError),
        type: 'error',
      })
    }
  }

  return (
    <Modal data-test="confirmation-modal" ariaLabel={formatMessage(adminOngoingEmailCampaignTemplateMessages.confirmDeleteModalTitle)}>
      <ModalHeader closeHref={closeHref}>
        <VStack spacing="s">
          <HStack spacing="s">
            <Box>
              <Icon name="VMSWeb-warning" color="error" size="lg" />
            </Box>
            <Text color="error" fontWeight="bold">
              {formatMessage(commonMessages.warning)}
            </Text>
          </HStack>
          <ModalTitle title={formatMessage(adminOngoingEmailCampaignTemplateMessages.confirmDeleteModalTitle)} />
        </VStack>
      </ModalHeader>
      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" href={closeHref} data-test={AdminOngoingEmailCampaignTemplatesConfirmDeleteModalTestId.cancel}>
            {formatMessage(commonMessages.cancel)}
          </Button>
          <Button
            variant="primary-warning"
            onClick={tryDeleteCampaignTemplate}
            data-test={AdminOngoingEmailCampaignTemplatesConfirmDeleteModalTestId.confirm}
          >
            {formatMessage(commonMessages.delete)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
