import { useMemo } from 'react'
import type { OngoingEmailCampaignTemplate } from '@sevenrooms/core/api'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'
import { emailContentFieldSchema } from '@sevenrooms/marketing'

export type AdminEmailContentFormData = ZodSchema<typeof useAdminEmailContentForm>
export type AdminEmailContentFormField = Field<AdminEmailContentFormData>

export function useAdminEmailContentForm() {
  return useMemo(
    () =>
      z.object({
        emailSender: emailContentFieldSchema,
        emailSubject: emailContentFieldSchema,
        previewText: emailContentFieldSchema,
        greeting: emailContentFieldSchema,
        emailBodySections: z.array(emailContentFieldSchema).min(1),
        signature: emailContentFieldSchema,
        signoff: emailContentFieldSchema,
        footer: emailContentFieldSchema,
      }),
    []
  )
}

export const getAdminEmailContentDefaultValues = (source?: OngoingEmailCampaignTemplate): AdminEmailContentFormData => {
  const emailBodyParts = source?.emailBodyParts ?? ['']
  const emailBodyPartsConfig = source?.emailBodyPartsConfig ?? [{ isEditable: false }]

  return {
    emailSender: {
      value: source?.emailSender ?? '',
      maxLen: source?.emailSenderMaxLen ?? null,
      isEditable: source?.isEmailSenderUserEditable ?? false,
    },
    emailSubject: {
      value: source?.emailSubject ?? '',
      maxLen: source?.subjectMaxLen ?? null,
      isEditable: source?.isSubjectUserEditable ?? false,
    },
    previewText: {
      value: source?.previewText ?? '',
      maxLen: source?.previewMaxLen ?? null,
      isEditable: source?.isPreviewUserEditable ?? false,
    },
    greeting: {
      value: source?.greeting ?? '',
      maxLen: source?.greetingMaxLen ?? null,
      isEditable: source?.isGreetingUserEditable ?? false,
    },
    emailBodySections: emailBodyParts.map((part, index) => ({
      value: part,
      maxLen: emailBodyPartsConfig[index]?.maxLen ?? null,
      isEditable: emailBodyPartsConfig[index]?.isEditable ?? false,
    })),
    signature: {
      value: source?.signature ?? '',
      maxLen: source?.signatureMaxLen ?? null,
      isEditable: source?.isSignatureUserEditable ?? false,
    },
    signoff: {
      value: source?.signoff ?? '',
      maxLen: source?.signoffMaxLen ?? null,
      isEditable: source?.isSignoffUserEditable ?? false,
    },
    footer: {
      value: source?.footer ?? '',
      maxLen: source?.footerMaxLen ?? null,
      isEditable: source?.isFooterUserEditable ?? false,
    },
  }
}
