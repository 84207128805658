import { defineMessages } from '@sevenrooms/core/locales'

export const adminVenueHistoryTrackingMessages = defineMessages({
  title: {
    id: 'venueHistoryTracking.title',
    defaultMessage: 'History Tracking messages',
  },
  venueConfigurationBreadcrumb: {
    id: 'venueHistoryTracking.venueConfiguration',
    defaultMessage: 'Venue Group configuration',
  },
  noHistory: {
    id: 'venueHistoryTracking.noHistory',
    defaultMessage: 'This venue group has no saved history yet.',
  },
  venueTab: {
    id: 'venueHistoryTracking.venueTab',
    defaultMessage: 'Venue Group History',
  },
  who: {
    id: 'venueHistoryTracking.who',
    defaultMessage: 'Who',
  },
  when: {
    id: 'venueHistoryTracking.when',
    defaultMessage: 'When (UTC)',
  },
  what: {
    id: 'venueHistoryTracking.what',
    defaultMessage: 'What',
  },
})
