import { defineMessages } from '@sevenrooms/core/locales'

export const adminCustomDomainsMessages = defineMessages({
  title: {
    id: 'adminCustomDomains.title',
    defaultMessage: 'Custom Domains',
  },
  tableVenueGroupNameHeader: {
    id: 'adminCustomDomains.tableVenueGroupNameHeader',
    defaultMessage: 'Venue Group',
  },
  tableCustomDomainHeader: {
    id: 'adminCustomDomains.tableCustomDomainHeader',
    defaultMessage: 'Domain',
  },
  tableVerifiedHeader: {
    id: 'adminCustomDomains.tableVerifiedHeader',
    defaultMessage: 'Verification',
  },
  verifiedStatus: {
    id: 'adminCustomDomains.verifiedStatus',
    defaultMessage: 'Verified',
  },
  notVerifiedStatus: {
    id: 'adminCustomDomains.notVerifiedStatus',
    defaultMessage: 'Not Verified',
  },
  refreshList: {
    id: 'adminCustomDomains.refreshList',
    defaultMessage: 'Refresh',
  },
} as const)
