import { useMemo } from 'react'
import { z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { adminVenueGroupMergeMessages } from '../../locales'

export function useAdminVenueGroupMergeForm() {
  const { formatMessage } = useLocales()

  return useMemo(
    () =>
      z
        .object({
          sourceVenueGroupId: z.string().nullable(),
          destinationVenueGroupId: z.string().nullable(),
          completedTracker: z.boolean(),
          agreedToScheduledMerge: z.boolean(),
        })
        .superRefine((data, ctx) => {
          if (!data.sourceVenueGroupId) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['sourceVenueGroupId'],
              message: formatMessage(adminVenueGroupMergeMessages.sourceVenueGroupRequiredMsg),
            })
          }
          if (!data.destinationVenueGroupId) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['destinationVenueGroupId'],
              message: formatMessage(adminVenueGroupMergeMessages.destinationVenueGroupRequiredMsg),
            })
          }
          if (data.sourceVenueGroupId && data.destinationVenueGroupId && data.sourceVenueGroupId === data.destinationVenueGroupId) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['destinationVenueGroupId'],
              message: formatMessage(adminVenueGroupMergeMessages.sameVenueGroupMsg),
            })
          }
          if (!data.completedTracker) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['completedTracker'],
              message: formatMessage(adminVenueGroupMergeMessages.trackerRequiredMsg),
            })
          }
          if (!data.agreedToScheduledMerge) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['agreedToScheduledMerge'],
              message: formatMessage(adminVenueGroupMergeMessages.mergeConsentRequireMsg),
            })
          }
        }),
    [formatMessage]
  )
}
