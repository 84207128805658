import { defineMessages } from '@sevenrooms/core/locales'

export const adminOngoingEmailCampaignTemplateMessages = defineMessages({
  createNewCampaign: {
    id: 'admin.ongoingEmailCampaignTemplate.createNewCampaignTemplate',
    defaultMessage: 'Admin - Create New Automated Email Campaign Template',
  },
  editCampaign: {
    id: 'admin.ongoingEmailCampaignTemplate.editCampaignTemplate',
    defaultMessage: 'Admin - Edit Automated Email Campaign Template',
  },
  emailDetails: {
    id: 'admin.ongoingEmailCampaignTemplate.emailDetails',
    defaultMessage: 'Email Details',
  },
  campaignName: {
    id: 'admin.ongoingEmailCampaignTemplate.campaignTemplateName',
    defaultMessage: 'Internal Name of Automated Email',
  },
  emailReplyTo: {
    id: 'admin.ongoingEmailCampaignTemplate.emailReplyTo',
    defaultMessage: 'Reply-to Address',
  },
  confirmDeleteModalTitle: {
    id: 'admin.ongoingEmailCampaignTemplate.confirmDeleteModalTitle',
    defaultMessage: 'Delete Automated Email?',
  },
  campaignDescription: {
    id: 'admin.ongoingEmailCampaignTemplate.campaignTemplateDescription',
    defaultMessage: 'Goal of Email (shown to customer)',
  },
  productType: {
    id: 'admin.ongoingEmailCampaignTemplate.productType',
    defaultMessage: 'Product Type',
  },
  category: {
    id: 'admin.ongoingEmailCampaignTemplate.category',
    defaultMessage: 'Category',
  },
  venueType: {
    id: 'admin.ongoingEmailCampaignTemplate.venueType',
    defaultMessage: 'Venue Type',
  },
  region: {
    id: 'admin.ongoingEmailCampaignTemplate.region',
    defaultMessage: 'Region',
  },
  createNewEmailTemplate: {
    id: 'admin.ongoingEmailCampaignTemplate.createNewEmailTemplate',
    defaultMessage: 'Create new Email Template',
  },
  maxCharacterCount: {
    id: 'admin.ongoingEmailCampaignTemplate.maxCharacterCount',
    defaultMessage: 'Max Character Count',
  },
  maxCharacterCountExceeded: {
    id: 'admin.ongoingEmailCampaignTemplate.maxCharacterCountExceeded',
    defaultMessage: 'Character count exceeds maximum for this section',
  },
  isUserEditable: {
    id: 'admin.ongoingEmailCampaignTemplate.isUserEditable',
    defaultMessage: 'Editable by customers',
  },
  emailSender: {
    id: 'admin.ongoingEmailCampaignTemplate.emailSender',
    defaultMessage: 'From',
  },
  subjectLine: {
    id: 'admin.ongoingEmailCampaignTemplate.subjectLine',
    defaultMessage: 'Subject Line',
  },
  previewEmail: {
    id: 'admin.ongoingEmailCampaignTemplate.previewEmail',
    defaultMessage: 'Preview Email',
  },
  previewText: {
    id: 'admin.ongoingEmailCampaignTemplate.previewText',
    defaultMessage: 'Preview Text',
  },
  previewTextDescription: {
    id: 'admin.ongoingEmailCampaignTemplate.previewTextDescription',
    defaultMessage: 'This snippet will appear in the inbox after the subject line',
  },
  greeting: {
    id: 'admin.ongoingEmailCampaignTemplate.previewTextDescription',
    defaultMessage: 'Greeting/Introduction',
  },
  body: {
    id: 'admin.ongoingEmailCampaignTemplate.body',
    defaultMessage: 'Body',
  },
  deleteSection: {
    id: 'admin.ongoingEmailCampaignTemplate.deleteSection',
    defaultMessage: 'Delete Section',
  },
  addSection: {
    id: 'admin.ongoingEmailCampaignTemplate.addSection',
    defaultMessage: 'Add new body section',
  },
  signature: {
    id: 'admin.ongoingEmailCampaignTemplate.signature',
    defaultMessage: 'Signature',
  },
  signoff: {
    id: 'admin.ongoingEmailCampaignTemplate.signoff',
    defaultMessage: 'Sign-off',
  },
  footer: {
    id: 'admin.ongoingEmailCampaignTemplate.footer',
    defaultMessage: 'Footer',
  },
  ongoingCampaigns: {
    id: 'admin.ongoingEmailCampaignTemplates.ongoingCampaigns',
    defaultMessage: 'Ongoing Campaigns',
  },
  oneTimeSends: {
    id: 'admin.ongoingEmailCampaignTemplates.oneTimeSends',
    defaultMessage: 'One Time Sends',
  },
  onPremCampaigns: {
    id: 'admin.ongoingEmailCampaignTemplates.onPremCampaigns',
    defaultMessage: 'On-Prem Automated Emails',
  },
  orderingCampaigns: {
    id: 'admin.ongoingEmailCampaignTemplates.orderingCampaigns',
    defaultMessage: 'Ordering Automated Emails',
  },
  ongoingCampaignsTitle: {
    id: 'admin.ongoingEmailCampaignTemplates.ongoingCampaignsTitle',
    defaultMessage: 'Admin Pre-built Ongoing Automated Emails',
  },
  oneTimeSendsTitle: {
    id: 'admin.ongoingEmailCampaignTemplates.oneTimeSendsTitle',
    defaultMessage: 'Admin Pre-built One Time Sends',
  },
  templateName: {
    id: 'admin.ongoingEmailCampaignTemplates.templateName',
    defaultMessage: 'Template Name',
  },
  lastUpdated: {
    id: 'admin.ongoingEmailCampaignTemplates.lastUpdated',
    defaultMessage: 'Last Updated',
  },
  defaultStatus: {
    id: 'admin.ongoingEmailCampaignTemplates.defaultStatus',
    defaultMessage: 'Default On/Off',
  },
  addNewCampaign: {
    id: 'admin.ongoingEmailCampaignTemplates.addNewCampaign',
    defaultMessage: 'Add New Automated Email',
  },
  regionFilter: {
    id: 'admin.ongoingEmailCampaignTemplates.regionFilter',
    defaultMessage: 'Region Filter',
  },
  venueTypeFilter: {
    id: 'admin.ongoingEmailCampaignTemplates.venueTypeFilter',
    defaultMessage: 'Venue Type Filter',
  },
  orderingPlatformFilter: {
    id: 'admin.ongoingEmailCampaignTemplates.orderingPlatformFilter',
    defaultMessage: 'Ordering Platform',
  },
  allRegions: {
    id: 'admin.ongoingEmailCampaignTemplates.allRegions',
    defaultMessage: 'All regions',
  },
  allVenueTypes: {
    id: 'admin.ongoingEmailCampaignTemplates.allVenueTypes',
    defaultMessage: 'All venue types',
  },
  allPlatforms: {
    id: 'admin.ongoingEmailCampaignTemplates.allPlatforms',
    defaultMessage: 'All platforms',
  },
  northAmerica: {
    id: 'admin.ongoingEmailCampaignTemplates.northAmerica',
    defaultMessage: 'North America',
  },
  europe: {
    id: 'admin.ongoingEmailCampaignTemplates.europe',
    defaultMessage: 'UK/Europe',
  },
  asia: {
    id: 'admin.ongoingEmailCampaignTemplates.asia',
    defaultMessage: 'Asia',
  },
  australia: {
    id: 'admin.ongoingEmailCampaignTemplates.australia',
    defaultMessage: 'AUS/NZ',
  },
  middleEast: {
    id: 'admin.ongoingEmailCampaignTemplates.middleEast',
    defaultMessage: 'Middle East',
  },
  latinAmerica: {
    id: 'admin.ongoingEmailCampaignTemplates.latinAmerica',
    defaultMessage: 'LATAM',
  },
  africa: {
    id: 'admin.ongoingEmailCampaignTemplates.africa',
    defaultMessage: 'Africa',
  },
  default: {
    id: 'admin.ongoingEmailCampaignTemplates.default',
    defaultMessage: 'Default',
  },
  fineDining: {
    id: 'admin.ongoingEmailCampaignTemplates.fineDining',
    defaultMessage: 'Fine Dining',
  },
  casual: {
    id: 'admin.ongoingEmailCampaignTemplates.casual',
    defaultMessage: 'Casual',
  },
  contemporary: {
    id: 'admin.ongoingEmailCampaignTemplates.contemporary',
    defaultMessage: 'Contemporary',
  },
  nightlife: {
    id: 'admin.ongoingEmailCampaignTemplates.nightlife',
    defaultMessage: 'Nightlife',
  },
  hotels: {
    id: 'admin.ongoingEmailCampaignTemplates.hotels',
    defaultMessage: 'Hotels',
  },
  eatertainment: {
    id: 'admin.ongoingEmailCampaignTemplates.eatertainment',
    defaultMessage: 'Eatertainment',
  },
  orderingPlatform: {
    id: 'admin.ongoingEmailCampaignTemplates.orderingPlatform',
    defaultMessage: 'Ordering Platform',
  },
  sevenrooms: {
    id: 'admin.ongoingEmailCampaignTemplates.ordering_sevenrooms',
    defaultMessage: 'Sevenrooms',
  },
  bbot: {
    id: 'admin.ongoingEmailCampaignTemplates.ordering_bbot',
    defaultMessage: 'BBOT',
  },
  olo: {
    id: 'admin.ongoingEmailCampaignTemplates.ordering_olo',
    defaultMessage: 'Olo',
  },
  edit: {
    id: 'admin.ongoingEmailCampaignTemplates.edit',
    defaultMessage: 'Edit',
  },
  duplicate: {
    id: 'admin.ongoingEmailCampaignTemplates.duplicate',
    defaultMessage: 'Duplicate',
  },
  delete: {
    id: 'admin.ongoingEmailCampaignTemplates.delete',
    defaultMessage: 'Delete',
  },
  thankYou: {
    id: 'admin.ongoingEmailCampaignTemplates.thank_you',
    defaultMessage: 'Thank You',
  },
  winback: {
    id: 'admin.ongoingEmailCampaignTemplates.winback',
    defaultMessage: 'Winback',
  },
  feedback: {
    id: 'admin.ongoingEmailCampaignTemplates.feedback',
    defaultMessage: 'Feedback',
  },
  abandon: {
    id: 'admin.ongoingEmailCampaignTemplates.abandon',
    defaultMessage: 'Abandon',
  },
  other: {
    id: 'admin.ongoingEmailCampaignTemplates.other',
    defaultMessage: 'Other',
  },
  on: {
    id: 'admin.ongoingEmailCampaignTemplates.on',
    defaultMessage: 'On',
  },
  off: {
    id: 'admin.ongoingEmailCampaignTemplates.off',
    defaultMessage: 'Off',
  },
  saveCampaignSuccess: {
    id: 'admin.ongoingEmailCampaignTemplates.saveCampaignSuccess',
    defaultMessage: 'Email template {templateName} has been saved.',
  },
  copy: {
    id: 'admin.ongoingEmailCampaignTemplates.copy',
    defaultMessage: 'COPY',
  },
  activate: {
    id: 'admin.ongoingEmailCampaignTemplates.activate',
    defaultMessage: 'Activate',
  },
  deactivate: {
    id: 'admin.ongoingEmailCampaignTemplates.deactivate',
    defaultMessage: 'Deactivate',
  },
  activateCampaignTitle: {
    id: 'admin.ongoingEmailCampaignTemplates.activateCampaignTitle',
    defaultMessage: 'Activate Automated Email?',
  },
  deactivateCampaignTitle: {
    id: 'admin.ongoingEmailCampaignTemplates.deactivateCampaignTitle',
    defaultMessage: 'Deactivate Automated Email?',
  },
  activateCampaignsTitle: {
    id: 'admin.ongoingEmailCampaignTemplates.activateCampaignsTitle',
    defaultMessage: 'Activate Automated Emails?',
  },
  deactivateCampaignsTitle: {
    id: 'admin.ongoingEmailCampaignTemplates.deactivateCampaignsTitle',
    defaultMessage: 'Deactivate Automated Emails?',
  },
  activateCampaignWarning: {
    id: 'admin.ongoingEmailCampaignTemplates.activateCampaignWarning',
    defaultMessage: 'Are you sure you want to activate this automated email for all eligible venues?',
  },
  deactivateCampaignWarning: {
    id: 'admin.ongoingEmailCampaignTemplates.deactivateCampaignWarning',
    defaultMessage:
      'Are you sure you want to deactivate this automated email for all eligible venues? This will remove the automated email template from the gallery.',
  },
  activateCampaignsWarning: {
    id: 'admin.ongoingEmailCampaignTemplates.activateCampaignsWarning',
    defaultMessage: 'Are you sure you want to activate {numCampaigns} automated emails for all eligible venues?',
  },
  deactivateCampaignsWarning: {
    id: 'admin.ongoingEmailCampaignTemplates.deactivateCampaignsWarning',
    defaultMessage:
      'Are you sure you want to deactivate {numCampaigns} automated emails for all eligible venues? This will remove the automated email templates from the gallery.',
  },
  toggleCampaignError: {
    id: 'admin.ongoingEmailCampaignTemplates.toggleCampaignError',
    defaultMessage: 'Unable to toggle selected campaign(s)',
  },
  deleteCampaignTemplateSuccess: {
    id: 'admin.ongoingEmailCampaignTemplates.deleteCampaignTemplateSuccess',
    defaultMessage: 'Deleted automated email template',
  },
  deleteCampaignTemplateError: {
    id: 'admin.ongoingEmailCampaignTemplates.deleteCampaignTemplateError',
    defaultMessage: 'Unable to delete selected campaign template',
  },
  saveCampaignTemplateError: {
    id: 'admin.ongoingEmailCampaignTemplates.saveCampaignTemplateError',
    defaultMessage: 'Unable to save campaign template',
  },
  pushUpdatesLive: {
    id: 'admin.ongoingEmailCampaignTemplates.pushUpdatesLive',
    defaultMessage: 'Push Updates Live',
  },
  updatesNotLive: {
    id: 'admin.ongoingEmailCampaignTemplates.updatesNotLive',
    defaultMessage: 'Updates not live',
  },
  updatesToPush: {
    id: 'admin.ongoingEmailCampaignTemplates.updatesToPush',
    defaultMessage: 'Updates to Push',
  },
  pushToLiveError: {
    id: 'admin.ongoingEmailCampaignTemplates.pushToLiveError',
    defaultMessage: 'An error has occured, try again.',
  },
  pushToLiveSuccess: {
    id: 'admin.ongoingEmailCampaignTemplates.pushToLiveSuccess',
    defaultMessage: 'Updates are being pushed to VMS',
  },
} as const)
