import { defineMessages } from '@sevenrooms/core/locales'

export const defaultAutoTagTierEditorMessages = defineMessages({
  addTier: {
    id: 'defaultAutoTag.tierEditor.addTier',
    defaultMessage: 'Add Tier',
  },
  ANNIVERSARY_LOCAL: {
    id: 'defaultAutoTag.tierEditor.anniversaryLocal',
    defaultMessage:
      'Tag clients within a window of {0} day(s) before & after their anniversary date. This tag will get applied at the start of this window. If a client visits at any point with the tag active, this tag will transition to "{1}". If a client does not visit within this window, this tag will remove itself after the window ends.',
  },
  CELEBRATED_ANNIVERSARY_LOCAL: {
    id: 'defaultAutoTag.tierEditor.celebratedAnniversaryLocal',
    defaultMessage: 'Tag clients who have completed a visit while having a "{0}" tag, and remove this tag automatically after {1} day(s).',
  },
  ANNIVERSARY: {
    id: 'defaultAutoTag.tierEditor.anniversary',
    defaultMessage:
      'Tag clients within a window of {0} day(s) before & after their anniversary date. This tag will get applied at the start of this window. If a client visits at any point with the tag active, this tag will transition to "{1}". If a client does not visit within this window, this tag will remove itself after the window ends.',
  },
  CELEBRATED_ANNIVERSARY: {
    id: 'defaultAutoTag.tierEditor.celebratedAnniversary',
    defaultMessage: 'Tag clients who have completed a visit while having a "{0}" tag, and remove this tag automatically after {1} day(s).',
  },
  BIRTHDAY_LOCAL: {
    id: 'defaultAutoTag.tierEditor.birthdayLocal',
    defaultMessage:
      'Tag clients within a window of {0} day(s) before & after their birthday. This tag will get applied at the start of this window. If a client visits at any point with the tag active, this tag will transition to "{1}". If a client does not visit within this window, this tag will remove itself after the window ends.',
  },
  CELEBRATED_BIRTHDAY_LOCAL: {
    id: 'defaultAutoTag.tierEditor.celebratedBirthdayLocal',
    defaultMessage: 'Tag clients who have completed a visit while having a "{0}" tag, and remove this tag automatically after {1} day(s).',
  },
  BIRTHDAY: {
    id: 'defaultAutoTag.tierEditor.birthday',
    defaultMessage:
      'Tag clients within a window of {0} day(s) before & after their birthday. This tag will get applied at the start of this window. If a client visits at any point with the tag active, this tag will transition to "{1}". If a client does not visit within this window, this tag will remove itself after the window ends.',
  },
  CELEBRATED_BIRTHDAY: {
    id: 'defaultAutoTag.tierEditor.celebratedBirthday',
    defaultMessage: 'Tag clients who have completed a visit while having a "{0}" tag, and remove this tag automatically after {1} day(s).',
  },
  FIRST_TIMER: {
    id: 'defaultAutoTag.tierEditor.firstTimer',
    defaultMessage: 'Tag clients when they are visiting your venue for the first time.',
  },
  FIRST_TIMER_COMPLETED: {
    id: 'defaultAutoTag.tierEditor.firstTimerCompleted',
    defaultMessage: 'Tag clients who have visited your venue for the first time in the past {0} day(s).',
  },
  FIRST_TIMER_GLOBAL: {
    id: 'defaultAutoTag.tierEditor.firstTimerGlobal',
    defaultMessage: 'Tag clients when they are visiting your group for the first time.',
  },
  FIRST_TIMER_COMPLETED_GLOBAL: {
    id: 'defaultAutoTag.tierEditor.firstTimerCompletedGlobal',
    defaultMessage: 'Tag clients who have visited your group for the first time in the past {0} day(s).',
  },
  POSITIVE_REVIEW: {
    id: 'defaultAutoTag.tierEditor.positiveReview',
    defaultMessage: 'Tag clients when they have left a positive review within the last {0} month(s).',
  },
  NEGATIVE_REVIEW: {
    id: 'defaultAutoTag.tierEditor.negativeReview',
    defaultMessage: 'Tag clients when they have left a negative review within the last {0} month(s).',
  },
  POSITIVE_FEEDBACK: {
    id: 'defaultAutoTag.tierEditor.positiveFeedback',
    defaultMessage: 'Tag clients when they have left positive feedback within the last {0} month(s).',
  },
  NEGATIVE_FEEDBACK: {
    id: 'defaultAutoTag.tierEditor.negativeFeedback',
    defaultMessage: 'Tag clients when they have left negative feedback within the last {0} month(s).',
  },
  GROUP_REVIEW: {
    id: 'defaultAutoTag.tierEditor.groupReview',
    defaultMessage: "Tag clients when they have left a review at one of your group's other locations within the last {0} month(s).",
  },
  GROUP_FEEDBACK: {
    id: 'defaultAutoTag.tierEditor.groupFeedback',
    defaultMessage: "Tag clients when they have left feedback at one of your group's other locations within the last {0} month(s).",
  },
  SPEND_LOCAL_TIER: {
    id: 'defaultAutoTag.tierEditor.spendLocalTier',
    defaultMessage: `Tag clients with at least {0} visit(s) and a {1} of {2}.`,
  },
  SPEND_GLOBAL_TIER: {
    id: 'defaultAutoTag.tierEditor.spendGlobalTier',
    defaultMessage: `Tag clients with at least {0} visit(s) and a {1} of {2}.`,
  },
  VISITS_GLOBAL_TIER: {
    id: 'defaultAutoTag.tierEditor.visitsGlobalTier',
    defaultMessage: 'Tag clients with at least {0} visit(s) in the past {1} month(s) to at least {2} venue(s) .',
  },
  VISITS_LOCAL_TIER: {
    id: 'defaultAutoTag.tierEditor.visitslocalTier',
    defaultMessage: 'Tag clients with at least {0} visit(s) in the past {1} month(s).',
  },
  WE_MISS_YOU: {
    id: 'defaultAutoTag.tierEditor.weMissYou',
    defaultMessage: 'Tag clients whose last visit was between {0} month(s) and {1} month(s) ago.',
  },
  WELCOME_BACK: {
    id: 'defaultAutoTag.tierEditor.welcomeBack',
    defaultMessage: 'Tag clients who had a "{0}" tag and now have an upcoming reservation.',
  },
  WELCOME_BACK_COMPLETED: {
    id: 'defaultAutoTag.tierEditor.welcomeBackCompleted',
    defaultMessage:
      'This tag will show up after a client tagged with a "{0}" tag has completed a visit and will be removed automatically after {1} day(s).',
  },
  WE_MISS_YOU_GLOBAL: {
    id: 'defaultAutoTag.tierEditor.weMissYouGlobal',
    defaultMessage:
      'Tag clients whose last visit was between {0} month(s) and {1} month(s) ago who have visited at least {2} venue(s) in the venue group.',
  },
  WELCOME_BACK_GLOBAL: {
    id: 'defaultAutoTag.tierEditor.welcomeBackGlobal',
    defaultMessage: 'Tag clients who had a "{0}" tag and now have an upcoming reservation at any venue in the group.',
  },
  WELCOME_BACK_COMPLETED_GLOBAL: {
    id: 'defaultAutoTag.tierEditor.welcomeBackCompletedGlobal',
    defaultMessage:
      'This tag will show up after a client tagged with a "{0}" tag has completed a visit and will be removed automatically after {1} day(s).',
  },
  BOOKED_THROUGH_OPENTABLE: {
    id: 'defaultAutoTag.tierEditor.bookedThroughOpenTable',
    defaultMessage: 'Tag clients who booked through OpenTable and visited at least once in the last day.',
  },
  NO_SHOW: {
    id: 'defaultAutoTag.tierEditor.noShow',
    defaultMessage: "Tag guests who didn't show up for their reservation in the last 7 days.",
  },
  WAITLIST_ABANDON: {
    id: 'defaultAutoTag.tierEditor.waitlistAbandon',
    defaultMessage: "Tag guests who abandoned waitlist (canceled or didn't show up) in the last 7 days.",
  },
  CANCELLATION: {
    id: 'defaultAutoTag.tierEditor.cancellation',
    defaultMessage: 'Tag guests who canceled their reservation in the last 7 days.',
  },
  UPCOMING_RESERVATION_IN_30_DAYS: {
    id: 'defaultAutoTag.tierEditor.upcomingReservationIn30Days',
    defaultMessage: 'Tag guests who have an upcoming reservation in the next 30 days.',
  },
  REFERRER_TIER: {
    id: 'defaultAutoTag.tierEditor.referrerTier',
    defaultMessage: 'Tag clients who have referred at least {0} completed reservations from their unique referral link.',
  },
  RESERVATION_REQUESTS: {
    id: 'defaultAutoTag.tierEditor.reservationRequests',
    defaultMessage:
      'Tag guests who have made at least {0} unfulfilled reservation requests through {1} with a party size of at least {2} in the past {3} days.',
  },
  VISITS_LOCAL_MARKETING_SEGMENTATION_TIER: {
    id: 'defaultAutoTag.tierEditor.visitsLocalMarketingSegmentationTier',
    defaultMessage: 'Tag clients who have exactly 2 completed reservations. The tag will automatically be removed after 7 days.',
  },
  CUSTOM: {
    id: 'defaultAutoTag.tierEditor.custom',
    defaultMessage: '',
  },
} as const)
