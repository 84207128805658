import { useState } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { Tab, TabList, TabPanel, Tabs } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { paymentsDebuggerMessages } from '../../locales'
import { DisplayChangelogItem } from './DisplayChangelogItem'
import { DisplayLogExplorer } from './DisplayLogExplorer'
import { DisplayResultEntity } from './DisplayResultEntity'
import type { DebugData, Item, LogItem } from './interfaces'

export function PaymentsDebuggerResults(props: { data?: DebugData }) {
  const { data } = props
  const [selectedIndex, setSelectedIndex] = useState(0)
  const { formatMessage } = useLocales()

  return (
    <Tabs onSelect={setSelectedIndex} selectedIndex={selectedIndex}>
      <TabList>
        <Tab data-test="tab-actuals">
          {formatMessage(paymentsDebuggerMessages.actuals)} [{data?.actuals.length}]
        </Tab>
        <Tab data-test="tab-invoices">
          {formatMessage(paymentsDebuggerMessages.invoices)} [{data?.invoices.length}]
        </Tab>
        <Tab data-test="tab-histories">
          {formatMessage(paymentsDebuggerMessages.historyItems)} [{data?.histories.length}]
        </Tab>
        <Tab data-test="tab-httpLogs">
          {formatMessage(paymentsDebuggerMessages.httpLogs)} [{data?.httpLogs.length}]
        </Tab>
        <Tab data-test="tab-changelog">
          {formatMessage(paymentsDebuggerMessages.changelog)} [{data?.changelog.length}]
        </Tab>
        <Tab data-test="tab-logsExplorer">{formatMessage(paymentsDebuggerMessages.logsExplorer)}</Tab>
      </TabList>
      <TabPanel>
        {data?.actuals.map((item: Item) => (
          <DisplayResultEntity key={item.shown.id} data={data} item={item} />
        ))}
      </TabPanel>
      <TabPanel>
        {data?.invoices.map((item: Item) => (
          <DisplayResultEntity key={item.shown.id} data={data} item={item} />
        ))}
      </TabPanel>
      <TabPanel>
        {data?.histories.map((item: Item) => (
          <DisplayResultEntity key={item.shown.id} data={data} item={item} />
        ))}
      </TabPanel>
      <TabPanel>
        <Text fontStyle="italic">{formatMessage(paymentsDebuggerMessages.httpLogsInfo)}</Text>
        {data?.httpLogs.map((item: Item) => (
          <DisplayResultEntity key={item.shown.id} data={data} item={item} />
        ))}
      </TabPanel>
      <TabPanel>
        {data?.changelog.map((item: LogItem) => (
          <DisplayChangelogItem key={item.datetime} item={item} />
        ))}
      </TabPanel>
      <TabPanel>{data && <DisplayLogExplorer data={data} />}</TabPanel>
    </Tabs>
  )
}
