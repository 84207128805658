import { DateTime } from '@sevenrooms/core/timepiece'
import type { BlockStatus as BlockStatusApi } from '../../api'
import type { BlockStatus } from '../../domain'

export namespace BlockStatusAdapter {
  export function toClient(data: BlockStatusApi): BlockStatus {
    return {
      email: data.email,
      isBlocked: data.is_blocked,
      updatedAt: data.updated_at ? DateTime.fromSafe(data.updated_at) : undefined,
      expiresAt: data.expires_at ? DateTime.fromSafe(data.expires_at) : undefined,
    }
  }
}
