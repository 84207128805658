import React from 'react'
import { Flex, Box, HStack, SecondaryHeader, SettingsPageContentLayout } from '@sevenrooms/core/ui-kit/layout'

const ADMIN_HEADER_HEIGHT = 100

export interface AdminPageContentProps {
  title?: React.ReactNode
  breadcrumbs?: React.ReactNode
  description?: React.ReactNode
  actions?: React.ReactNode
  tab?: React.ReactNode
  headerWidth?: string
  secondHeaderMaxWidth?: React.CSSProperties['maxWidth']
}

export function AdminPageContent({
  title,
  breadcrumbs,
  description,
  actions,
  headerWidth,
  secondHeaderMaxWidth = '968px',
  tab,
  children,
}: React.PropsWithChildren<AdminPageContentProps>) {
  return (
    <>
      {title && (
        <SecondaryHeader
          top={ADMIN_HEADER_HEIGHT}
          title={title}
          breadcrumbs={
            breadcrumbs && (
              <HStack maxWidth={secondHeaderMaxWidth} ml="auto" mr="auto">
                {breadcrumbs}
              </HStack>
            )
          }
          description={description}
          actions={actions}
          width={headerWidth}
          maxWidth={secondHeaderMaxWidth}
          isAdmin
          tab={tab}
        />
      )}

      <Flex width="100%" height="100%" justifyContent="center">
        <Box width="80%" height="100%" mb="xl" ml="xl" mr="xl" maxWidth="968px">
          <SettingsPageContentLayout paddingTop={ADMIN_HEADER_HEIGHT}>{children}</SettingsPageContentLayout>
        </Box>
      </Flex>
    </>
  )
}
