import { skipToken } from '@reduxjs/toolkit/query'
import {
  TollFreeVerificationStatus,
  useGetMostRecentTollFreeVenueGroupPhoneNumberQuery,
  useGetVenueBusinessContactQuery,
} from '@sevenrooms/core/api'
import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { HStack, SimpleSection, Loader } from '@sevenrooms/core/ui-kit/layout'
import { StatusLabel, Text, type StatusLabelVariant } from '@sevenrooms/core/ui-kit/typography'
import { adminVenuePhoneNumbersMessages } from '../../locales'
import { TollFreeVerificationForm } from './TollFreeVerificationForm'
import type { TollFreeVerificationFormSchema, StatusInfoType } from './VenuePhoneNumbers.zod'

interface TollFreeVerificationCardProps {
  field: Field<TollFreeVerificationFormSchema>
  venueId: string
  venueGroupId: string
  initiallyCollapsed?: boolean
  statusInfo: StatusInfoType | null
  disabled?: boolean
}

const DateFormatter = new Intl.DateTimeFormat()

const StatusToTagType: Map<TollFreeVerificationStatus, StatusLabelVariant> = new Map([
  [TollFreeVerificationStatus.UNVERIFIED, 'inactive'],
  [TollFreeVerificationStatus.PENDING, 'status'],
  [TollFreeVerificationStatus.PENDING, 'warning'],
  [TollFreeVerificationStatus.REJECTED, 'error'],
  [TollFreeVerificationStatus.READY_FOR_VERIFICATION, 'warning'],
  [TollFreeVerificationStatus.VERIFIED, 'success'],
])

export function TollFreeVerificationCard({
  field,
  venueId,
  venueGroupId,
  initiallyCollapsed,
  statusInfo,
  disabled,
}: TollFreeVerificationCardProps) {
  const { formatMessage } = useLocales()
  const { data, isFetching } = useGetMostRecentTollFreeVenueGroupPhoneNumberQuery(disabled ? skipToken : { venueGroupId })
  const { data: contactData, isFetching: isFetchingContact } = useGetVenueBusinessContactQuery(disabled ? skipToken : { venueId })

  return (
    <SimpleSection
      title={
        <HStack display="flex" width="100%" alignItems="center" columnGap="m">
          {formatMessage(adminVenuePhoneNumbersMessages.tollFreeVerificationSectionTitle)}
          {statusInfo && (
            <>
              <StatusLabel
                variant={StatusToTagType.get(statusInfo.status) || 'status'}
                info={statusInfo.description ? <Text color="lightFont">{statusInfo.description}</Text> : undefined}
              >
                {formatMessage(adminVenuePhoneNumbersMessages[`tfvStatusLabel.${statusInfo.status}`])}
              </StatusLabel>

              <Text>{DateFormatter.format(statusInfo.updatedAt)}</Text>
            </>
          )}
        </HStack>
      }
      key={venueId}
      initiallyCollapsed={initiallyCollapsed}
    >
      {isFetching || isFetchingContact ? (
        <Loader />
      ) : (
        <TollFreeVerificationForm field={field} contactData={contactData} latestVerifiedTollFreeInfo={data} disabled={disabled} />
      )}
    </SimpleSection>
  )
}
