import _ from 'lodash'
import { useDispatch } from 'react-redux'
import type { AdminAutoTagConfig } from '@sevenrooms/core/api'
import { z, useForm } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { DateTime } from '@sevenrooms/core/timepiece'
import { Label, RadioGroup, type RadioChoice, FormToggle } from '@sevenrooms/core/ui-kit/form'
import { DividerLine, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Header, Text } from '@sevenrooms/core/ui-kit/typography'
import { autoTagSlideOutMessages, defaultAutoTagTierDescriptions } from '../../../../locales'
import { updateSlideOutState } from '../autoTagSlideOutSlice'
import { DefaultAutoTagTierEditor } from './DefaultAutoTagTierEditor'

export function DefaultAutoTagSlideOutContent({
  autoTagConfig,
  tagDisplayName,
  radioChoices,
}: {
  autoTagConfig: AdminAutoTagConfig
  tagDisplayName: string
  radioChoices: RadioChoice<string>[]
}) {
  const { formatMessage } = useLocales()
  const dispatch = useDispatch()

  const formValues = z.object({
    isActive: z.boolean(),
  })
  const form = useForm(formValues, { defaultValues: { isActive: autoTagConfig.isActive } })
  const { field } = form

  const toggleAutoTagConfigStatus = () => {
    const updatedAutoTagConfig = _.cloneDeep(autoTagConfig)
    const updatedStatus = !autoTagConfig.isActive
    updatedAutoTagConfig.isActive = updatedStatus
    updatedAutoTagConfig.tierConfigs = updatedAutoTagConfig.tierConfigs.map(tierConfig =>
      Object.assign(tierConfig, { isActive: updatedStatus })
    )
    dispatch(updateSlideOutState({ autoTagConfig: updatedAutoTagConfig }))
  }

  return (
    <VStack spacing="lm">
      <HStack justifyContent="space-between">
        <Header type="h3">{autoTagConfig.nameDisplay}</Header>
        <FormToggle
          field={field.prop('isActive')}
          onClick={() => toggleAutoTagConfigStatus()}
          label={formatMessage(form.getValues('isActive') ? commonMessages.active : commonMessages.inactive)}
        />
      </HStack>
      {autoTagConfig?.updated && (
        <Text color="secondaryFont">
          {formatMessage(autoTagSlideOutMessages.lastUpdated, {
            updated: new DateTime(new Date(autoTagConfig.updated)).formatNYearSMonthNDaySWeekSTime(),
          })}
        </Text>
      )}
      <Text>{autoTagConfig?.configType ? formatMessage(defaultAutoTagTierDescriptions[autoTagConfig.configType]) : ''}</Text>
      <Label primary={<Text fontWeight="bold">{formatMessage(autoTagSlideOutMessages.autoTagType)}</Text>}>
        <RadioGroup selected={autoTagConfig?.isGlobal ? 'global' : 'local'} choices={radioChoices} />
      </Label>
      <DividerLine color="borders" margin="none" />
      <Header type="h3">{formatMessage(autoTagSlideOutMessages.conditions)}</Header>
      {autoTagConfig?.configType && (
        <DefaultAutoTagTierEditor
          tierConfigs={autoTagConfig.tierConfigs}
          initialSelected={tagDisplayName}
          configType={autoTagConfig.configType}
        />
      )}
    </VStack>
  )
}
