import { useLocales } from '@sevenrooms/core/locales'
import { Box, DividerLine, HStack, SimpleSection } from '@sevenrooms/core/ui-kit/layout'
import { Link } from '@sevenrooms/core/ui-kit/typography'
import { paymentsDebuggerMessages } from '../../locales'
import { DisplayResultEntityProperties } from './DisplayResultEntityProperties'
import type { DebugData, Item } from './interfaces'

export function DisplayResultEntity(props: { item: Item; data: DebugData }) {
  const { item, data } = props
  const { formatMessage } = useLocales()

  const href =
    `https://console.cloud.google.com/logs/query;query="${data.venue.id}` +
    `"%0Aresource.type%3D"gae_app"%0Aresource.labels.module_id%3D"default"%0Atimestamp>="${item.timeMin}"` +
    ` AND timestamp<="${item.timeMax}"?project=${data.app.APP_ID}`

  const actions = (
    <HStack spacing="s">
      <Link
        isExternal
        target="_blank"
        to={href}
        rel="noreferrer"
        onClick={e => {
          e.stopPropagation()
        }}
      >
        <img
          alt={formatMessage(paymentsDebuggerMessages.googleLogsExplorer)}
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAABfElEQVR4Ae1UJUA1QRDmd6PgNFwLLh0puBe8k2DnJG3H3emdgrtLw50faLi7382zvWf53ZfGvpsds7J8uh/vCvPkhQ2Y510NEsAQBjAAQ4bC09FZAXyanvCaX2TLEAHZqvmlSEBETPUK5pRAMhTCqTNcIEGxGfWn1nAgha81/9C2vnwhvWRHQh2rfSHEi8uVMohnPjAe6/AMoTpmGIXOly8w/WbulnWF0YVlb4hGim6kGNNmz/poUSxEknvB/1NXUFXwIqHqUyP4wwNSZKmFV/yB3Q/lIv3OR8t/fwtEyFlALZZ5t+KPKv0cWc0VYfrlGgRlUqFtyRFS5DAIyBG1lQjKjCPAJ6C61rQnYBHR8GBqEbGNSNGj1sZy9TZCD/qMsgbpGUuZwBokLoHgIPEhDAfoQP41+lPbSn/CGto7zFgmrgRtF9RZ6ZwICuucSh3JGdoEEw8KrNOf0GLgoGAp0xgEiWIgPBl10lhHFXrUdTBk/FnHsYJ0/Wfd8r0CMtAKMeHAg5kAAAAASUVORK5CYII="
        />
      </Link>
    </HStack>
  )

  return (
    <Box mt="s">
      <SimpleSection title={item.type} actions={actions}>
        <DisplayResultEntityProperties title={formatMessage(paymentsDebuggerMessages.primary)} items={item?.shown} shown />
        <DividerLine />
        <DisplayResultEntityProperties title={formatMessage(paymentsDebuggerMessages.secondary)} items={item?.hidden} />
        <DividerLine />
        <DisplayResultEntityProperties title={formatMessage(paymentsDebuggerMessages.consts)} items={item?.consts} />
      </SimpleSection>
    </Box>
  )
}
