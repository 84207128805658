import { useCallback, useMemo, useState } from 'react'
import { AdminPageContent, AdminPageMeta } from '@sevenrooms/admin/components'
import { useGetBulkUpdateSettingsDataQuery, useBulkUpdateSettingsMutation } from '@sevenrooms/admin-lib-bulk-update-settings'
import { useForm, useWatchMany } from '@sevenrooms/core/form'
import { useLocales, commonMessages } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import {
  FormInput,
  FormSelect,
  Label,
  type RadioChoice,
  RadioGroup,
  TextArea,
  FormMultiSelect,
  LoaderButton,
} from '@sevenrooms/core/ui-kit/form'
import { Banner, BaseSection, HStack, Loader, NotificationContainer, VStack, notify } from '@sevenrooms/core/ui-kit/layout'
import { useBulkUpdateSettingsForm } from '../form'
import { adminBulkUpdateSettingsMessages } from '../locales'

export function AdminBulkUpdateSettings() {
  const { formatMessage } = useLocales()
  const [isLoading, setIsLoading] = useState(false)
  const [bulkSettingsUpdateMutation] = useBulkUpdateSettingsMutation()

  const { data: bulkUpdateSettingsData, isLoading: bulkUpdateSettingsIsLoading } = useGetBulkUpdateSettingsDataQuery(null)

  const venueSelectOptions: RadioChoice<string>[] = useMemo(
    () => [
      {
        value: 'URL_KEYS_OR_IDS',
        label: formatMessage(adminBulkUpdateSettingsMessages.venueSelectByUrlkeysLabel),
      },
      {
        value: 'PACKAGE',
        label: formatMessage(adminBulkUpdateSettingsMessages.venueSelectByPackageLabel),
      },
    ],
    [formatMessage]
  )

  const packageOptions: SelectOption<string>[] = useMemo(
    () => (bulkUpdateSettingsData?.packages as SelectOption[]) ?? [],
    [bulkUpdateSettingsData?.packages]
  )

  const stringSettingsOptions: SelectOption<string>[] = useMemo(
    () => (bulkUpdateSettingsData?.stringSettings as SelectOption[]) ?? [],
    [bulkUpdateSettingsData?.stringSettings]
  )

  const booleanSettingsOptions: SelectOption<string>[] = useMemo(
    () => (bulkUpdateSettingsData?.booleanSettings as SelectOption[]) ?? [],
    [bulkUpdateSettingsData?.booleanSettings]
  )

  const settingTypeOptions: SelectOption<string>[] = useMemo(
    () => [
      {
        id: 'BOOLEAN',
        label: formatMessage(adminBulkUpdateSettingsMessages.boolean),
      },
      {
        id: 'STRING',
        label: formatMessage(adminBulkUpdateSettingsMessages.string),
      },
    ],
    [formatMessage]
  )

  const booleanValueOptions: RadioChoice<string>[] = useMemo(
    () => [
      {
        value: 'ENABLED',
        label: formatMessage(commonMessages.enabled),
      },
      {
        value: 'DISABLED',
        label: formatMessage(commonMessages.disabled),
      },
    ],
    [formatMessage]
  )

  const schema = useBulkUpdateSettingsForm()
  const { field, setValue } = useForm(schema, {
    defaultValues: {
      settingType: settingTypeOptions[0]?.id,
      venueSelectType: venueSelectOptions[0]?.value,
      stringValue: '',
      booleanValue: true,
    },
    mode: 'onSubmit',
  })

  const [venueSelectType, venues, packages, settingName, settingType, booleanValue, stringValue] = useWatchMany(field, [
    'venueSelectType',
    'venues',
    'packages',
    'settingName',
    'settingType',
    'booleanValue',
    'stringValue',
  ])

  const bulkUpdate = useCallback(async () => {
    setIsLoading(true)
    const response = await bulkSettingsUpdateMutation({
      venueSelectType,
      venues: (venues ?? '').split('\n').filter(v => v),
      packages: packages ?? [],
      settingName,
      settingType,
      booleanValue,
      stringValue,
    })

    if ('data' in response && response.data === true) {
      notify({
        content: formatMessage(adminBulkUpdateSettingsMessages.successMessage),
        type: 'success',
        position: 'bottom-right',
        autoClose: false,
      })
    } else if ('error' in response) {
      notify({
        content: formatMessage(adminBulkUpdateSettingsMessages.errorMessage),
        type: 'error',
        position: 'bottom-right',
        autoClose: false,
      })
    }
    setIsLoading(false)
  }, [booleanValue, bulkSettingsUpdateMutation, formatMessage, packages, settingName, settingType, stringValue, venueSelectType, venues])

  return (
    <>
      <AdminPageMeta title={formatMessage(adminBulkUpdateSettingsMessages.title)} />
      <AdminPageContent title={formatMessage(adminBulkUpdateSettingsMessages.title)}>
        {bulkUpdateSettingsIsLoading ? (
          <Loader />
        ) : (
          <VStack spacing="m">
            <Banner
              title={formatMessage(adminBulkUpdateSettingsMessages.warningTitle)}
              description={formatMessage(adminBulkUpdateSettingsMessages.warningMessage)}
              type="warning"
            />
            <BaseSection title={formatMessage(adminBulkUpdateSettingsMessages.step1Title)}>
              <VStack spacing="m" p="m">
                <RadioGroup field={field.prop('venueSelectType')} choices={venueSelectOptions} />
                {venueSelectType === 'URL_KEYS_OR_IDS' && (
                  <Label
                    primary={formatMessage(adminBulkUpdateSettingsMessages.venueListLabel)}
                    secondary={formatMessage(adminBulkUpdateSettingsMessages.venueListSubLabel)}
                  >
                    <TextArea fullWidth rows={20} field={field.prop('venues')} />
                  </Label>
                )}
                {venueSelectType === 'PACKAGE' && (
                  <Label primary={formatMessage(adminBulkUpdateSettingsMessages.venuePackageLabel)}>
                    <FormMultiSelect options={packageOptions} field={field.prop('packages')} searchable />
                  </Label>
                )}
              </VStack>
            </BaseSection>
            <BaseSection title={formatMessage(adminBulkUpdateSettingsMessages.step2Title)}>
              <VStack spacing="m" p="m">
                <HStack spacing="m">
                  <Label
                    width="50%"
                    primary={formatMessage(adminBulkUpdateSettingsMessages.settingValueTypeLabel)}
                    info={<>{formatMessage(adminBulkUpdateSettingsMessages.settingValueTypeSublabel)}</>}
                  >
                    <FormSelect options={settingTypeOptions} field={field.prop('settingType')} />
                  </Label>
                  <Label
                    width="50%"
                    primary={formatMessage(adminBulkUpdateSettingsMessages.settingLabel)}
                    info={<>{formatMessage(adminBulkUpdateSettingsMessages.settingSubLabel)}</>}
                  >
                    <FormSelect
                      options={settingType === 'BOOLEAN' ? booleanSettingsOptions : stringSettingsOptions}
                      field={field.prop('settingName')}
                    />
                  </Label>
                </HStack>
                <HStack spacing="m">
                  <Label
                    width="100%"
                    primary={formatMessage(adminBulkUpdateSettingsMessages.settingValueLabel)}
                    info={<>{formatMessage(adminBulkUpdateSettingsMessages.settingValueSublabel)}</>}
                  >
                    {settingType === 'BOOLEAN' ? (
                      <RadioGroup
                        name=""
                        choices={booleanValueOptions}
                        selected={booleanValue ? 'ENABLED' : 'DISABLED'}
                        onChange={(option: RadioChoice<string>) => {
                          setValue('booleanValue', option.value === 'ENABLED')
                        }}
                      />
                    ) : (
                      <FormInput fullWidth field={field.prop('stringValue')} />
                    )}
                  </Label>
                </HStack>
              </VStack>
            </BaseSection>
            <BaseSection
              title={formatMessage(adminBulkUpdateSettingsMessages.step3Title)}
              description={formatMessage(adminBulkUpdateSettingsMessages.step3Description)}
            >
              <VStack spacing="m" p="m">
                <LoaderButton onClick={() => bulkUpdate()} loading={isLoading} data-test="sr-bulk-update-settings-save">
                  {formatMessage(adminBulkUpdateSettingsMessages.saveButton)}
                </LoaderButton>
              </VStack>
            </BaseSection>
            <NotificationContainer />
          </VStack>
        )}
      </AdminPageContent>
    </>
  )
}
