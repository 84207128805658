import { useCallback, useState } from 'react'
import { useRebuildSyncBlobsMutation } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { DateOnly } from '@sevenrooms/core/timepiece'
import { IconButton, LoaderButton } from '@sevenrooms/core/ui-kit/form'
import { HStack, VStack, notify, Tooltip } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { adminSyncBlobsMessages } from '../../locales'

interface AdminSyncBlobActionsProps {
  venueId: string
  selectedDate: Date
}

export function AdminSyncBlobActions({ venueId, selectedDate }: AdminSyncBlobActionsProps) {
  const { formatMessage } = useLocales()

  const [isRebuildingStatic, setIsRebuildingStatic] = useState(false)
  const [isRebuildingDay, setIsRebuildingDay] = useState(false)

  const [rebuildSyncBlobsMutation] = useRebuildSyncBlobsMutation()

  const rebuildSyncBlobs = useCallback(
    async (rebuildStatic: boolean, rebuildDay: boolean) => {
      if (rebuildStatic) {
        setIsRebuildingStatic(true)
      } else if (rebuildDay) {
        setIsRebuildingDay(true)
      }
      const message = await rebuildSyncBlobsMutation({
        venueId,
        rebuildStatic,
        rebuildDay,
        date: selectedDate,
      })
      if (rebuildStatic) {
        setIsRebuildingStatic(false)
      } else if (rebuildDay) {
        setIsRebuildingDay(false)
      }
      if ('data' in message && message.data === 'success') {
        const toastMessage = rebuildStatic
          ? formatMessage(adminSyncBlobsMessages.rebuildToastDescriptionStatic)
          : formatMessage(adminSyncBlobsMessages.rebuildToastDescriptionDay, { date: DateOnly.fromJsDateSafe(selectedDate)?.toIso() })
        notify({
          content: <ToastMessage message={toastMessage} />,
          type: 'success',
          position: 'bottom-right',
          autoClose: false,
          style: { width: '600px' },
        })
      } else if ('error' in message) {
        notify({ content: <ToastMessage message={message.error as string} />, type: 'error', position: 'bottom-right', autoClose: false })
      }
    },
    [rebuildSyncBlobsMutation, venueId, selectedDate, formatMessage]
  )

  return (
    <HStack spacing="s">
      <LoaderButton
        onClick={() => rebuildSyncBlobs(true, false)}
        disabled={isRebuildingStatic || isRebuildingDay}
        loading={isRebuildingStatic}
        data-test="rebuild-static-models-button"
      >
        {formatMessage(adminSyncBlobsMessages.rebuildStaticModels)}
      </LoaderButton>
      <LoaderButton
        onClick={() => rebuildSyncBlobs(false, true)}
        disabled={isRebuildingDay || isRebuildingStatic}
        loading={isRebuildingDay}
        data-test="rebuild-day-models-button"
      >
        {formatMessage(adminSyncBlobsMessages.rebuildDayModels)}
      </LoaderButton>
      <RebuildTooltip />
    </HStack>
  )
}

function RebuildTooltip() {
  const { formatMessage } = useLocales()
  return (
    <Tooltip
      content={
        <VStack spacing="s">
          <Text color="lightFont" fontWeight="bold">
            {formatMessage(adminSyncBlobsMessages.rebuildTooltipTitle)}
          </Text>
          <Text color="lightFont">{formatMessage(adminSyncBlobsMessages.rebuildStaticModelsTooltip)}</Text>
          <Text color="lightFont">{formatMessage(adminSyncBlobsMessages.rebuildDayModelsTooltip)}</Text>
        </VStack>
      }
    >
      <IconButton icon="VMSWeb-info" borderType="none-round" />
    </Tooltip>
  )
}

function ToastMessage({ message }: { message: string }) {
  const { formatMessage } = useLocales()

  return (
    <VStack spacing="s">
      <Text color="lightFont" fontWeight="bold">
        {formatMessage(adminSyncBlobsMessages.rebuildToastTitle)}
      </Text>
      <Text color="lightFont">{message}</Text>
    </VStack>
  )
}
