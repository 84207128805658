import { type CustomAutoTagValueObjectType, CustomAutoTagValueObjectTypeEnum } from '@sevenrooms/core/domain'
import { CustomAutoTagBooleanSelect } from './CustomAutoTagBooleanSelect'
import { CustomAutoTagCurrencyInput } from './CustomAutoTagCurrencyInput'
import { CustomAutoTagDateRangePicker } from './CustomAutoTagDateRangePicker'
import { CustomAutoTagGenderSelect } from './CustomAutoTagGenderSelect'
import { CustomAutoTagMultiSelect } from './CustomAutoTagMultiSelect'
import { CustomAutoTagNumberInput } from './CustomAutoTagNumberInput'
import { CustomAutoTagPercentageInput } from './CustomAutoTagPercentageInput'
import { CustomAutoTagStringInput } from './CustomAutoTagStringInput'
import { CustomAutoTagTimeRangePicker } from './CustomAutoTagTimeRangePicker'

export function CustomAutoTagValueObjectSwitch({
  path,
  valueObjectType,
  initialValue,
}: {
  path: (string | number)[]
  valueObjectType?: CustomAutoTagValueObjectType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValue?: any
}) {
  switch (valueObjectType) {
    /**
     * TODO
     * The tags multi-select component is still in development (VX-3312).
     * Once it is complete, we will need to use it for these two cases,
     * provided it has the appropriate form mechanisms.
     *
     * case CustomAutoTagValueObjectTypeEnum.ENUM_CLIENT_TAGS:
     * case CustomAutoTagValueObjectTypeEnum.ENUM_RESERVATION_TAGS:
     *   break
     */

    case CustomAutoTagValueObjectTypeEnum.ENUM_GENDER:
      return <CustomAutoTagGenderSelect path={path} selectedGender={initialValue} />
    case CustomAutoTagValueObjectTypeEnum.ENUM_VENUE_NAME:
    case CustomAutoTagValueObjectTypeEnum.ENUM_VENUE_SERVER:
    case CustomAutoTagValueObjectTypeEnum.ENUM_BOOKED_BY:
    case CustomAutoTagValueObjectTypeEnum.ENUM_FEEDBACK_RATING:
    case CustomAutoTagValueObjectTypeEnum.ENUM_MONTH:
    case CustomAutoTagValueObjectTypeEnum.ENUM_ORDER_METHOD:
    case CustomAutoTagValueObjectTypeEnum.ENUM_ORDER_STATUS:
    case CustomAutoTagValueObjectTypeEnum.ENUM_ORDER_SOURCE_SYSTEM:
    case CustomAutoTagValueObjectTypeEnum.ENUM_RESERVATION_STATUS:
    case CustomAutoTagValueObjectTypeEnum.ENUM_REVIEW_PLATFORM:
    case CustomAutoTagValueObjectTypeEnum.ENUM_REVIEW_RATING:
    case CustomAutoTagValueObjectTypeEnum.ENUM_SEATING_AREA:
    case CustomAutoTagValueObjectTypeEnum.ENUM_SHIFT_CATEGORY:
    case CustomAutoTagValueObjectTypeEnum.ENUM_WAITLIST_STATUS:
      return <CustomAutoTagMultiSelect path={path} valueObjectType={valueObjectType} />
    case CustomAutoTagValueObjectTypeEnum.DATE_DELTA:
    case CustomAutoTagValueObjectTypeEnum.DURATION:
    case CustomAutoTagValueObjectTypeEnum.NUMBER:
    case CustomAutoTagValueObjectTypeEnum.NTIMES:
      return <CustomAutoTagNumberInput path={path} initialValue={initialValue} valueObjectType={valueObjectType} />
    case CustomAutoTagValueObjectTypeEnum.STRING:
      return <CustomAutoTagStringInput path={path} initialValue={initialValue} />
    case CustomAutoTagValueObjectTypeEnum.PERCENTAGE:
      return <CustomAutoTagPercentageInput path={path} initialValue={initialValue} />
    case CustomAutoTagValueObjectTypeEnum.CURRENCY:
      return <CustomAutoTagCurrencyInput path={path} initialValue={initialValue} />
    case CustomAutoTagValueObjectTypeEnum.DATE_RANGE:
      return <CustomAutoTagDateRangePicker path={path} selectedDates={initialValue} />
    case CustomAutoTagValueObjectTypeEnum.BOOLEAN:
      return <CustomAutoTagBooleanSelect path={path} selectedBoolean={initialValue} />
    case CustomAutoTagValueObjectTypeEnum.TIME:
      return <CustomAutoTagTimeRangePicker path={path} selectedTimes={initialValue} />
    case CustomAutoTagValueObjectTypeEnum.ANY_DATE:
    default:
      return null
  }
}
