import type { CustomAutoTagOperatorType, CustomAutoTagValueObjectType } from '@sevenrooms/core/domain'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'

const customAutoTagObjects = {
  conditions: [
    {
      AGGREGATE_ORDER_AVG_SPEND_PER_ORDER: {
        condition_type: 'AGGREGATE_ORDER_AVG_SPEND_PER_ORDER',
        value_object_types: ['CURRENCY'],
        valid_chain_conditions: [],
        display_name: 'Aggregate Order: Avg. Spend/Order',
      },
    },
    {
      AGGREGATE_ORDER_COUNT: {
        condition_type: 'AGGREGATE_ORDER_COUNT',
        value_object_types: ['NTIMES'],
        valid_chain_conditions: [],
        display_name: 'Aggregate Order: Total Orders',
      },
    },
    {
      AGGREGATE_ORDER_TOTAL_SPEND: {
        condition_type: 'AGGREGATE_ORDER_TOTAL_SPEND',
        value_object_types: ['CURRENCY'],
        valid_chain_conditions: [],
        display_name: 'Aggregate Order: Total Spend',
      },
    },
    {
      FEEDBACK_ORDER_AMBIENCE_RATING: {
        condition_type: 'FEEDBACK_ORDER_AMBIENCE_RATING',
        value_object_types: ['ENUM_FEEDBACK_RATING'],
        valid_chain_conditions: ['FEEDBACK_ORDER_DATE'],
        display_name: 'Feedback: Order Ambience Rating',
      },
    },
    {
      FEEDBACK_ORDER_DATE: {
        condition_type: 'FEEDBACK_ORDER_DATE',
        value_object_types: ['ANY_DATE', 'DATE_DELTA', 'DATE_RANGE'],
        valid_chain_conditions: [],
        display_name: 'Feedback: Order Date',
      },
    },
    {
      FEEDBACK_ORDER_DETAIL: {
        condition_type: 'FEEDBACK_ORDER_DETAIL',
        value_object_types: ['STRING'],
        valid_chain_conditions: ['FEEDBACK_ORDER_DATE'],
        display_name: 'Feedback: Order Detail',
      },
    },
    {
      FEEDBACK_ORDER_FOOD_RATING: {
        condition_type: 'FEEDBACK_ORDER_FOOD_RATING',
        value_object_types: ['ENUM_FEEDBACK_RATING'],
        valid_chain_conditions: ['FEEDBACK_ORDER_DATE'],
        display_name: 'Feedback: Order Food Rating',
      },
    },
    {
      FEEDBACK_ORDER_OVERALL_RATING: {
        condition_type: 'FEEDBACK_ORDER_OVERALL_RATING',
        value_object_types: ['ENUM_FEEDBACK_RATING'],
        valid_chain_conditions: ['FEEDBACK_ORDER_DATE'],
        display_name: 'Feedback: Order Overall Rating',
      },
    },
    {
      FEEDBACK_ORDER_RECOMMENDATION: {
        condition_type: 'FEEDBACK_ORDER_RECOMMENDATION',
        value_object_types: ['BOOLEAN'],
        valid_chain_conditions: ['FEEDBACK_ORDER_DATE'],
        display_name: 'Feedback: Order Recommendation',
      },
    },
    {
      FEEDBACK_ORDER_SERVICE_RATING: {
        condition_type: 'FEEDBACK_ORDER_SERVICE_RATING',
        value_object_types: ['ENUM_FEEDBACK_RATING'],
        valid_chain_conditions: ['FEEDBACK_ORDER_DATE'],
        display_name: 'Feedback: Order Service Rating',
      },
    },
    {
      FEEDBACK_VISIT_AMBIENCE_RATING: {
        condition_type: 'FEEDBACK_VISIT_AMBIENCE_RATING',
        value_object_types: ['ENUM_FEEDBACK_RATING'],
        valid_chain_conditions: ['FEEDBACK_VISIT_DATE'],
        display_name: 'Feedback: Visit Ambience Rating',
      },
    },
    {
      FEEDBACK_VISIT_DATE: {
        condition_type: 'FEEDBACK_VISIT_DATE',
        value_object_types: ['ANY_DATE', 'DATE_DELTA', 'DATE_RANGE'],
        valid_chain_conditions: [],
        display_name: 'Feedback: Visit Date',
      },
    },
    {
      FEEDBACK_VISIT_DETAIL: {
        condition_type: 'FEEDBACK_VISIT_DETAIL',
        value_object_types: ['STRING'],
        valid_chain_conditions: ['FEEDBACK_VISIT_DATE'],
        display_name: 'Feedback: Visit Detail',
      },
    },
    {
      FEEDBACK_VISIT_FOOD_RATING: {
        condition_type: 'FEEDBACK_VISIT_FOOD_RATING',
        value_object_types: ['ENUM_FEEDBACK_RATING'],
        valid_chain_conditions: ['FEEDBACK_VISIT_DATE'],
        display_name: 'Feedback: Visit Food Rating',
      },
    },
    {
      FEEDBACK_VISIT_OVERALL_RATING: {
        condition_type: 'FEEDBACK_VISIT_OVERALL_RATING',
        value_object_types: ['ENUM_FEEDBACK_RATING'],
        valid_chain_conditions: ['FEEDBACK_VISIT_DATE'],
        display_name: 'Feedback: Visit Overall Rating',
      },
    },
    {
      FEEDBACK_VISIT_RECOMMENDATION: {
        condition_type: 'FEEDBACK_VISIT_RECOMMENDATION',
        value_object_types: ['BOOLEAN'],
        valid_chain_conditions: ['FEEDBACK_VISIT_DATE'],
        display_name: 'Feedback: Visit Recommendation',
      },
    },
    {
      FEEDBACK_VISIT_SERVICE_RATING: {
        condition_type: 'FEEDBACK_VISIT_SERVICE_RATING',
        value_object_types: ['ENUM_FEEDBACK_RATING'],
        valid_chain_conditions: ['FEEDBACK_VISIT_DATE'],
        display_name: 'Feedback: Visit Service Rating',
      },
    },
    {
      ORDER_CITY: {
        condition_type: 'ORDER_CITY',
        value_object_types: ['STRING'],
        valid_chain_conditions: [],
        display_name: 'Order: City',
      },
    },
    {
      ORDER_CLOSE_DATE: {
        condition_type: 'ORDER_CLOSE_DATE',
        value_object_types: ['DATE_DELTA', 'DATE_RANGE'],
        valid_chain_conditions: ['ORDER_COUNT'],
        display_name: 'Order: Closed Date',
      },
    },
    {
      ORDER_CLOSE_TIME: {
        condition_type: 'ORDER_CLOSE_TIME',
        value_object_types: ['TIME'],
        valid_chain_conditions: ['ORDER_OPEN_DATE'],
        display_name: 'Order: Closed Time',
      },
    },
    {
      ORDER_COUNT: {
        condition_type: 'ORDER_COUNT',
        value_object_types: ['NTIMES'],
        valid_chain_conditions: [],
        display_name: 'Order: Count',
      },
    },
    {
      ORDER_OPEN_DATE: {
        condition_type: 'ORDER_OPEN_DATE',
        value_object_types: ['DATE_DELTA', 'DATE_RANGE'],
        valid_chain_conditions: ['ORDER_COUNT'],
        display_name: 'Order: Creation Date',
      },
    },
    {
      ORDER_OPEN_TIME: {
        condition_type: 'ORDER_OPEN_TIME',
        value_object_types: ['TIME'],
        valid_chain_conditions: ['ORDER_OPEN_DATE'],
        display_name: 'Order: Creation Time',
      },
    },
    {
      ORDER_GRATUITY: {
        condition_type: 'ORDER_GRATUITY',
        value_object_types: ['PERCENTAGE'],
        valid_chain_conditions: ['ORDER_OPEN_DATE'],
        display_name: 'Order: Gratuity %',
      },
    },
    {
      ORDER_ITEM_NAME: {
        condition_type: 'ORDER_ITEM_NAME',
        value_object_types: ['STRING'],
        valid_chain_conditions: ['ORDER_OPEN_DATE', 'ORDER_COUNT'],
        display_name: 'Order: Item Name',
      },
    },
    {
      ORDER_POSTAL_CODE: {
        condition_type: 'ORDER_POSTAL_CODE',
        value_object_types: ['STRING'],
        valid_chain_conditions: [],
        display_name: 'Order: Postal Code',
      },
    },
    {
      ORDER_PROMOTION_CODE: {
        condition_type: 'ORDER_PROMOTION_CODE',
        value_object_types: ['STRING'],
        valid_chain_conditions: ['ORDER_OPEN_DATE'],
        display_name: 'Order: Promotion Code',
      },
    },
    {
      ORDER_PROMOTION_NAME: {
        condition_type: 'ORDER_PROMOTION_NAME',
        value_object_types: ['STRING'],
        valid_chain_conditions: ['ORDER_OPEN_DATE'],
        display_name: 'Order: Promotion Name',
      },
    },
    {
      ORDER_SOURCE_SYSTEM: {
        condition_type: 'ORDER_SOURCE_SYSTEM',
        value_object_types: ['ENUM_ORDER_SOURCE_SYSTEM'],
        valid_chain_conditions: ['ORDER_OPEN_DATE'],
        display_name: 'Order: Source',
      },
    },
    {
      ORDER_STATE: {
        condition_type: 'ORDER_STATE',
        value_object_types: ['STRING'],
        valid_chain_conditions: [],
        display_name: 'Order: State',
      },
    },
    {
      ORDER_STATUS: {
        condition_type: 'ORDER_STATUS',
        value_object_types: ['ENUM_ORDER_STATUS'],
        valid_chain_conditions: ['ORDER_OPEN_DATE'],
        display_name: 'Order: Status',
      },
    },
    {
      ORDER_TOTAL_SPEND: {
        condition_type: 'ORDER_TOTAL_SPEND',
        value_object_types: ['CURRENCY'],
        valid_chain_conditions: ['ORDER_OPEN_DATE'],
        display_name: 'Order: Total Spend',
      },
    },
    {
      ORDER_METHOD: {
        condition_type: 'ORDER_METHOD',
        value_object_types: ['ENUM_ORDER_METHOD'],
        valid_chain_conditions: ['ORDER_OPEN_DATE', 'ORDER_COUNT'],
        display_name: 'Order: Type',
      },
    },
    {
      OUTGOING_EMAIL_BOUNCED: {
        condition_type: 'OUTGOING_EMAIL_BOUNCED',
        value_object_types: ['ANY_DATE', 'DATE_DELTA', 'DATE_RANGE'],
        valid_chain_conditions: [],
        display_name: 'OutgoingEmail: Bounced',
      },
    },
    {
      OUTGOING_EMAIL_CAMPAIGN_NAME: {
        condition_type: 'OUTGOING_EMAIL_CAMPAIGN_NAME',
        value_object_types: ['STRING'],
        valid_chain_conditions: [],
        display_name: 'OutgoingEmail: Campaign Name',
      },
    },
    {
      OUTGOING_EMAIL_CLICKED: {
        condition_type: 'OUTGOING_EMAIL_CLICKED',
        value_object_types: ['ANY_DATE', 'DATE_DELTA', 'DATE_RANGE'],
        valid_chain_conditions: ['OUTGOING_EMAIL_COUNT'],
        display_name: 'OutgoingEmail: Clicked',
      },
    },
    {
      OUTGOING_EMAIL_COUNT: {
        condition_type: 'OUTGOING_EMAIL_COUNT',
        value_object_types: ['NTIMES'],
        valid_chain_conditions: [],
        display_name: 'OutgoingEmail: Count',
      },
    },
    {
      OUTGOING_EMAIL_DELIVERED: {
        condition_type: 'OUTGOING_EMAIL_DELIVERED',
        value_object_types: ['ANY_DATE', 'DATE_DELTA', 'DATE_RANGE'],
        valid_chain_conditions: [],
        display_name: 'OutgoingEmail: Delivered',
      },
    },
    {
      OUTGOING_EMAIL_OPENED: {
        condition_type: 'OUTGOING_EMAIL_OPENED',
        value_object_types: ['ANY_DATE', 'DATE_DELTA', 'DATE_RANGE'],
        valid_chain_conditions: ['OUTGOING_EMAIL_COUNT'],
        display_name: 'OutgoingEmail: Opened',
      },
    },
    {
      OUTGOING_EMAIL_SENT: {
        condition_type: 'OUTGOING_EMAIL_SENT',
        value_object_types: ['ANY_DATE', 'DATE_DELTA', 'DATE_RANGE'],
        valid_chain_conditions: [],
        display_name: 'OutgoingEmail: Sent',
      },
    },
    {
      OUTGOING_EMAIL_SPAM: {
        condition_type: 'OUTGOING_EMAIL_SPAM',
        value_object_types: ['ANY_DATE', 'DATE_DELTA', 'DATE_RANGE'],
        valid_chain_conditions: [],
        display_name: 'OutgoingEmail: Spam',
      },
    },
    {
      OUTGOING_EMAIL_UNSUBSCRIBED: {
        condition_type: 'OUTGOING_EMAIL_UNSUBSCRIBED',
        value_object_types: ['ANY_DATE', 'DATE_DELTA', 'DATE_RANGE'],
        valid_chain_conditions: [],
        display_name: 'OutgoingEmail: Unsubscribed',
      },
    },
    {
      CLIENT_ANNIVERSARY_MONTH: {
        condition_type: 'CLIENT_ANNIVERSARY_MONTH',
        value_object_types: ['ENUM_MONTH'],
        valid_chain_conditions: [],
        display_name: 'Profile: Anniversary Month',
      },
    },
    {
      CLIENT_BIRTHDAY_MONTH: {
        condition_type: 'CLIENT_BIRTHDAY_MONTH',
        value_object_types: ['ENUM_MONTH'],
        valid_chain_conditions: [],
        display_name: 'Profile: Birthday Month',
      },
    },
    {
      CLIENT_CITY: {
        condition_type: 'CLIENT_CITY',
        value_object_types: ['STRING'],
        valid_chain_conditions: [],
        display_name: 'Profile: City',
      },
    },
    {
      CLIENT_COMPANY: {
        condition_type: 'CLIENT_COMPANY',
        value_object_types: ['STRING'],
        valid_chain_conditions: [],
        display_name: 'Profile: Company',
      },
    },
    {
      CLIENT_COUNTRY: {
        condition_type: 'CLIENT_COUNTRY',
        value_object_types: ['STRING'],
        valid_chain_conditions: [],
        display_name: 'Profile: Country',
      },
    },
    {
      CLIENT_CREATED_DATE: {
        condition_type: 'CLIENT_CREATED_DATE',
        value_object_types: ['DATE_DELTA', 'DATE_RANGE'],
        valid_chain_conditions: [],
        display_name: 'Profile: Created Date',
      },
    },
    {
      CLIENT_EMAIL_ADDRESS: {
        condition_type: 'CLIENT_EMAIL_ADDRESS',
        value_object_types: ['STRING'],
        valid_chain_conditions: [],
        display_name: 'Profile: Email Address',
      },
    },
    {
      CLIENT_GENDER: {
        condition_type: 'CLIENT_GENDER',
        value_object_types: ['ENUM_GENDER'],
        valid_chain_conditions: [],
        display_name: 'Profile: Gender',
      },
    },
    {
      CLIENT_JOB_TITLE: {
        condition_type: 'CLIENT_JOB_TITLE',
        value_object_types: ['STRING'],
        valid_chain_conditions: [],
        display_name: 'Profile: Job Title',
      },
    },
    {
      CLIENT_LAST_ORDER_DATE: {
        condition_type: 'CLIENT_LAST_ORDER_DATE',
        value_object_types: ['DATE_DELTA', 'DATE_RANGE'],
        valid_chain_conditions: [],
        display_name: 'Profile: Last Order Date',
      },
    },
    {
      CLIENT_LAST_RESERVATION_DATE: {
        condition_type: 'CLIENT_LAST_RESERVATION_DATE',
        value_object_types: ['DATE_DELTA', 'DATE_RANGE'],
        valid_chain_conditions: [],
        display_name: 'Profile: Last Reservation Date',
      },
    },
    {
      CLIENT_LOYALTY_RANK: {
        condition_type: 'CLIENT_LOYALTY_RANK',
        value_object_types: ['STRING'],
        valid_chain_conditions: [],
        display_name: 'Profile: Loyalty Rank',
      },
    },
    {
      CLIENT_LOYALTY_TIER: {
        condition_type: 'CLIENT_LOYALTY_TIER',
        value_object_types: ['STRING'],
        valid_chain_conditions: [],
        display_name: 'Profile: Loyalty Tier',
      },
    },
    {
      CLIENT_POSTAL_CODE: {
        condition_type: 'CLIENT_POSTAL_CODE',
        value_object_types: ['STRING'],
        valid_chain_conditions: [],
        display_name: 'Profile: Postal Code',
      },
    },
    {
      CLIENT_STATE: {
        condition_type: 'CLIENT_STATE',
        value_object_types: ['STRING'],
        valid_chain_conditions: [],
        display_name: 'Profile: State',
      },
    },
    {
      CLIENT_TAGS: {
        condition_type: 'CLIENT_TAGS',
        value_object_types: ['ENUM_CLIENT_TAGS'],
        valid_chain_conditions: [],
        display_name: 'Profile: Tags',
      },
    },
    {
      RESERVATION_BOOKED_ENHANCED: {
        condition_type: 'RESERVATION_BOOKED_ENHANCED',
        value_object_types: ['ANY_DATE', 'DATE_DELTA', 'DATE_RANGE'],
        valid_chain_conditions: [
          'VISIT_VENUE_NAME',
          'RESERVATION_CREATED_DATE',
          'RESERVATION_PARTY_SIZE',
          'RESERVATION_STATUS',
          'RESERVATION_TAGS',
          'VISIT_GRATUITY',
          'VISIT_SEATING_AREA',
          'VISIT_SERVED_BY',
          'VISIT_SHIFT_CATEGORY',
          'RESERVATION_BOOKED_BY',
          'RESERVATION_COUNT',
        ],
        display_name: 'Reservation: Booked Reservation Count (Enhanced)',
      },
    },
    {
      RESERVATION_COMPLETED_VISIT: {
        condition_type: 'RESERVATION_COMPLETED_VISIT',
        value_object_types: ['ANY_DATE', 'DATE_DELTA', 'DATE_RANGE'],
        valid_chain_conditions: ['RESERVATION_COMPLETED_COUNT'],
        display_name: 'Reservation: Completed Visit Count',
      },
    },
    {
      RESERVATION_COMPLETED_VISIT_ENHANCED: {
        condition_type: 'RESERVATION_COMPLETED_VISIT_ENHANCED',
        value_object_types: ['ANY_DATE', 'DATE_DELTA', 'DATE_RANGE'],
        valid_chain_conditions: [
          'VISIT_VENUE_NAME',
          'RESERVATION_CREATED_DATE',
          'RESERVATION_PARTY_SIZE',
          'RESERVATION_STATUS',
          'RESERVATION_TAGS',
          'VISIT_GRATUITY',
          'VISIT_SEATING_AREA',
          'VISIT_SERVED_BY',
          'VISIT_SHIFT_CATEGORY',
          'RESERVATION_BOOKED_BY',
          'RESERVATION_COMPLETED_COUNT',
        ],
        display_name: 'Reservation: Completed Visit Count (Enhanced)',
      },
    },
    {
      RESERVATION_COUNT: {
        condition_type: 'RESERVATION_COUNT',
        value_object_types: ['NTIMES'],
        valid_chain_conditions: [],
        display_name: 'Reservation: Count',
      },
    },
    {
      RESERVATION_CREATED_DATE: {
        condition_type: 'RESERVATION_CREATED_DATE',
        value_object_types: ['ANY_DATE', 'DATE_DELTA', 'DATE_RANGE'],
        valid_chain_conditions: [],
        display_name: 'Reservation: Created Date',
      },
    },
    {
      RESERVATION_DATE: {
        condition_type: 'RESERVATION_DATE',
        value_object_types: ['ANY_DATE', 'DATE_DELTA', 'DATE_RANGE'],
        valid_chain_conditions: ['RESERVATION_COUNT'],
        display_name: 'Reservation: Date',
      },
    },
    {
      RESERVATION_PARTY_SIZE: {
        condition_type: 'RESERVATION_PARTY_SIZE',
        value_object_types: ['IS_ANYTHING', 'NUMBER'],
        valid_chain_conditions: ['RESERVATION_DATE'],
        display_name: 'Reservation: Party Size',
      },
    },
    {
      RESERVATION_STATUS: {
        condition_type: 'RESERVATION_STATUS',
        value_object_types: ['IS_ANYTHING', 'ENUM_RESERVATION_STATUS'],
        valid_chain_conditions: ['RESERVATION_DATE'],
        display_name: 'Reservation: Status',
      },
    },
    {
      RESERVATION_TAGS: {
        condition_type: 'RESERVATION_TAGS',
        value_object_types: ['IS_ANYTHING', 'ENUM_RESERVATION_TAGS'],
        valid_chain_conditions: ['RESERVATION_DATE'],
        display_name: 'Reservation: Tag',
      },
    },
    {
      RESERVATION_TIME: {
        condition_type: 'RESERVATION_TIME',
        value_object_types: ['TIME'],
        valid_chain_conditions: ['RESERVATION_DATE'],
        display_name: 'Reservation: Time',
      },
    },
    {
      REVIEW_DATE: {
        condition_type: 'REVIEW_DATE',
        value_object_types: ['ANY_DATE', 'DATE_DELTA', 'DATE_RANGE'],
        valid_chain_conditions: [],
        display_name: 'Review: Date',
      },
    },
    {
      REVIEW_DETAIL: {
        condition_type: 'REVIEW_DETAIL',
        value_object_types: ['STRING'],
        valid_chain_conditions: ['REVIEW_DATE'],
        display_name: 'Review: Detail',
      },
    },
    {
      REVIEW_PLATFORM: {
        condition_type: 'REVIEW_PLATFORM',
        value_object_types: ['ENUM_REVIEW_PLATFORM'],
        valid_chain_conditions: ['REVIEW_DATE'],
        display_name: 'Review: Platform',
      },
    },
    {
      REVIEW_RATING: {
        condition_type: 'REVIEW_RATING',
        value_object_types: ['ENUM_REVIEW_RATING'],
        valid_chain_conditions: ['REVIEW_DATE'],
        display_name: 'Review: Rating',
      },
    },
    {
      VISIT_HISTORY_AVG_SPEND_PER_COVER: {
        condition_type: 'VISIT_HISTORY_AVG_SPEND_PER_COVER',
        value_object_types: ['CURRENCY'],
        valid_chain_conditions: [],
        display_name: 'Visit History: Avg. Spend/Cover',
      },
    },
    {
      VISIT_HISTORY_TOTAL_CANCELS: {
        condition_type: 'VISIT_HISTORY_TOTAL_CANCELS',
        value_object_types: ['NUMBER'],
        valid_chain_conditions: [],
        display_name: 'Visit History: Total Cancels',
      },
    },
    {
      VISIT_HISTORY_TOTAL_NO_SHOWS: {
        condition_type: 'VISIT_HISTORY_TOTAL_NO_SHOWS',
        value_object_types: ['NUMBER'],
        valid_chain_conditions: [],
        display_name: 'Visit History: Total No Shows',
      },
    },
    {
      VISIT_HISTORY_TOTAL_SPEND: {
        condition_type: 'VISIT_HISTORY_TOTAL_SPEND',
        value_object_types: ['CURRENCY'],
        valid_chain_conditions: [],
        display_name: 'Visit History: Total Spend',
      },
    },
    {
      VISIT_HISTORY_TOTAL_SPEND_PER_VISIT: {
        condition_type: 'VISIT_HISTORY_TOTAL_SPEND_PER_VISIT',
        value_object_types: ['CURRENCY'],
        valid_chain_conditions: [],
        display_name: 'Visit History: Total Spend/Visit',
      },
    },
    {
      VISIT_HISTORY_TOTAL_VISITS: {
        condition_type: 'VISIT_HISTORY_TOTAL_VISITS',
        value_object_types: ['NUMBER'],
        valid_chain_conditions: [],
        display_name: 'Visit History: Total Visits',
      },
    },
    {
      VISIT_GRATUITY: {
        condition_type: 'VISIT_GRATUITY',
        value_object_types: ['IS_ANYTHING', 'PERCENTAGE'],
        valid_chain_conditions: ['RESERVATION_DATE'],
        display_name: 'Visit: Gratuity %',
      },
    },
    {
      VISIT_POS_ITEM_NAME: {
        condition_type: 'VISIT_POS_ITEM_NAME',
        value_object_types: ['STRING'],
        valid_chain_conditions: ['RESERVATION_DATE', 'RESERVATION_COUNT'],
        display_name: 'Visit: POS Item Name',
      },
    },
    {
      VISIT_POS_ITEM_PRICE: {
        condition_type: 'VISIT_POS_ITEM_PRICE',
        value_object_types: ['CURRENCY'],
        valid_chain_conditions: ['RESERVATION_DATE', 'RESERVATION_COUNT'],
        display_name: 'Visit: POS Item Price',
      },
    },
    {
      VISIT_SEATING_AREA: {
        condition_type: 'VISIT_SEATING_AREA',
        value_object_types: ['IS_ANYTHING', 'ENUM_SEATING_AREA'],
        valid_chain_conditions: ['RESERVATION_DATE'],
        display_name: 'Visit: Seating Area',
      },
    },
    {
      VISIT_SHIFT_CATEGORY: {
        condition_type: 'VISIT_SHIFT_CATEGORY',
        value_object_types: ['IS_ANYTHING', 'ENUM_SHIFT_CATEGORY'],
        valid_chain_conditions: ['RESERVATION_DATE'],
        display_name: 'Visit: Shift Category',
      },
    },
    {
      VISIT_TOTAL_SPEND: {
        condition_type: 'VISIT_TOTAL_SPEND',
        value_object_types: ['CURRENCY'],
        valid_chain_conditions: ['RESERVATION_DATE'],
        display_name: 'Visit: Total Spend',
      },
    },
    {
      WAITLIST_DATE_JOINED: {
        condition_type: 'WAITLIST_DATE_JOINED',
        value_object_types: ['ANY_DATE', 'DATE_DELTA', 'DATE_RANGE'],
        valid_chain_conditions: [],
        display_name: 'Waitlist: Date Joined',
      },
    },
    {
      WAITLIST_PARTY_SIZE: {
        condition_type: 'WAITLIST_PARTY_SIZE',
        value_object_types: ['NUMBER'],
        valid_chain_conditions: ['WAITLIST_DATE_JOINED'],
        display_name: 'Waitlist: Party Size',
      },
    },
    {
      WAITLIST_QUOTED_WAIT_DURATION: {
        condition_type: 'WAITLIST_QUOTED_WAIT_DURATION',
        value_object_types: ['DURATION'],
        valid_chain_conditions: ['WAITLIST_DATE_JOINED'],
        display_name: 'Waitlist: Quoted Wait Duration',
      },
    },
    {
      WAITLIST_START_TIME: {
        condition_type: 'WAITLIST_START_TIME',
        value_object_types: ['TIME'],
        valid_chain_conditions: ['WAITLIST_DATE_JOINED'],
        display_name: 'Waitlist: Start Time',
      },
    },
    {
      WAITLIST_STATUS: {
        condition_type: 'WAITLIST_STATUS',
        value_object_types: ['ENUM_WAITLIST_STATUS'],
        valid_chain_conditions: ['WAITLIST_DATE_JOINED'],
        display_name: 'Waitlist: Status',
      },
    },
    {
      WAITLIST_WAIT_DURATION: {
        condition_type: 'WAITLIST_WAIT_DURATION',
        value_object_types: ['DURATION'],
        valid_chain_conditions: ['WAITLIST_DATE_JOINED'],
        display_name: 'Waitlist: Wait Duration',
      },
    },
  ],
  value_objects: [
    {
      ANY_DATE: {
        value_object_type: 'ANY_DATE',
        operator_types: ['EVER'],
      },
    },
    {
      IS_ANYTHING: {
        value_object_type: 'IS_ANYTHING',
        operator_types: ['IS_ANYTHING'],
      },
    },
    {
      BOOLEAN: {
        value_object_type: 'BOOLEAN',
        operator_types: ['EQUALS', 'NOT_EQUALS'],
      },
    },
    {
      CURRENCY: {
        value_object_type: 'CURRENCY',
        operator_types: ['EQUALS', 'LESS_THAN', 'LESS_THAN_OR_EQUAL', 'GREATER_THAN', 'GREATER_THAN_OR_EQUAL'],
      },
    },
    {
      DATE_DELTA: {
        value_object_type: 'DATE_DELTA',
        operator_types: ['WITHIN_THE_PAST', 'WITHIN_THE_NEXT'],
      },
    },
    {
      DATE_RANGE: {
        value_object_type: 'DATE_RANGE',
        operator_types: ['BETWEEN_DATE'],
      },
    },
    {
      DURATION: {
        value_object_type: 'DURATION',
        operator_types: ['EQUALS', 'LESS_THAN', 'LESS_THAN_OR_EQUAL', 'GREATER_THAN', 'GREATER_THAN_OR_EQUAL'],
      },
    },
    {
      ENUM_CLIENT_TAGS: {
        value_object_type: 'ENUM_CLIENT_TAGS',
        is_multi_select: true,
        operator_types: ['INCLUDES_ANY', 'INCLUDES_ALL', 'EXCLUDES_ANY', 'EXCLUDES_ALL'],
        has_static_options: false,
      },
    },
    {
      ENUM_FEEDBACK_RATING: {
        value_object_type: 'ENUM_FEEDBACK_RATING',
        is_multi_select: true,
        options: [
          {
            '1': 1,
          },
          {
            '2': 2,
          },
          {
            '3': 3,
          },
          {
            '4': 4,
          },
          {
            '5': 5,
          },
        ],
        operator_types: ['INCLUDES_ANY', 'EXCLUDES_ALL'],
        has_static_options: true,
      },
    },
    {
      ENUM_GENDER: {
        value_object_type: 'ENUM_GENDER',
        is_multi_select: false,
        options: [
          {
            FEMALE: 'Female',
          },
          {
            MALE: 'Male',
          },
        ],
        operator_types: ['EQUALS', 'NOT_EQUALS'],
        has_static_options: true,
      },
    },
    {
      ENUM_MONTH: {
        value_object_type: 'ENUM_MONTH',
        is_multi_select: true,
        options: [
          {
            JANUARY: 'January',
          },
          {
            FEBRUARY: 'February',
          },
          {
            MARCH: 'March',
          },
          {
            APRIL: 'April',
          },
          {
            MAY: 'May',
          },
          {
            JUNE: 'June',
          },
          {
            JULY: 'July',
          },
          {
            AUGUST: 'August',
          },
          {
            SEPTEMBER: 'September',
          },
          {
            OCTOBER: 'October',
          },
          {
            NOVEMBER: 'November',
          },
          {
            DECEMBER: 'December',
          },
        ],
        operator_types: ['INCLUDES_ANY', 'EXCLUDES_ALL'],
        has_static_options: true,
      },
    },
    {
      ENUM_ORDER_METHOD: {
        value_object_type: 'ENUM_ORDER_METHOD',
        is_multi_select: true,
        options: [
          {
            DELIVERY: 'Delivery',
          },
          {
            ON_PREMISE: 'On Premise',
          },
          {
            PICKUP: 'Pickup',
          },
          {
            PRE_ORDER: 'Pre Order',
          },
          {
            ROOM_SERVICE: 'Room Service',
          },
        ],
        operator_types: ['INCLUDES_ANY', 'EXCLUDES_ALL'],
        has_static_options: true,
      },
    },
    {
      ENUM_ORDER_SOURCE_SYSTEM: {
        value_object_type: 'ENUM_ORDER_SOURCE_SYSTEM',
        is_multi_select: true,
        options: [
          {
            BBOT: 'Bbot',
          },
          {
            OLO: 'Olo',
          },
          {
            SEVENROOMS: 'SevenRooms',
          },
        ],
        operator_types: ['INCLUDES_ANY', 'EXCLUDES_ALL'],
        has_static_options: true,
      },
    },
    {
      ENUM_ORDER_STATUS: {
        value_object_type: 'ENUM_ORDER_STATUS',
        is_multi_select: true,
        options: [
          {
            CLOSED: 'Closed',
          },
          {
            FAILED_INJECTION: 'Failed Injection',
          },
          {
            OPEN: 'Open',
          },
          {
            QUEUED: 'Queued',
          },
          {
            READY_DRIVER: 'Ready Driver',
          },
          {
            READY_PICKUP: 'Ready Pickup',
          },
          {
            VOID: 'Void',
          },
          {
            WITH_DRIVER: 'With Driver',
          },
          {
            WORKING_ON_ORDER: 'Working On Order',
          },
        ],
        operator_types: ['INCLUDES_ANY', 'EXCLUDES_ALL'],
        has_static_options: true,
      },
    },
    {
      ENUM_RESERVATION_STATUS: {
        value_object_type: 'ENUM_RESERVATION_STATUS',
        is_multi_select: true,
        operator_types: ['INCLUDES_ANY', 'EXCLUDES_ALL'],
        has_static_options: false,
      },
    },
    {
      ENUM_RESERVATION_TAGS: {
        value_object_type: 'ENUM_RESERVATION_TAGS',
        is_multi_select: true,
        operator_types: ['INCLUDES_ANY', 'INCLUDES_ALL', 'EXCLUDES_ANY', 'EXCLUDES_ALL'],
        has_static_options: false,
      },
    },
    {
      ENUM_REVIEW_PLATFORM: {
        value_object_type: 'ENUM_REVIEW_PLATFORM',
        is_multi_select: true,
        options: [
          {
            google: 'Google',
          },
          {
            yelp: 'Yelp',
          },
          {
            sevenrooms: 'SevenRooms',
          },
          {
            grubhub: 'Grubhub/Seamless',
          },
          {
            bookatable: 'Bookatable',
          },
          {
            foursquare: 'Foursquare',
          },
          {
            opentable: 'OpenTable (International)',
          },
          {
            tripadvisor: 'TripAdvisor',
          },
          {
            openrice: 'OpenRice',
          },
          {
            opentableusa: 'OpenTable USA',
          },
          {
            facebook: 'Facebook',
          },
        ],
        operator_types: ['INCLUDES_ANY', 'EXCLUDES_ALL'],
        has_static_options: true,
      },
    },
    {
      ENUM_REVIEW_RATING: {
        value_object_type: 'ENUM_REVIEW_RATING',
        is_multi_select: true,
        options: [
          {
            '1': 1,
          },
          {
            '2': 2,
          },
          {
            '3': 3,
          },
          {
            '4': 4,
          },
          {
            '5': 5,
          },
        ],
        operator_types: ['INCLUDES_ANY', 'EXCLUDES_ALL'],
        has_static_options: true,
      },
    },
    {
      ENUM_SEATING_AREA: {
        value_object_type: 'ENUM_SEATING_AREA',
        is_multi_select: true,
        operator_types: ['INCLUDES_ANY', 'EXCLUDES_ALL'],
        has_static_options: false,
      },
    },
    {
      ENUM_SHIFT_CATEGORY: {
        value_object_type: 'ENUM_SHIFT_CATEGORY',
        is_multi_select: true,
        options: [
          {
            BREAKFAST: 'Breakfast',
          },
          {
            BRUNCH: 'Brunch',
          },
          {
            LUNCH: 'Lunch',
          },
          {
            DAY: 'Day',
          },
          {
            DINNER: 'Dinner',
          },
          {
            LEGACY: 'Night',
          },
        ],
        operator_types: ['INCLUDES_ANY', 'EXCLUDES_ALL'],
        has_static_options: true,
      },
    },
    {
      ENUM_WAITLIST_STATUS: {
        value_object_type: 'ENUM_WAITLIST_STATUS',
        is_multi_select: true,
        options: [
          {
            GUEST_CANCELED: 'Canceled',
          },
          {
            GUEST_CONFIRMED: 'Confirmed',
          },
          {
            JOINED_ONLINE: 'Joined Online',
          },
          {
            NO_SHOW: 'No Show',
          },
          {
            REMOVED: 'Removed',
          },
          {
            SEATED: 'Seated',
          },
          {
            TABLE_READY: 'Table Ready',
          },
          {
            WAITING: 'Waiting',
          },
          {
            WAITING_CONFIRMATION: 'Awaiting Confirmation',
          },
        ],
        operator_types: ['INCLUDES_ANY', 'EXCLUDES_ALL'],
        has_static_options: true,
      },
    },
    {
      NTIMES: {
        value_object_type: 'NTIMES',
        operator_types: ['EQUALS', 'LESS_THAN', 'LESS_THAN_OR_EQUAL', 'GREATER_THAN', 'GREATER_THAN_OR_EQUAL'],
      },
    },
    {
      NUMBER: {
        value_object_type: 'NUMBER',
        operator_types: ['EQUALS', 'LESS_THAN', 'LESS_THAN_OR_EQUAL', 'GREATER_THAN', 'GREATER_THAN_OR_EQUAL'],
      },
    },
    {
      PERCENTAGE: {
        value_object_type: 'PERCENTAGE',
        operator_types: ['EQUALS', 'LESS_THAN', 'LESS_THAN_OR_EQUAL', 'GREATER_THAN', 'GREATER_THAN_OR_EQUAL'],
      },
    },
    {
      STRING: {
        value_object_type: 'STRING',
        operator_types: ['EQUALS', 'NOT_EQUALS', 'STARTS_WITH', 'CONTAINS', 'DOES_NOT_CONTAIN'],
      },
    },
    {
      TIME: {
        value_object_type: 'TIME',
        operator_types: ['BETWEEN_TIME'],
      },
    },
  ],
  operators: [
    {
      BETWEEN_TIME: {
        display_name: 'between',
        operator_type: 'BETWEEN_TIME',
      },
    },
    {
      BETWEEN_DATE: {
        display_name: 'between',
        operator_type: 'BETWEEN_DATE',
      },
    },
    {
      CONTAINS: {
        display_name: 'contains',
        operator_type: 'CONTAINS',
      },
    },
    {
      DOES_NOT_CONTAIN: {
        display_name: 'does not contain',
        operator_type: 'DOES_NOT_CONTAIN',
      },
    },
    {
      NOT_EQUALS: {
        display_name: 'does not equal',
        operator_type: 'NOT_EQUALS',
      },
    },
    {
      EQUALS: {
        display_name: 'equals',
        operator_type: 'EQUALS',
      },
    },
    {
      EVER: {
        display_name: 'ever',
        operator_type: 'EVER',
      },
    },
    {
      IS_ANYTHING: {
        display_name: 'is anything',
        operator_type: 'IS_ANYTHING',
      },
    },
    {
      EXCLUDES_ALL: {
        display_name: 'excludes all',
        operator_type: 'EXCLUDES_ALL',
      },
    },
    {
      EXCLUDES_ANY: {
        display_name: 'excludes any',
        operator_type: 'EXCLUDES_ANY',
      },
    },
    {
      GREATER_THAN: {
        display_name: 'greater than',
        operator_type: 'GREATER_THAN',
      },
    },
    {
      GREATER_THAN_OR_EQUAL: {
        display_name: 'greater than or equal to',
        operator_type: 'GREATER_THAN_OR_EQUAL',
      },
    },
    {
      INCLUDES_ALL: {
        display_name: 'includes all',
        operator_type: 'INCLUDES_ALL',
      },
    },
    {
      INCLUDES_ANY: {
        display_name: 'includes any',
        operator_type: 'INCLUDES_ANY',
      },
    },
    {
      LESS_THAN: {
        display_name: 'less than',
        operator_type: 'LESS_THAN',
      },
    },
    {
      LESS_THAN_OR_EQUAL: {
        display_name: 'less than or equal to',
        operator_type: 'LESS_THAN_OR_EQUAL',
      },
    },
    {
      STARTS_WITH: {
        display_name: 'starts with',
        operator_type: 'STARTS_WITH',
      },
    },
    {
      WITHIN_THE_NEXT: {
        display_name: 'within the next',
        operator_type: 'WITHIN_THE_NEXT',
      },
    },
    {
      WITHIN_THE_PAST: {
        display_name: 'within the past',
        operator_type: 'WITHIN_THE_PAST',
      },
    },
  ],
}
// When this is in an API, make adapter(s) for all these types for snake <-> camel
export interface AutoTagConditionConfig {
  conditionType: string
  displayName: string
  valueObjectTypes: CustomAutoTagValueObjectType[]
  validChainConditionTypes: string[]
}
const conditionConfigs: { [key: string]: AutoTagConditionConfig } = {}
customAutoTagObjects.conditions.forEach(conditionObject => {
  Object.entries(conditionObject).forEach(([k, v]) => {
    conditionConfigs[k] = {
      conditionType: v.condition_type,
      displayName: v.display_name,
      valueObjectTypes: v.value_object_types,
      validChainConditionTypes: v.valid_chain_conditions,
    }
  })
})

interface AutoTagConfigValueObjectType {
  valueObjectType: CustomAutoTagValueObjectType
  operatorTypes: CustomAutoTagOperatorType[]
  hasStaticOptions: boolean
  isMultiSelect: boolean
  options: SelectOption[]
}
const valueObjects: { [key: string]: AutoTagConfigValueObjectType } = {}
const transformOptionsToSelectOptions = (options: { [key: string]: unknown }[]) => {
  const selectOptions: SelectOption[] = []
  options.forEach(option => {
    Object.entries(option).forEach(([id, label]) => {
      selectOptions.push({
        id,
        label: `${label}`,
      })
    })
  })
  return selectOptions
}
customAutoTagObjects.value_objects.forEach(valueObject => {
  Object.entries(valueObject).forEach(([k, v]) => {
    valueObjects[k] = {
      valueObjectType: v.value_object_type,
      operatorTypes: v.operator_types,
      hasStaticOptions: v.has_static_options || false,
      isMultiSelect: v.is_multi_select || false,
      options: v.options ? transformOptionsToSelectOptions(v.options) : [],
    }
  })
})

interface AutoTagConfigOperator {
  displayName: string
  operatorType: CustomAutoTagOperatorType
}
const operators: { [key: string]: AutoTagConfigOperator } = {}
customAutoTagObjects.operators.forEach(operator => {
  Object.entries(operator).forEach(([k, v]) => {
    operators[k] = {
      displayName: v.display_name,
      operatorType: v.operator_type,
    }
  })
})

const conditionSelectOptions: SelectOption[] = Object.values(conditionConfigs).map(conditionDefinition => ({
  id: conditionDefinition.conditionType,
  label: conditionDefinition.displayName,
}))

export const getValueObjectTypeSelectOptions = (valueType: CustomAutoTagValueObjectType) => valueObjects[valueType]?.options || []

export { conditionConfigs, conditionSelectOptions, valueObjects, operators }
