import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { HStack } from '@sevenrooms/core/ui-kit/layout'

interface AutoTagSlideOutFooterProps {
  primaryLabel: string
  primaryAction: () => void
  close?: () => void
}

export function AutoTagSlideOutFooter({ primaryLabel, primaryAction, close }: AutoTagSlideOutFooterProps) {
  const { formatMessage } = useLocales()

  return (
    <HStack spacing="s" ml="auto">
      <Button data-test="ui-kit-slideout-cancel" onClick={close} variant="tertiary">
        {formatMessage(commonMessages.cancel)}
      </Button>
      <Button data-test="ui-kit-slideout-primary-button" onClick={primaryAction} variant="primary">
        {primaryLabel}
      </Button>
    </HStack>
  )
}
