import { useDispatch } from 'react-redux'
import { AdminPageContent, AdminPageMeta } from '@sevenrooms/admin'
import { useLocales } from '@sevenrooms/core/locales'
import { adminAutoTagsMessages } from '../../locales'
import { AdminAutoTagTable } from './AdminAutoTagTable'
import { fetchAutoTagConfigTiers } from './adminAutoTagTableSlice'
import { AutoTagSlideOut } from './AutoTagSlideOut'

export function AdminAutoTags() {
  const dispatch = useDispatch()
  const { formatMessage } = useLocales()

  dispatch(fetchAutoTagConfigTiers())

  return (
    <>
      <AdminPageMeta title={formatMessage(adminAutoTagsMessages.title)} />

      <AdminPageContent title={formatMessage(adminAutoTagsMessages.title)}>
        <AdminAutoTagTable />
      </AdminPageContent>

      <AutoTagSlideOut />
    </>
  )
}
