import { useCallback } from 'react'
import type { EmailCampaignTemplate } from '@sevenrooms/core/api'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { VStack, HStack, Box, Modal, ModalHeader, ModalTitle, ModalFooter, ModalActions } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { adminOneTimeEmailCampaignTemplatesMessages } from '../../locales'

export interface AdminOneTimeEmailCampaignTemplateArchiveModalProps {
  closeHref: string
  emailCampaignTemplate: EmailCampaignTemplate
  onArchive: (emailCampaignTemplate: EmailCampaignTemplate) => void
  isLoading: boolean
}

export function AdminOneTimeEmailCampaignTemplateArchiveModal({
  emailCampaignTemplate,
  onArchive,
  closeHref,
  isLoading,
}: AdminOneTimeEmailCampaignTemplateArchiveModalProps) {
  const { formatMessage } = useLocales()

  const onArchiveConfirm = useCallback(() => {
    onArchive(emailCampaignTemplate)
  }, [emailCampaignTemplate, onArchive])

  return (
    <Modal data-test="archive-modal" ariaLabel={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.archiveModalTitle)}>
      <ModalHeader closeHref={closeHref}>
        <VStack spacing="s">
          <HStack spacing="s">
            <Box>
              <Icon name="VMSWeb-warning" color="error" size="lg" />
            </Box>
            <Text color="error" fontWeight="bold">
              {formatMessage(commonMessages.warning)}
            </Text>
          </HStack>
          <ModalTitle title={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.archiveModalTitle)} />
        </VStack>
      </ModalHeader>

      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" href={closeHref} data-test="archive-modal-cancel">
            {formatMessage(commonMessages.cancel)}
          </Button>
          <Button data-test="archive-modal-confirm" variant="primary-warning" onClick={onArchiveConfirm} disabled={isLoading}>
            {formatMessage(commonMessages.archive)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
