/* eslint-disable import/no-cycle */
import _ from 'lodash'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { AdminPageContent, AdminPageMeta, useStoreSelector } from '@sevenrooms/admin'
import { useAdminOngoingEmailCampaignTemplateFilterContext, usePushAdminEmailCampaignUpdatesLiveMutation } from '@sevenrooms/core/api'
import {
  EmailCampaignCategoryEnum,
  MarketingCampaignProductTypeEnum,
  MarketingCampaignRegionEnum,
  EmailCampaignStatusEnum,
  MarketingCampaignVenueTypeEnum,
} from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Surface, useNavigation } from '@sevenrooms/core/navigation'
import { Toggle } from '@sevenrooms/core/ui-kit/core'
import { Button, Menu, MenuItem } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import {
  Box,
  HStack,
  notify,
  Spreadsheet,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Window,
  type DataTableColumn,
} from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { routes } from '@sevenrooms/routes'
import { adminOngoingEmailCampaignTemplateMessages } from '../../locales'
import { AdminOngoingEmailCampaignTemplatesConfirmDeleteModal } from './AdminOngoingEmailCampaignTemplatesConfirmDeleteModal'
import { setState } from './AdminOngoingEmailCampaignTemplatesConfirmDeleteModal/adminOngoingEmailCampaignTemplatesConfirmDeleteModalSlice'
import { AdminOngoingEmailCampaignTemplatesFilters } from './AdminOngoingEmailCampaignTemplatesFilters'
import {
  removeAdminOngoingEmailCampaignTemplatesTableRowByIndex,
  setUpdatesLiveStatus,
} from './adminOngoingEmailCampaignTemplatesTableSlice'
import { AdminOngoingEmailCampaignTemplatesToggleStatusModal } from './AdminOngoingEmailCampaignTemplatesToggleStatusModal/AdminOngoingEmailCampaignTemplatesToggleStatusModal'
import { setAdminOngoingEmailCampaignTemplatesToggleStatusModalState } from './AdminOngoingEmailCampaignTemplatesToggleStatusModal/adminOngoingEmailCampaignTemplatesToggleStatusModalSlice'

export interface AdminOngoingEmailCampaignTemplatesTableRow {
  templateName: string
  venueType?: string
  region?: string
  lastUpdated?: string
  defaultStatus?: string
  productType?: string
  campaignCategory?: string
  id: string
  status: string
  subRows?: AdminOngoingEmailCampaignTemplatesTableRow[]
  hasNewUpdates?: boolean
}

export function AdminOngoingEmailCampaignTemplatesTable() {
  const { formatMessage } = useLocales()
  const { rows } = useStoreSelector(state => state['admin.ongoingEmailCampaignTemplatesTable'])
  const nav = useNavigation()
  const dispatch = useDispatch()
  const testRows = _.isEmpty(rows) ? placeholderRows : rows
  const { campaignRegion, campaignVenueType, orderingPlatform } = useAdminOngoingEmailCampaignTemplateFilterContext()
  const [pushAdminEmailCampaignUpdatesLive] = usePushAdminEmailCampaignUpdatesLiveMutation()
  const onPremRows = createCampaignProductTypeRows(filterRows(testRows, onPremProductTypes, campaignRegion, campaignVenueType))
  const orderingRows = createCampaignProductTypeRows(
    filterRows(testRows, orderingProductTypes, campaignRegion, campaignVenueType, orderingPlatform)
  )
  const columns = useCallback<(rows: AdminOngoingEmailCampaignTemplatesTableRow[]) => DataTableColumn<typeof rows[number]>[]>(
    () => [
      {
        key: 'templateName',
        header: formatMessage(adminOngoingEmailCampaignTemplateMessages.templateName),
        render: (row: AdminOngoingEmailCampaignTemplatesTableRow) => (
          <>
            {row.templateName}
            {row.hasNewUpdates && row.status === EmailCampaignStatusEnum.ACTIVE && (
              <Text textDisplay="block" color="warning">
                {formatMessage(adminOngoingEmailCampaignTemplateMessages.updatesToPush)}
              </Text>
            )}
          </>
        ),
        minWidth: 158,
        maxWidth: 158,
      },
      { header: formatMessage(adminOngoingEmailCampaignTemplateMessages.venueType), render: 'venueType', minWidth: 140, maxWidth: 140 },
      { header: formatMessage(adminOngoingEmailCampaignTemplateMessages.region), render: 'region', minWidth: 160, maxWidth: 160 },
      { header: formatMessage(adminOngoingEmailCampaignTemplateMessages.lastUpdated), render: 'lastUpdated', minWidth: 180, maxWidth: 180 },
      {
        header: '',
        cellAlign: 'end',
        key: 'action',
        render: (row: AdminOngoingEmailCampaignTemplatesTableRow, rowIndex: number) => {
          const isActive = row.status === EmailCampaignStatusEnum.ACTIVE
          const deleteItemColor = isActive ? 'deactivated' : 'darkFont'
          return (
            <HStack alignItems="center" spacing="sm">
              <Toggle
                data-test="toggle-email-campaign-status"
                onChange={() => handleStatusToggle(row)}
                checked={isActive}
                disabled={getIsToggleDisabled(row)}
              />
              {!row.subRows?.length && (
                <>
                  <Menu scrollLock>
                    <MenuItem
                      onClick={() => {
                        nav.push(routes.admin.ongoingEmailCampaignTemplates.edit, { params: { templateId: row.id } })
                      }}
                    >
                      <Icon name="VMSWeb-edit" />
                      {formatMessage(adminOngoingEmailCampaignTemplateMessages.edit)}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        nav.push(routes.admin.ongoingEmailCampaignTemplates.duplicate, { params: { templateId: row.id } })
                      }}
                    >
                      <Icon name="VMSWeb-copy" />
                      {formatMessage(adminOngoingEmailCampaignTemplateMessages.duplicate)}
                    </MenuItem>
                    {row.hasNewUpdates && isActive && (
                      <MenuItem
                        onClick={async () => {
                          try {
                            const data = await pushAdminEmailCampaignUpdatesLive({ emailCampaignTemplateId: row.id })
                            if ('error' in data) {
                              notify({ content: formatMessage(adminOngoingEmailCampaignTemplateMessages.pushToLiveError), type: 'error' })
                            } else {
                              setUpdatesLiveStatus(row.id)
                              dispatch(setUpdatesLiveStatus(row.id))
                              notify({
                                content: formatMessage(adminOngoingEmailCampaignTemplateMessages.pushToLiveSuccess),
                                type: 'success',
                              })
                            }
                          } catch {
                            notify({ content: formatMessage(adminOngoingEmailCampaignTemplateMessages.pushToLiveError), type: 'error' })
                          }
                        }}
                      >
                        <Icon name="VMSWeb-refresh" />
                        {formatMessage(adminOngoingEmailCampaignTemplateMessages.pushUpdatesLive)}
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={() => {
                        if (isActive) {
                          return
                        }
                        dispatch(setState({ templateId: row.id, rowIndex }))
                        nav.push(routes.admin.ongoingEmailCampaignTemplates.confirmDeleteModal)
                      }}
                    >
                      <Icon name="VMSWeb-trash" color={deleteItemColor} />
                      <Text color={deleteItemColor}>{formatMessage(adminOngoingEmailCampaignTemplateMessages.delete)}</Text>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </HStack>
          )
        },
        expandable: true,
        minWidth: 109,
        maxWidth: 109,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formatMessage, nav]
  )

  const handleStatusToggle = (row: AdminOngoingEmailCampaignTemplatesTableRow) => {
    const updatedStatus = getIsActive(row) ? EmailCampaignStatusEnum.INACTIVE : EmailCampaignStatusEnum.ACTIVE
    const emailCampaignTemplateIds = row.subRows
      ? row.subRows.filter(subRow => subRow.status === row.status).map(subRow => subRow.id)
      : [row.id]
    const toggleAdminEmailCampaignStatusModalState = {
      emailCampaignTemplateIds,
      updatedStatus,
    }
    dispatch(setAdminOngoingEmailCampaignTemplatesToggleStatusModalState(toggleAdminEmailCampaignStatusModalState))
    nav.push(routes.admin.ongoingEmailCampaignTemplates.toggleEmailCampaignTemplateStatusModal)
  }

  const removeRowByIndex = (index: number) => {
    dispatch(removeAdminOngoingEmailCampaignTemplatesTableRowByIndex(index))
  }

  return (
    <>
      <AdminPageMeta title={formatMessage(adminOngoingEmailCampaignTemplateMessages.ongoingCampaignsTitle)} />

      <AdminPageContent
        title={formatMessage(adminOngoingEmailCampaignTemplateMessages.ongoingCampaignsTitle)}
        actions={
          <Button
            variant="primary"
            icon="VMSWeb-add"
            onClick={() => {
              nav.push(routes.admin.ongoingEmailCampaignTemplates.create)
            }}
            data-test="add-new-automated-email-button"
          >
            {formatMessage(adminOngoingEmailCampaignTemplateMessages.addNewCampaign)}
          </Button>
        }
      >
        <Tabs>
          <TabList>
            <Tab data-test="on-prem-campaign-button">{formatMessage(adminOngoingEmailCampaignTemplateMessages.onPremCampaigns)}</Tab>
            <Tab data-test="ordering-automated-emails-button">
              {formatMessage(adminOngoingEmailCampaignTemplateMessages.orderingCampaigns)}
            </Tab>
          </TabList>
          <Box pt="m">
            <TabPanel>
              <AdminOngoingEmailCampaignTemplatesFilters />
              <Spreadsheet columns={columns(onPremRows)} data={onPremRows} />
            </TabPanel>
            <TabPanel>
              <AdminOngoingEmailCampaignTemplatesFilters isOrderingCampaignTab />
              <Spreadsheet columns={columns(orderingRows)} data={orderingRows} />
            </TabPanel>
          </Box>
        </Tabs>
      </AdminPageContent>

      <Surface destination={routes.admin.ongoingEmailCampaignTemplates.toggleEmailCampaignTemplateStatusModal}>
        <Window>
          <AdminOngoingEmailCampaignTemplatesToggleStatusModal
            closeHref={nav.closeSurfaceHref(routes.admin.ongoingEmailCampaignTemplates.toggleEmailCampaignTemplateStatusModal)}
          />
        </Window>
      </Surface>
      <Surface destination={routes.admin.ongoingEmailCampaignTemplates.confirmDeleteModal}>
        <Window>
          <AdminOngoingEmailCampaignTemplatesConfirmDeleteModal
            closeHref={nav.closeSurfaceHref(routes.admin.ongoingEmailCampaignTemplates.confirmDeleteModal)}
            removeRowByIndex={removeRowByIndex}
          />
        </Window>
      </Surface>
    </>
  )
}

const onPremProductTypes = [MarketingCampaignProductTypeEnum.ON_PREM]

const orderingProductTypes = [
  MarketingCampaignProductTypeEnum.ORDERING_BBOT,
  MarketingCampaignProductTypeEnum.ORDERING_OLO,
  MarketingCampaignProductTypeEnum.ORDERING_SEVENROOMS,
]

const filterRows = (
  rows: AdminOngoingEmailCampaignTemplatesTableRow[],
  productType: string[],
  campaignRegion?: string[],
  campaignVenueType?: string[],
  orderingPlatform?: string[]
) => {
  const filteredRows: AdminOngoingEmailCampaignTemplatesTableRow[] = []
  const allRegions = !!(!campaignRegion?.length || campaignRegion?.includes('ALL_REGIONS'))
  const allVenueTypes = !!(!campaignVenueType?.length || campaignVenueType?.includes('ALL_VENUE_TYPES'))
  const allOrderingPlatforms = !!(!orderingPlatform?.length || orderingPlatform?.includes('ALL_PLATFORMS'))
  const regions = convertFilterSelections(MarketingCampaignRegionEnum, campaignRegion || [])
  const venueTypes = convertFilterSelections(MarketingCampaignVenueTypeEnum, campaignVenueType || [])
  const orderingPlatforms = convertFilterSelections(MarketingCampaignProductTypeEnum, orderingPlatform || [])
  for (const row of rows) {
    if (
      productType.includes(row.productType || '') &&
      (allRegions || regions?.includes(row.region || '')) &&
      (allVenueTypes || venueTypes?.includes(row.venueType || '')) &&
      (allOrderingPlatforms || orderingPlatforms?.includes(row.productType || ''))
    ) {
      filteredRows.push(row)
    }
  }
  return filteredRows
}

const convertFilterSelections = (FilterEnum: { [key: string]: string }, filterSelections: string[]) => {
  const convertedSelections = []
  for (const selection of filterSelections) {
    if (Object.keys(FilterEnum).includes(selection)) {
      convertedSelections.push(FilterEnum[selection])
    }
  }
  return convertedSelections
}

const createCampaignProductTypeRows = (rows: AdminOngoingEmailCampaignTemplatesTableRow[]) => {
  const thankYouCampaigns: AdminOngoingEmailCampaignTemplatesTableRow[] = []
  const winbackCampaigns: AdminOngoingEmailCampaignTemplatesTableRow[] = []
  const feedbackCampaigns: AdminOngoingEmailCampaignTemplatesTableRow[] = []
  const abandonCampaigns: AdminOngoingEmailCampaignTemplatesTableRow[] = []
  const otherCampaigns: AdminOngoingEmailCampaignTemplatesTableRow[] = []
  for (const campaign of rows) {
    if (campaign.campaignCategory === EmailCampaignCategoryEnum.THANK_YOU) {
      thankYouCampaigns.push(campaign)
    } else if (campaign.campaignCategory === EmailCampaignCategoryEnum.WINBACK) {
      winbackCampaigns.push(campaign)
    } else if (campaign.campaignCategory === EmailCampaignCategoryEnum.FEEDBACK) {
      feedbackCampaigns.push(campaign)
    } else if (campaign.campaignCategory === EmailCampaignCategoryEnum.ABANDON) {
      abandonCampaigns.push(campaign)
    } else {
      otherCampaigns.push(campaign)
    }
  }
  const campaignCategoryRows = createCampaignCategoryRows(
    thankYouCampaigns,
    winbackCampaigns,
    feedbackCampaigns,
    abandonCampaigns,
    otherCampaigns
  )
  return campaignCategoryRows
}

const createCampaignCategoryRows = (
  thankYouCampaigns: AdminOngoingEmailCampaignTemplatesTableRow[],
  winbackCampaigns: AdminOngoingEmailCampaignTemplatesTableRow[],
  feedbackCampaigns: AdminOngoingEmailCampaignTemplatesTableRow[],
  abandonCampaigns: AdminOngoingEmailCampaignTemplatesTableRow[],
  otherCampaigns: AdminOngoingEmailCampaignTemplatesTableRow[]
) => {
  const categoryRows: AdminOngoingEmailCampaignTemplatesTableRow[] = []
  if (!_.isEmpty(thankYouCampaigns)) {
    categoryRows.push({
      templateName: EmailCampaignCategoryEnum.THANK_YOU,
      id: EmailCampaignCategoryEnum.THANK_YOU,
      subRows: thankYouCampaigns,
      status: getCategoryRowStatus(thankYouCampaigns),
    })
  }
  if (!_.isEmpty(winbackCampaigns)) {
    categoryRows.push({
      templateName: EmailCampaignCategoryEnum.WINBACK,
      id: EmailCampaignCategoryEnum.WINBACK,
      subRows: winbackCampaigns,
      status: getCategoryRowStatus(winbackCampaigns),
    })
  }
  if (!_.isEmpty(feedbackCampaigns)) {
    categoryRows.push({
      templateName: EmailCampaignCategoryEnum.FEEDBACK,
      id: EmailCampaignCategoryEnum.FEEDBACK,
      subRows: feedbackCampaigns,
      status: getCategoryRowStatus(feedbackCampaigns),
    })
  }
  if (!_.isEmpty(abandonCampaigns)) {
    categoryRows.push({
      templateName: EmailCampaignCategoryEnum.ABANDON,
      id: EmailCampaignCategoryEnum.ABANDON,
      subRows: abandonCampaigns,
      status: getCategoryRowStatus(abandonCampaigns),
    })
  }
  if (!_.isEmpty(otherCampaigns)) {
    categoryRows.push({
      templateName: EmailCampaignCategoryEnum.OTHER,
      id: EmailCampaignCategoryEnum.OTHER,
      subRows: otherCampaigns,
      status: getCategoryRowStatus(otherCampaigns),
    })
  }
  return categoryRows
}

const getCategoryRowStatus = (subRows: AdminOngoingEmailCampaignTemplatesTableRow[]): string =>
  subRows.some(subRow => subRow.status === EmailCampaignStatusEnum.ACTIVE)
    ? EmailCampaignStatusEnum.ACTIVE
    : EmailCampaignStatusEnum.INACTIVE

const getIsActive = (row: AdminOngoingEmailCampaignTemplatesTableRow): boolean =>
  row.subRows ? row.subRows.some(subRow => subRow.status === EmailCampaignStatusEnum.ACTIVE) : row.status === EmailCampaignStatusEnum.ACTIVE

const getIsToggleDisabled = (row: AdminOngoingEmailCampaignTemplatesTableRow): boolean =>
  row.subRows
    ? !row.subRows.some(subRow => subRow.status === EmailCampaignStatusEnum.ACTIVE || subRow.status === EmailCampaignStatusEnum.INACTIVE)
    : !(row.status === EmailCampaignStatusEnum.ACTIVE || row.status === EmailCampaignStatusEnum.INACTIVE)

const placeholderRows: AdminOngoingEmailCampaignTemplatesTableRow[] = [
  {
    templateName: 'First Time Visit',
    venueType: 'Fine Dining',
    region: 'UK/Europe',
    lastUpdated: '1/11/12 at 11:20 am',
    defaultStatus: 'On',
    productType: 'On Prem',
    campaignCategory: 'Thank You',
    status: 'ACTIVE',
    id: '1',
    hasNewUpdates: true,
  },
  {
    templateName: 'Second Time Visit',
    venueType: 'Nightlife',
    region: 'North America',
    lastUpdated: '1/11/12 at 11:20 am',
    defaultStatus: 'Off',
    productType: 'On Prem',
    campaignCategory: 'Thank You',
    status: 'ACTIVE',
    id: '2',
  },
  {
    templateName: 'Welcome Back',
    venueType: 'Nightlife',
    region: 'North America',
    lastUpdated: '1/11/12 at 11:20 am',
    defaultStatus: 'Off',
    productType: 'On Prem',
    campaignCategory: 'Winback',
    status: 'ACTIVE',
    id: '3',
  },
  {
    templateName: 'Welcome Back',
    venueType: 'Nightlife',
    region: 'North America',
    lastUpdated: '1/11/12 at 11:20 am',
    defaultStatus: 'Off',
    productType: 'Ordering (bbot)',
    campaignCategory: 'Winback',
    status: 'ACTIVE',
    id: '4',
  },
]
