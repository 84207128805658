import { defineMessages } from '@sevenrooms/core/locales'

export const adminAutoTagsMessages = defineMessages({
  title: {
    id: 'adminAutoTags.title',
    defaultMessage: 'Auto-tags',
  },
  addNewAutoTagButton: {
    id: 'adminAutoTags.addNewAutoTagButton',
    defaultMessage: 'Add New Auto-tag',
  },
  tagsTab: {
    id: 'adminAutoTags.tagsTab',
    defaultMessage: 'Tags',
  },
  archivedTagsTab: {
    id: 'adminAutoTags.archivedTagsTab',
    defaultMessage: 'Archived Tags',
  },
  global: {
    id: 'adminAutoTags.global',
    defaultMessage: 'Global',
  },
  local: {
    id: 'adminAutoTags.local',
    defaultMessage: 'Local',
  },
  createAutoTagSlideOutTitle: {
    id: 'adminAutoTags.createAutoTagSlideOutTitle',
    defaultMessage: 'Create Auto-tag',
  },
  editAutoTagSlideOutTitle: {
    id: 'adminAutoTags.editAutoTagSlideOutTitle',
    defaultMessage: 'Edit Auto-tag',
  },
  tableTagNameHeader: {
    id: 'adminAutoTags.tableTagNameHeader',
    defaultMessage: 'Auto-tag Name',
  },
  tableCategoryHeader: {
    id: 'adminAutoTags.tableCategoryHeader',
    defaultMessage: 'Category',
  },
  tableCreatedByHeader: {
    id: 'adminAutoTags.tableCreatedByHeader',
    defaultMessage: 'Created By',
  },
  tableTypeHeader: {
    id: 'adminAutoTags.tableTypeHeader',
    defaultMessage: 'Type',
  },
  and: {
    id: 'adminAutoTags.and',
    defaultMessage: 'AND',
  },
  or: {
    id: 'adminAutoTags.or',
    defaultMessage: 'OR',
  },
} as const)
