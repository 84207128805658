import { createAsyncThunk, createSlice, type PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  type AutoTagTierConfig,
  type AutoTagConfigCondition,
  type AutoTagConfigConditionTree,
  AdminAutoTagsService,
  type AdminAutoTagConfig,
} from '@sevenrooms/core/api'
import { AutoTagTierTypeEnum, type AutoTagConfigType, AutoTagConfigTypeEnum, type AutoTagTierAttributeType } from '@sevenrooms/core/domain'

const getNewTierConfig = (configType: AutoTagConfigType) => {
  const tierConfig: Partial<AutoTagTierConfig> = {
    customTierId: null,
    isActive: true,
    isEdited: false,
    isForMarketingSegmentation: false,
    nameDisplay: 'New Tag',
  }
  switch (configType) {
    case AutoTagConfigTypeEnum.SPEND_LOCAL:
      tierConfig.tierType = AutoTagTierTypeEnum.SPEND_LOCAL_TIER
      tierConfig.attributes = { MIN_VISITS: 0, SPEND_AMOUNT: 0, SPEND_TYPE: 'TOTAL' }
      break
    case AutoTagConfigTypeEnum.SPEND_GLOBAL:
      tierConfig.tierType = AutoTagTierTypeEnum.SPEND_GLOBAL_TIER
      tierConfig.attributes = { MIN_VISITS: 0, SPEND_AMOUNT: 0, SPEND_TYPE: 'TOTAL' }
      break
    case AutoTagConfigTypeEnum.VISITS_LOCAL:
      tierConfig.tierType = AutoTagTierTypeEnum.VISITS_LOCAL_TIER
      tierConfig.attributes = { MIN_VISITS: 0, PAST_MONTHS_MAX: 0 }
      break
    case AutoTagConfigTypeEnum.VISITS_GLOBAL:
    default:
      tierConfig.tierType = AutoTagTierTypeEnum.VISITS_GLOBAL_TIER
      tierConfig.attributes = { PAST_MONTHS_MAX: 0, MIN_VISITS: 0, MIN_VENUES: 0 }
      break
  }
  return tierConfig as AutoTagTierConfig
}

export interface CustomAutoTagSlideOutState {
  isShowing: boolean
  isLoading: boolean
  loadingError?: string
  autoTagConfig?: AdminAutoTagConfig

  // We maintain these fields here instead of solely in the autoTagConfig
  // to account for the creation of new tags, which won't have an existing
  // tier config for us to modify.
  isCustom: boolean
  tagDisplayName: string
  conditionTree: AutoTagConfigConditionTree

  tagName: string
}

const initialState: CustomAutoTagSlideOutState = {
  isShowing: false,
  isLoading: false,
  tagDisplayName: '',
  isCustom: false,
  conditionTree: [],
  tagName: '',
}

type AutoTagConfigConditionEdit = Partial<AutoTagConfigCondition>
export interface ConditionTreeUpdate {
  path: (number | string)[]
  data: AutoTagConfigConditionEdit
}

export interface TierConfigAttributeUpdate {
  index: number
  attribute: AutoTagTierAttributeType
  value: number | string
}

export interface TierConfigUpdate {
  index: number
  value: Partial<AutoTagTierConfig>
}

export const fetchAutoTagConfig = createAsyncThunk('autoTagSlideOut/fetch AutoTagConfig', async (id: string) => {
  const response = await AdminAutoTagsService.getAutoTagConfig(id)
  return response
})

const autoTagSlideOutSlice = createSlice({
  name: 'autoTagSlideOut',
  initialState,
  reducers: {
    updateSlideOutState: (state, action: PayloadAction<Partial<CustomAutoTagSlideOutState>>) => Object.assign(state, action.payload),
    resetSlideOutState: () => initialState,
    setConditionTree: (state, action: PayloadAction<AutoTagConfigConditionTree>) => {
      state.conditionTree = action.payload
    },
    addConditionGroup: state => {
      state.conditionTree.push([{ chain: [], condition: undefined, operator: undefined, value: undefined }])
    },
    addCondition: (state, action: PayloadAction<{ groupIndex: number }>) => {
      state.conditionTree[action.payload.groupIndex]?.push({
        chain: [],
        condition: undefined,
        operator: undefined,
        value: undefined,
      })
    },
    updateConditionTree: (state, action: PayloadAction<ConditionTreeUpdate>) => {
      Object.assign(_.get(state.conditionTree, action.payload.path), action.payload.data)
    },
    removeCondition: (state, action: PayloadAction<[number, number]>) => {
      const [groupIndex, conditionIndex] = action.payload
      state.conditionTree[groupIndex]?.splice(conditionIndex, 1)
      if (state.conditionTree[groupIndex]?.length === 0) {
        state.conditionTree.splice(groupIndex, 1)
      }
    },
    addTierConfig: (state, action: PayloadAction<AutoTagConfigType>) => {
      if (state.autoTagConfig) {
        state.autoTagConfig.tierConfigs.push(getNewTierConfig(action.payload))
      }
    },
    updateTierConfigAttribute: (state, action: PayloadAction<TierConfigAttributeUpdate>) => {
      if (state.autoTagConfig) {
        Object.assign(_.get(state.autoTagConfig.tierConfigs, [action.payload.index, 'attributes']), {
          [action.payload.attribute]: action.payload.value,
        })
      }
    },
    updateTierConfig: (state, action: PayloadAction<TierConfigUpdate>) => {
      if (state.autoTagConfig) {
        Object.assign(_.get(state.autoTagConfig.tierConfigs, action.payload.index), action.payload.value)
      }
    },
    removeTierConfig: (state, action: PayloadAction<number>) => {
      if (state.autoTagConfig) {
        state.autoTagConfig.tierConfigs.splice(action.payload, 1)
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchAutoTagConfig.pending, state => {
      state.isLoading = true
      state.loadingError = undefined
    })

    builder.addCase(fetchAutoTagConfig.fulfilled, (state, { payload }) => {
      state.autoTagConfig = payload
      state.isCustom = payload.isConfigTypeCustom
      state.isLoading = false
    })

    builder.addCase(fetchAutoTagConfig.rejected, (state, { payload }) => {
      state.loadingError = JSON.stringify(payload)
      state.isLoading = false
    })
  },
})

const { actions } = autoTagSlideOutSlice
export const {
  setConditionTree,
  addConditionGroup,
  addCondition,
  updateConditionTree,
  removeCondition,
  updateSlideOutState,
  resetSlideOutState,
  updateTierConfigAttribute,
  removeTierConfig,
  addTierConfig,
  updateTierConfig,
} = actions
export const { reducer: autoTagSlideOutSliceReducer, name: autoTagSlideOutSliceName } = autoTagSlideOutSlice
