import { useDispatch } from 'react-redux'
import type { AutoTagConfigCondition } from '@sevenrooms/core/api'
import { z, useForm } from '@sevenrooms/core/form'
import { FormSelect } from '@sevenrooms/core/ui-kit/form'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { updateConditionTree } from '../../../autoTagSlideOutSlice'
import { conditionConfigs, conditionSelectOptions } from '../CustomAutoTagDataPlaceholder'

export function CustomAutoTagConditionSelect({ path, selectedConditionType }: { path: number[]; selectedConditionType?: string }) {
  const dispatch = useDispatch()

  const formValues = z.object({
    conditionType: z.string(),
  })
  const form = useForm(formValues)
  const { field } = form

  form.setValue('conditionType', selectedConditionType || '')

  return (
    <Box width="364px">
      <FormSelect
        field={field.prop('conditionType')}
        options={conditionSelectOptions}
        onChange={selected => {
          if (!selected || selected === selectedConditionType) {
            return
          }

          const condition = conditionConfigs[selected]
          if (!condition) {
            return
          }

          const chainConditions: AutoTagConfigCondition[] = condition.validChainConditionTypes.map(chainConditionType => ({
            condition: chainConditionType,
            chain: [],
            operator: undefined,
            value: undefined,
          }))

          dispatch(
            updateConditionTree({
              path,
              data: { condition: condition.conditionType, chain: chainConditions, operator: undefined, value: undefined },
            })
          )
        }}
      />
    </Box>
  )
}
