import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { FormSelect } from '@sevenrooms/core/ui-kit/form'
import { FluidControl, type FluidControlProps } from './FluidControl'

interface SelectProps<T extends string | number> {
  options: SelectOption<T>[]
  onChange?: () => void
  disabled?: boolean
}
interface FluidSelectProps<T extends string | number> extends FluidControlProps<T>, SelectProps<T> {}

export function FluidSelect<T extends string | number>({ options, onChange, disabled, ...props }: FluidSelectProps<T>) {
  return (
    <FluidControl {...props}>
      <FormSelect options={options} field={props.field} hideFilterIcon onChange={onChange} disabled={disabled} />
    </FluidControl>
  )
}

interface FluidSelectNullableProps<T extends string | number> extends FluidControlProps<null | T>, SelectProps<T> {}

export function FluidSelectNullable<T extends string | number>({ options, disabled, onChange, ...props }: FluidSelectNullableProps<T>) {
  return (
    <FluidControl {...props}>
      <FormSelect options={options} field={props.field} hideFilterIcon withEmpty hideEmptyOption disabled={disabled} onChange={onChange} />
    </FluidControl>
  )
}
