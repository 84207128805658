import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { type AutoTagTierAttributeType, AutoTagTierAttributeTypeEnum } from '@sevenrooms/core/domain'
import { CurrencyInput, NumberInput } from '@sevenrooms/core/ui-kit/core'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { updateTierConfigAttribute } from '../../autoTagSlideOutSlice'

const MAX = 99999

export function DefaultAutoTagNumberInput({
  index,
  attribute,
  initialValue,
}: {
  index: number
  attribute: AutoTagTierAttributeType
  initialValue?: number
}) {
  const dispatch = useDispatch()
  const [value, setValue] = useState<number | null>(initialValue ?? 0)

  const updateStore = (value: number) => dispatch(updateTierConfigAttribute({ index, attribute, value }))

  if (initialValue === undefined) {
    dispatch(updateStore(0))
  }

  return (
    <Box width="100px" display="inline-block">
      {attribute === AutoTagTierAttributeTypeEnum.SPEND_AMOUNT ? (
        <CurrencyInput value={value} onChange={setValue} onBlur={() => updateStore(value ?? 0)} max={MAX} />
      ) : (
        <NumberInput value={value} onChange={setValue} onBlur={() => updateStore(value ?? 0)} max={MAX} />
      )}
    </Box>
  )
}
