import { srGet, srPostJson, throwFetchError } from '@sevenrooms/core/api'
import { BlockStatusAdapter } from '../adapters'
import type { BlockStatus } from '../api'

export namespace ManageBlocklistService {
  export async function getEmailStatus(email: string) {
    const { data } = await srGet<BlockStatus>(`/api-yoa/admin/manage_blocklist/status`, { email }).then(throwFetchError)
    return BlockStatusAdapter.toClient(data)
  }

  export async function unblockEmail(email: string) {
    const { data } = await srPostJson<BlockStatus>(`/api-yoa/admin/manage_blocklist/unblock`, { email }).then(throwFetchError)
    return BlockStatusAdapter.toClient(data)
  }
}
