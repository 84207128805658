import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { CurrencyInput } from '@sevenrooms/core/ui-kit/core'
import { updateConditionTree } from '../../../../autoTagSlideOutSlice'

export function CustomAutoTagCurrencyInput({ path, initialValue }: { path: (string | number)[]; initialValue: number }) {
  const dispatch = useDispatch()
  const [value, setValue] = useState<number | null>(initialValue ?? 0)

  const updateStore = (value?: number) => dispatch(updateConditionTree({ path, data: { value } }))

  if (initialValue === undefined) {
    updateStore(0)
  }

  return <CurrencyInput value={value} onChange={setValue} onBlur={() => updateStore(value ?? undefined)} />
}
