import React from 'react'
import { render } from 'react-dom'
import { AdminPage } from '@sevenrooms/admin'
import { svrExport } from '@sevenrooms/core/ui-kit/utils'
import { AdminTimeslotViewer } from './components'

function renderAdminTimeslotViewer(elementId: string, venueId: string, venueName: string, venueUrlKey: string) {
  return render(
    <AdminPage>
      <AdminTimeslotViewer venueId={venueId} venueName={venueName} venueUrlKey={venueUrlKey} />
    </AdminPage>,
    document.getElementById(elementId)
  )
}

svrExport('AdminTimeslotViewer', 'render', renderAdminTimeslotViewer)
