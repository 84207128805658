import { useDispatch } from 'react-redux'
import type { CustomAutoTagValueObjectType } from '@sevenrooms/core/domain'
import { MultiSelect } from '@sevenrooms/core/ui-kit/form'
import { updateConditionTree } from '../../../../autoTagSlideOutSlice'
import { getValueObjectTypeSelectOptions } from '../../CustomAutoTagDataPlaceholder'

/**
 * TODO
 * The form mechanism for the MultiSelect are currently in development (VX-3174).
 * Once it's complete, we will need to integrate this component with the form
 * mechanisms. Without them, it doesn't handle its own state properly.
 */
export function CustomAutoTagMultiSelect({
  path,
  valueObjectType,
}: {
  path: (string | number)[]
  valueObjectType: CustomAutoTagValueObjectType
}) {
  const dispatch = useDispatch()

  return (
    <MultiSelect
      useCheckboxItems
      options={getValueObjectTypeSelectOptions(valueObjectType)}
      onChange={value => {
        dispatch(updateConditionTree({ path, data: { value } }))
      }}
    />
  )
}
