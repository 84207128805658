/* eslint-disable import/no-cycle */
import { useDispatch } from 'react-redux'
import { useStoreSelector } from '@sevenrooms/admin'
import { type OngoingEmailCampaignTemplate, useUpdateAdminEmailCampaignTemplateMutation } from '@sevenrooms/core/api'
import { EmailCampaignStatusEnum } from '@sevenrooms/core/domain'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useHistory } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import {
  Box,
  HStack,
  VStack,
  Modal,
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
  type ModalHeaderProps,
  ModalTitle,
  notify,
} from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { adminOngoingEmailCampaignTemplateMessages } from '../../../locales'
import { clearAdminOngoingEmailCampaignTemplatesToggleStatusModalState } from './adminOngoingEmailCampaignTemplatesToggleStatusModalSlice'

export interface AdminOngoingEmailCampaignTemplatesToggleStatusModalProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
}

export function AdminOngoingEmailCampaignTemplatesToggleStatusModal({
  closeHref,
}: AdminOngoingEmailCampaignTemplatesToggleStatusModalProps) {
  const { formatMessage } = useLocales()
  const history = useHistory()
  const dispatch = useDispatch()
  const [updateAdminEmailCampaignTemplate, updateAdminEmailCampaignTemplateStatus] = useUpdateAdminEmailCampaignTemplateMutation()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const toggleAdminEmailCampaignTemplateStatusModalState: any = useStoreSelector(state => state.toggleAdminEmailCampaignTemplateStatusModal)
  const { emailCampaignTemplateIds, updatedStatus } = toggleAdminEmailCampaignTemplateStatusModalState || {}

  const isActivation = updatedStatus === EmailCampaignStatusEnum.ACTIVE

  const titleText =
    emailCampaignTemplateIds?.length > 1
      ? formatMessage(
          isActivation
            ? adminOngoingEmailCampaignTemplateMessages.activateCampaignsTitle
            : adminOngoingEmailCampaignTemplateMessages.deactivateCampaignsTitle
        )
      : formatMessage(
          isActivation
            ? adminOngoingEmailCampaignTemplateMessages.activateCampaignTitle
            : adminOngoingEmailCampaignTemplateMessages.deactivateCampaignTitle
        )

  const bodyText =
    emailCampaignTemplateIds?.length > 1
      ? formatMessage(
          isActivation
            ? adminOngoingEmailCampaignTemplateMessages.activateCampaignsWarning
            : adminOngoingEmailCampaignTemplateMessages.deactivateCampaignsWarning,
          { numCampaigns: emailCampaignTemplateIds.length }
        )
      : formatMessage(
          isActivation
            ? adminOngoingEmailCampaignTemplateMessages.activateCampaignWarning
            : adminOngoingEmailCampaignTemplateMessages.deactivateCampaignWarning
        )

  const handleConfirm = async () => {
    try {
      await updateAdminEmailCampaignTemplate({
        emailCampaignTemplateIds,
        emailCampaignTemplate: { status: updatedStatus } as OngoingEmailCampaignTemplate,
      })
      history.push(closeHref)
    } catch {
      notify({
        content: formatMessage(adminOngoingEmailCampaignTemplateMessages.toggleCampaignError),
        type: 'error',
      })
    }
  }

  const handleCancel = () => {
    dispatch(clearAdminOngoingEmailCampaignTemplatesToggleStatusModalState())
    history.push(closeHref)
  }

  return (
    <Modal ariaLabel={titleText}>
      <ModalHeader onClose={handleCancel}>
        <VStack spacing="s">
          <HStack spacing="s">
            <Box>
              <Icon name="VMSWeb-warning" color={isActivation ? 'warning' : 'error'} size="lg" />
            </Box>
            <Text color={isActivation ? 'warning' : 'error'} fontWeight="bold">
              {formatMessage(commonMessages.warning)}
            </Text>
          </HStack>
          <ModalTitle title={titleText} />
        </VStack>
      </ModalHeader>
      <ModalBody>
        <Text>{bodyText}</Text>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button data-test="toggle-admin-email-campaign-status-modal-cancel-button" variant="tertiary" onClick={() => handleCancel()}>
            {formatMessage(commonMessages.cancel)}
          </Button>
          <Button
            data-test="toggle-admin-email-campaign-status-modal-confirm-button"
            variant={isActivation ? 'primary' : 'primary-warning'}
            type="submit"
            onClick={() => handleConfirm()}
            disabled={updateAdminEmailCampaignTemplateStatus.isLoading}
          >
            {formatMessage(
              isActivation ? adminOngoingEmailCampaignTemplateMessages.activate : adminOngoingEmailCampaignTemplateMessages.deactivate
            )}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
