import { useMemo, useCallback, useState } from 'react'
import { JsonView, defaultStyles } from 'react-json-view-lite'
import { AdminPageContent, AdminPageMeta } from '@sevenrooms/admin/components'
import { useLazyGetTimeslotsQuery } from '@sevenrooms/admin-lib-timeslot-viewer'
import { useGetAudienceHierarchyQuery } from '@sevenrooms/core/api'
import type { AudienceHierarchy } from '@sevenrooms/core/domain'
import { useForm, useWatchMany } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import {
  Button,
  Checkbox,
  FormInput,
  FormNumberInput,
  FormSelect,
  Label,
  LoaderButton,
  SingleDatePicker,
  generateTimeSlots,
} from '@sevenrooms/core/ui-kit/form'
import { BaseSection, VStack, Breadcrumbs, HStack, Loader, Banner } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { routes } from '@sevenrooms/routes'
import { useAdminTimeslotViewForm } from '../form'
import { adminTimeslotViewerMessages } from '../locales'

interface AdminTimeslotViewerProps {
  venueId: string
  venueName: string
  venueUrlKey: string
}

export function AdminTimeslotViewer({ venueId, venueName, venueUrlKey }: AdminTimeslotViewerProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const [timeslots, setTimeslots] = useState<Record<string, unknown>[] | null>(null)
  const [getTimeslots, { isLoading }] = useLazyGetTimeslotsQuery()
  const audienceHierarchy = useGetAudienceHierarchyQuery({ venueId })

  const audienceOptions = useMemo(() => transformAudiences(audienceHierarchy.data ?? []), [audienceHierarchy.data])
  const timeSlotOptions = useMemo(
    () =>
      generateTimeSlots(0, 15).map(time => {
        const formattedTime = time.substring(0, 5)
        return { id: formattedTime, label: formattedTime }
      }),
    []
  )

  const defaultValues = useMemo(() => {
    const params = new URLSearchParams(document.location.search)
    return {
      date: new Date(params.get('date') ?? new Date().toISOString()),
      time: params.get('time') ?? '18:00',
      partySize: Number(params.get('partySize') ?? 2),
      audience: params.get('audience') ?? 'SEVENROOMS_WIDGET',
      manuallyEnterAudience: params.get('manuallyEnterAudience') === 'true',
    }
  }, [])

  const schema = useAdminTimeslotViewForm()
  const { field } = useForm(schema, {
    defaultValues,
    mode: 'onSubmit',
  })

  const [date, time, partySize, audience, manuallyEnterAudience] = useWatchMany(field, [
    'date',
    'time',
    'partySize',
    'audience',
    'manuallyEnterAudience',
  ])

  const doGetTimeslots = useCallback(async () => {
    setTimeslots(await getTimeslots({ venueId, date, time, partySize: partySize ?? 0, audience }).unwrap())

    const params = {
      date: date.toISOString(),
      time,
      partySize,
      audience,
      manuallyEnterAudience,
    }
    window.history.pushState(
      {},
      '',
      `?${Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')}`
    )
  }, [getTimeslots, venueId, date, time, partySize, audience, manuallyEnterAudience])

  return (
    <>
      <AdminPageMeta title={formatMessage(adminTimeslotViewerMessages.title)} />
      <AdminPageContent
        breadcrumbs={
          <Breadcrumbs>
            <Button
              href={nav.href(routes.admin.venueEdit, { params: { venueId } })}
              variant="tertiary"
              target="_self"
              isExternal
              noPadding
              data-test="venue-configuration-breadcrumbs-button"
            >
              {formatMessage(adminTimeslotViewerMessages.venueConfigurationBreadcrumb)}
            </Button>
            <Text>{formatMessage(adminTimeslotViewerMessages.timeslotViewerBreadcrumb)}</Text>
          </Breadcrumbs>
        }
        title={<div dangerouslySetInnerHTML={{ __html: venueName }} />}
      >
        <VStack spacing="m" pt="m">
          <Banner
            title={formatMessage(adminTimeslotViewerMessages.bannerTitle)}
            description={formatMessage(adminTimeslotViewerMessages.bannerDescription)}
            action={formatMessage(adminTimeslotViewerMessages.bannerAction)}
            onAction={() => window.open(`${window.location.origin}/manager2/${venueUrlKey}/availability`, '_blank')}
            type="info"
          />
          <BaseSection title={formatMessage(adminTimeslotViewerMessages.searchForATimeslot)}>
            {audienceHierarchy.data ? (
              <VStack spacing="m" p="m">
                <HStack spacing="m">
                  <Label primary={formatMessage(adminTimeslotViewerMessages.date)} width="33%">
                    <SingleDatePicker field={field.prop('date')} data-test="admin-timeslot-viewer-date-picker" />
                  </Label>
                  <Label primary={formatMessage(adminTimeslotViewerMessages.time)} width="34%">
                    <FormSelect
                      field={field.prop('time')}
                      options={timeSlotOptions}
                      searchable
                      hideEmptyOption
                      data-test="admin-timeslot-viewer-time-select"
                    />
                  </Label>
                  <Label primary={formatMessage(adminTimeslotViewerMessages.partySize)} width="33%">
                    <FormNumberInput field={field.prop('partySize')} data-test="admin-timeslot-viewer-party-size-input" />
                  </Label>
                </HStack>
                <HStack spacing="m">
                  <Label primary={formatMessage(adminTimeslotViewerMessages.audience)} width="50%">
                    {manuallyEnterAudience ? (
                      <FormInput field={field.prop('audience')} fullWidth data-test="admin-timeslot-viewer-audience-input" />
                    ) : (
                      <FormSelect
                        field={field.prop('audience')}
                        options={audienceOptions}
                        searchable
                        hideEmptyOption
                        data-test="admin-timeslot-viewer-audience-select"
                      />
                    )}
                  </Label>
                  <Label primary="&nbsp;" width="50%">
                    <VStack pt="s">
                      <Checkbox field={field.prop('manuallyEnterAudience')} data-test="admin-timeslot-viewer-manually-enter-toggle">
                        {formatMessage(adminTimeslotViewerMessages.manuallyEnterAudience)}
                      </Checkbox>
                    </VStack>
                  </Label>
                </HStack>
                <LoaderButton data-test="admin-timeslot-viewer-search" onClick={doGetTimeslots} loading={isLoading}>
                  {formatMessage(adminTimeslotViewerMessages.search)}
                </LoaderButton>
                {timeslots && (
                  <Text>
                    {timeslots.length === 0
                      ? formatMessage(adminTimeslotViewerMessages.noTimeslotsFoundMessage)
                      : formatMessage(adminTimeslotViewerMessages.timeslotsFoundMessage, { count: timeslots.length })}
                  </Text>
                )}
              </VStack>
            ) : (
              <VStack spacing="m" p="m">
                <Loader />
              </VStack>
            )}
          </BaseSection>
          {timeslots &&
            timeslots.map((timeslot, index) => (
              <BaseSection
                title={formatMessage(adminTimeslotViewerMessages.timeslotTitle, { num: index + 1 })}
                key={`${timeslot.access_persistent_id}-${index}` as string}
              >
                <VStack mt="m">
                  {/* eslint-disable-next-line react/forbid-component-props */}
                  <JsonView data={timeslot} style={defaultStyles} />
                </VStack>
              </BaseSection>
            ))}
        </VStack>
      </AdminPageContent>
    </>
  )
}

function transformAudiences(data: AudienceHierarchy[]): SelectOption[] {
  return data
    .map(node => {
      if (node.children && node.children.length > 0) {
        return transformAudiences(node.children)
      }
      return { label: node.name, id: node.value }
    })
    .flat()
}
