import { defineMessages } from '@sevenrooms/core/locales'

export const paymentsDebuggerMessages = defineMessages({
  googleLogsExplorer: {
    id: 'paymentsDebugger.googleLogsExplorer',
    defaultMessage: 'Google logs explorer',
  },
  primary: {
    id: 'paymentsDebugger.primary',
    defaultMessage: 'Primary',
  },
  secondary: {
    id: 'paymentsDebugger.secondary',
    defaultMessage: 'Secondary',
  },
  consts: {
    id: 'paymentsDebugger.consts',
    defaultMessage: 'Consts',
  },
  nothingFound: {
    id: 'paymentsDebugger.nothingFound',
    defaultMessage: 'Nothing found',
  },
  go: {
    id: 'paymentsDebugger.go',
    defaultMessage: 'Go',
  },
  searchBy: {
    id: 'paymentsDebugger.searchBy',
    defaultMessage: 'Search by',
  },
  searchString: {
    id: 'paymentsDebugger.searchString',
    defaultMessage: 'Search string',
  },
  errorString: {
    id: 'paymentsDebugger.errorString',
    defaultMessage: 'Unexpected error happened, probably on back-end',
  },
  errorStringWithMessage: {
    id: 'paymentsDebugger.errorStringWithMessage',
    defaultMessage: 'Unexpected error happened, probably on back-end: {error}',
  },
  devWarning: {
    id: 'paymentsDebugger.devWarning',
    defaultMessage: "Notice! Urls to google logs explorer won't work correctly on dev env",
  },
  goToLogs: {
    id: 'paymentsDebugger.goToLogs',
    defaultMessage: 'Go to logs',
  },
  possibleIpFilters: {
    id: 'paymentsDebugger.possibleIpFilters',
    defaultMessage: 'Possible IP filters:',
  },
  possibleUrlFilters: {
    id: 'paymentsDebugger.possibleUrlFilters',
    defaultMessage: 'Possible URL filters:',
  },
  actuals: {
    id: 'paymentsDebugger.actuals',
    defaultMessage: 'Actuals',
  },
  invoices: {
    id: 'paymentsDebugger.invoices',
    defaultMessage: 'Invoices',
  },
  historyItems: {
    id: 'paymentsDebugger.historyItems',
    defaultMessage: 'History items',
  },
  httpLogs: {
    id: 'paymentsDebugger.httpLogs',
    defaultMessage: 'HTTP Logs',
  },
  changelog: {
    id: 'paymentsDebugger.changelog',
    defaultMessage: 'Changelog',
  },
  logsExplorer: {
    id: 'paymentsDebugger.logsExplorer',
    defaultMessage: 'Logs explorer',
  },
  httpLogsInfo: {
    id: 'paymentsDebugger.httpLogsInfo',
    defaultMessage:
      'Some HTTP queries happens before the actual is created thus can not be displayed here. ' +
      'Some logs missing the information to properly extract the data from DB. ' +
      'Check the google logs explorer if this section is empty.',
  },
})
