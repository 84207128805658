import { defineMessages } from '@sevenrooms/core/locales'

export const apiAudienceMessages = defineMessages({
  header: {
    id: 'apiAudienceMessages.header',
    defaultMessage: 'Add new Client API/Audience to {entityObjectName}',
  },
  modalHeader: {
    id: 'apiAudienceMessages.modalHeader',
    defaultMessage: 'Add new Client API/Audience',
  },
  addNameLabel: {
    id: 'apiAudienceMessages.addNameLabel',
    defaultMessage: 'Name (Case Sensitive)',
  },
  integrationOwner: {
    id: 'apiAudienceMessages.integrationOwner',
    defaultMessage: 'Integration Owner',
  },
  addTypeLabel: {
    id: 'apiAudienceMessages.addTypeLabel',
    defaultMessage: 'Integration type',
  },
  addTypeIdLabel: {
    id: 'apiAudienceMessages.addTypeIdLabel',
    defaultMessage: 'Integration',
  },
  levelGroup: {
    id: 'apiAudienceMessages.levelGroup',
    defaultMessage: 'Group level',
  },
  levelEntity: {
    id: 'apiAudienceMessages.levelEntity',
    defaultMessage: '{entityName} level',
  },
  dismiss: {
    id: 'apiAudienceMessages.dismiss',
    defaultMessage: 'Dismiss',
  },
  add: {
    id: 'apiAudienceMessages.add',
    defaultMessage: 'Add',
  },
  deleteWarningHeader: {
    id: 'apiAudienceMessages.deleteWarningHeader',
    defaultMessage: 'Warning',
  },
  deleteWarningHeaderSubTitle: {
    id: 'apiAudienceMessages.deleteWarningHeaderSubTitle',
    defaultMessage: 'Do you want to delete the audience?',
  },
  deleteWarning: {
    id: 'apiAudienceMessages.deleteWarning',
    defaultMessage: 'The action is irreversible',
  },
  delete: {
    id: 'apiAudienceMessages.delete',
    defaultMessage: 'Delete',
  },
  edit: {
    id: 'apiAudienceMessages.edit',
    defaultMessage: 'Edit',
  },
  noApiAddOn: {
    id: 'apiAudienceMessages.noApiAddOn',
    defaultMessage:
      'Heads up! This customer DOES NOT have the API Add-On subscription. Only proceed from here if you are creating credentials for a POS integration or custom audience.',
  },
  addNewButton: {
    id: 'apiAudienceMessages.addNewButton',
    defaultMessage: 'Add Client API/Audience',
  },
  baseURL: {
    id: 'apiAudienceMessages.baseURL',
    defaultMessage: 'Base URL',
  },
  tableHeaderName: {
    id: 'apiAudienceMessages.tableHeaderName',
    defaultMessage: 'Name',
  },
  tableHeaderIntegrationType: {
    id: 'apiAudienceMessages.tableHeaderIntegrationType',
    defaultMessage: 'Integration Type',
  },
  tableHeaderLevel: {
    id: 'apiAudienceMessages.tableHeaderLevel',
    defaultMessage: 'Level',
  },
  tableHeaderClientId: {
    id: 'apiAudienceMessages.tableHeaderClientId',
    defaultMessage: 'Client Id',
  },
  tableHeaderClientSecret: {
    id: 'apiAudienceMessages.tableHeaderClientSecret',
    defaultMessage: 'Client Secret',
  },
  tableHeaderActive: {
    id: 'apiAudienceMessages.tableHeaderActive',
    defaultMessage: 'Active',
  },
  tableHeaderWebhook: {
    id: 'apiAudienceMessages.tableHeaderWebhook',
    defaultMessage: 'Web hook',
  },
  copy: {
    id: 'apiAudienceMessages.copy',
    defaultMessage: 'Copy',
  },
})
