import { createApi } from '@reduxjs/toolkit/query/react'
import camelcaseKeys from 'camelcase-keys'
import { srGet, srPostJson, throwFetchError, RTKUtils } from '@sevenrooms/core/api'
import type {
  GetVenueInteractiveFloorplanImagesDataAPI,
  UpdateInteractiveFloorplanImagesAPI,
  VenueInteractiveFloorplanImagesApiResponse,
} from './data'

async function getVenueInteractiveFloorplanImagesData({ venueId }: GetVenueInteractiveFloorplanImagesDataAPI) {
  const { data } = await srGet<VenueInteractiveFloorplanImagesApiResponse>(`/api-yoa/venue/${venueId}/interactive-floorplan-images`).then(
    throwFetchError
  )
  return camelcaseKeys(data.images)
}

async function updateInteractiveFloorplanImages({ venueId, images }: UpdateInteractiveFloorplanImagesAPI) {
  await srPostJson<void>(`/api-yoa/venue/${venueId}/interactive-floorplan-images`, {
    images,
  }).then(throwFetchError)
}

export const useVenueInteractiveFloorplanImages = createApi({
  reducerPath: 'venueInteractiveFloorplanImages',
  baseQuery: RTKUtils.noBaseQuery,
  tagTypes: ['InteractiveFloorplanImages'],
  endpoints: builder => ({
    getVenueInteractiveFloorplanImages: builder.query({
      queryFn: RTKUtils.rtkQuery(getVenueInteractiveFloorplanImagesData),
      providesTags: ['InteractiveFloorplanImages'],
    }),
    updateInteractiveFloorplanImages: builder.mutation({
      invalidatesTags: () => ['InteractiveFloorplanImages'],
      queryFn: RTKUtils.rtkQuery(updateInteractiveFloorplanImages),
    }),
  }),
})

export const { useGetVenueInteractiveFloorplanImagesQuery, useUpdateInteractiveFloorplanImagesMutation } =
  useVenueInteractiveFloorplanImages
