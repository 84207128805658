import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from '@sevenrooms/admin'
import type { AdminAutoTagConfigTier } from '@sevenrooms/core/api'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { IconButton } from '@sevenrooms/core/ui-kit/form'
import {
  Box,
  HStack,
  Loader,
  Spreadsheet,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  type DataTableColumn,
  type Row,
} from '@sevenrooms/core/ui-kit/layout'
import { vxTheme as theme } from '@sevenrooms/core/ui-kit/theme'
import { StatusLabel, Tag } from '@sevenrooms/core/ui-kit/typography'
import { adminAutoTagsMessages } from '../../locales'
import { fetchAutoTagConfig, updateSlideOutState } from './AutoTagSlideOut/autoTagSlideOutSlice'

export interface AdminAutoTagTableRow extends AdminAutoTagConfigTier {
  id: string
}

const compareRows = (rowAValue: string | boolean, rowBValue: string | boolean) => (rowAValue > rowBValue ? 1 : -1)
const tagNameSortFn = (rowA: Row, rowB: Row, columnId: string) => {
  const rowAValue = rowA.values[columnId as string]?.props.children
  const rowBValue = rowB.values[columnId as string]?.props.children
  return compareRows(rowAValue, rowBValue)
}

export function AdminAutoTagTable() {
  const { formatMessage } = useLocales()
  const { rows, isLoading } = useSelector((state: RootState) => state.adminAutoTagTable)
  const dispatch = useDispatch()

  const columns = React.useCallback<(rows: AdminAutoTagTableRow[]) => DataTableColumn<typeof rows[number]>[]>(
    () => [
      {
        header: formatMessage(adminAutoTagsMessages.tableTagNameHeader),
        key: 'tagName',
        render: (row: AdminAutoTagTableRow) => (
          <Tag backgroundColor={theme.colors.defaultTagColor} icon="VMSWeb-refresh">
            {row.tagNameDisplay}
          </Tag>
        ),
        minWidth: 275,
        maxWidth: 275,
        sortable: true,
        sortType: tagNameSortFn,
      },
      {
        header: formatMessage(adminAutoTagsMessages.tableCategoryHeader),
        render: 'category',
        sortable: true,
        sortType: 'string',
        minWidth: 180,
        maxWidth: 180,
      },
      {
        header: formatMessage(adminAutoTagsMessages.tableCreatedByHeader),
        key: 'createdBy',
        render: () => '-',
        sortable: true,
        sortType: 'string',
        minWidth: 125,
        maxWidth: 125,
      },
      {
        header: formatMessage(adminAutoTagsMessages.tableTypeHeader),
        key: 'type',
        render: (row: AdminAutoTagTableRow) =>
          row.autotagConfigIsGlobal ? formatMessage(adminAutoTagsMessages.global) : formatMessage(adminAutoTagsMessages.local),
        sortable: true,
        sortType: 'string',
        minWidth: 125,
        maxWidth: 125,
      },
      {
        header: '',
        key: 'status',
        render: (row: AdminAutoTagTableRow) =>
          row.isActive ? (
            <StatusLabel variant="success">{formatMessage(commonMessages.active)}</StatusLabel>
          ) : (
            <StatusLabel variant="inactive">{formatMessage(commonMessages.inactive)}</StatusLabel>
          ),
        sortable: false,
        minWidth: 70,
        maxWidth: 70,
      },
      {
        header: '',
        cellAlign: 'end',
        key: 'action',
        render: (row: AdminAutoTagTableRow) => (
          <HStack alignItems="center" spacing="sm">
            <IconButton
              borderType="none-round"
              icon="VMSWeb-edit"
              onClick={() => {
                dispatch(fetchAutoTagConfig(row.autotagConfigId))
                dispatch(
                  updateSlideOutState({
                    conditionTree: row.customConfigTreeClient || [],
                    tagDisplayName: row.tagNameDisplay,
                    isShowing: true,
                    tagName: row.tagName,
                  })
                )
              }}
            />
          </HStack>
        ),
        expandable: true,
        minWidth: 75,
        maxWidth: 75,
      },
    ],
    [dispatch, formatMessage]
  )

  return (
    <Box pb="lm">
      {isLoading ? (
        <Loader />
      ) : (
        <Tabs>
          <Box mb="lm">
            <TabList>
              <Tab data-test={adminAutoTagsMessages.tagsTab.id}>
                {formatMessage(adminAutoTagsMessages.tagsTab)} ({rows.length})
              </Tab>
            </TabList>
          </Box>
          <TabPanel>
            <Spreadsheet data={rows} columns={columns(rows)} height={480} />
          </TabPanel>
        </Tabs>
      )}
    </Box>
  )
}
