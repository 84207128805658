import moment from 'moment'
import { useCallback } from 'react'
import { useGetPackageWizardReportsQuery, useStartPackageWizardReportsMutation } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, Box, HStack, notify } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { routes } from '@sevenrooms/routes'
import { adminVenuePackageProvisioningMessages } from '../../locales'

const REPORT_COMPLETED_STATUS = 'COMPLETED'

interface VenueGroupPackageWizardSectionProps {
  venueGroupId: string
  venueGroupName: string
}

export function VenueGroupPackageWizardSection({ venueGroupId, venueGroupName }: VenueGroupPackageWizardSectionProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()

  const { data } = useGetPackageWizardReportsQuery({ venueGroupId })
  const [startPackageWizard, { isUninitialized }] = useStartPackageWizardReportsMutation()

  const onClickStart = useCallback(async () => {
    try {
      await startPackageWizard({
        venueGroupId,
      }).unwrap()
      notify({
        content: formatMessage(adminVenuePackageProvisioningMessages.wizardStartSuccess),
        type: 'success',
      })
    } catch (error) {
      notify({ content: String(error), type: 'error' })
    }
  }, [startPackageWizard, venueGroupId, formatMessage])
  return (
    <Box p="lm">
      <BaseSection
        title={formatMessage(adminVenuePackageProvisioningMessages.wizardTitleVenueGroup, { venueGroupName })}
        description={formatMessage(adminVenuePackageProvisioningMessages.wizardDownloadDescription)}
        actions={
          <Button disabled={!isUninitialized} onClick={onClickStart} data-test="wizard-start-button">
            {formatMessage(adminVenuePackageProvisioningMessages.wizardStartButton)}
          </Button>
        }
      >
        <Box p="lm">
          {data?.reports && !data.reports.length && <Text>{formatMessage(adminVenuePackageProvisioningMessages.wizardNoReports)}</Text>}
          {data?.reports?.map(report => (
            <HStack key={report.id} pt="s" spacing="l" justifyContent="space-between" alignItems="center">
              <Text>{moment(report.started).format('LLL')}</Text>
              <Text>{report.status}</Text>
              <Button
                disabled={report.status !== REPORT_COMPLETED_STATUS}
                href={nav.href(routes.api.packageWizardReportDownload, { params: { reportId: report.id } })}
                isExternal
                data-test="wizard-download-button"
              >
                {formatMessage(adminVenuePackageProvisioningMessages.wizardDownloadButton)}
              </Button>
            </HStack>
          ))}
        </Box>
      </BaseSection>
    </Box>
  )
}
