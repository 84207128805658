import { useMemo } from 'react'
import { useAdminOngoingEmailCampaignTemplateFilterContext } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { MultiSelect, Label } from '@sevenrooms/core/ui-kit/form'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Grid, Box } from '@sevenrooms/core/ui-kit/layout'
import { adminOngoingEmailCampaignTemplateMessages } from '../../locales'

interface AdminOngoingEmailCampaignTemplatesFiltersProps {
  isOrderingCampaignTab?: boolean
}

export function AdminOngoingEmailCampaignTemplatesFilters({ isOrderingCampaignTab }: AdminOngoingEmailCampaignTemplatesFiltersProps) {
  const { formatMessage } = useLocales()
  const isMobile = useMaxWidthBreakpoint('s')
  const { campaignRegion, campaignVenueType, orderingPlatform, setCampaignRegion, setCampaignVenueType, setOrderingPlatform } =
    useAdminOngoingEmailCampaignTemplateFilterContext()

  const regionFilters = useMemo(
    () => [
      {
        id: 'ALL_REGIONS',
        label: formatMessage(adminOngoingEmailCampaignTemplateMessages.allRegions),
      },
      {
        id: 'NORTH_AMERICA',
        label: formatMessage(adminOngoingEmailCampaignTemplateMessages.northAmerica),
      },
      {
        id: 'UK_EUROPE',
        label: formatMessage(adminOngoingEmailCampaignTemplateMessages.europe),
      },
      {
        id: 'ASIA',
        label: formatMessage(adminOngoingEmailCampaignTemplateMessages.asia),
      },
      {
        id: 'AUS_NZ',
        label: formatMessage(adminOngoingEmailCampaignTemplateMessages.australia),
      },
      {
        id: 'MIDDLE_EAST',
        label: formatMessage(adminOngoingEmailCampaignTemplateMessages.middleEast),
      },
      {
        id: 'LATAM',
        label: formatMessage(adminOngoingEmailCampaignTemplateMessages.latinAmerica),
      },
      {
        id: 'AFRICA',
        label: formatMessage(adminOngoingEmailCampaignTemplateMessages.africa),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const venueTypeFilters = useMemo(
    () => [
      {
        id: 'ALL_VENUE_TYPES',
        label: formatMessage(adminOngoingEmailCampaignTemplateMessages.allVenueTypes),
      },
      {
        id: 'DEFAULT',
        label: formatMessage(adminOngoingEmailCampaignTemplateMessages.default),
      },
      {
        id: 'FINE_DINING',
        label: formatMessage(adminOngoingEmailCampaignTemplateMessages.fineDining),
      },
      {
        id: 'CONTEMPORARY',
        label: formatMessage(adminOngoingEmailCampaignTemplateMessages.contemporary),
      },
      {
        id: 'CASUAL',
        label: formatMessage(adminOngoingEmailCampaignTemplateMessages.casual),
      },
      {
        id: 'HOTELS',
        label: formatMessage(adminOngoingEmailCampaignTemplateMessages.hotels),
      },
      {
        id: 'NIGHTLIFE',
        label: formatMessage(adminOngoingEmailCampaignTemplateMessages.nightlife),
      },
      {
        id: 'EATERTAINMENT',
        label: formatMessage(adminOngoingEmailCampaignTemplateMessages.eatertainment),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const orderingPlatformFilters = useMemo(
    () => [
      {
        id: 'ALL_PLATFORMS',
        label: formatMessage(adminOngoingEmailCampaignTemplateMessages.allPlatforms),
      },
      {
        id: 'ORDERING_SEVENROOMS',
        label: formatMessage(adminOngoingEmailCampaignTemplateMessages.sevenrooms),
      },
      {
        id: 'ORDERING_BBOT',
        label: formatMessage(adminOngoingEmailCampaignTemplateMessages.bbot),
      },
      {
        id: 'ORDERING_OLO',
        label: formatMessage(adminOngoingEmailCampaignTemplateMessages.olo),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const renderCampaignTemplateFilter = ({
    label,
    options,
    onChange,
    initialSelectedIds,
  }: {
    label: string
    options: SelectOption[]
    initialSelectedIds: string[]
    onChange: (selectedIds: string[]) => void
  }) => (
    <Box maxWidth="100%" gridColumn={isMobile ? 'auto / span 12' : 'auto / span 4'}>
      <Label primary={label}>
        <MultiSelect
          useCheckboxItems
          usePills={false}
          options={options}
          onChange={onChange}
          displayAsTextWhenCollapsed
          initialSelectedIds={initialSelectedIds}
        />
      </Label>
    </Box>
  )

  return (
    <Box pb="lm">
      <Grid gridTemplateColumns="repeat(12, 1fr)" gap="lm" gridAutoRows="min-content">
        <Box maxWidth="100%" gridColumn="auto / span 9">
          <Grid gridTemplateColumns="repeat(12, 1fr)" columnGap="s" rowGap="lm" gridAutoRows="min-content">
            {renderCampaignTemplateFilter({
              options: regionFilters,
              onChange: setCampaignRegion,
              initialSelectedIds: campaignRegion,
              label: formatMessage(adminOngoingEmailCampaignTemplateMessages.regionFilter),
            })}
            {renderCampaignTemplateFilter({
              options: venueTypeFilters,
              onChange: setCampaignVenueType,
              initialSelectedIds: campaignVenueType,
              label: formatMessage(adminOngoingEmailCampaignTemplateMessages.venueTypeFilter),
            })}
            {isOrderingCampaignTab &&
              renderCampaignTemplateFilter({
                onChange: setOrderingPlatform,
                options: orderingPlatformFilters,
                initialSelectedIds: orderingPlatform,
                label: formatMessage(adminOngoingEmailCampaignTemplateMessages.orderingPlatform),
              })}
          </Grid>
        </Box>
      </Grid>
    </Box>
  )
}
