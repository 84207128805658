/* eslint-disable import/no-cycle */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { AdminOngoingEmailCampaignTemplatesTableRow } from './AdminOngoingEmailCampaignTemplatesTable'

export interface AdminOngoingEmailCampaignTemplatesTableState {
  rows: AdminOngoingEmailCampaignTemplatesTableRow[]
}

const initialState: AdminOngoingEmailCampaignTemplatesTableState = {
  rows: [],
}

const adminOngoingEmailCampaignTemplatesTableSlice = createSlice({
  name: 'admin.ongoingEmailCampaignTemplatesTable',
  initialState,
  reducers: {
    setAdminOngoingEmailCampaignTemplatesTableRows: (state, action: PayloadAction<AdminOngoingEmailCampaignTemplatesTableRow[]>) => {
      state.rows = action.payload
    },
    removeAdminOngoingEmailCampaignTemplatesTableRowByIndex: (state, action: PayloadAction<number>) => {
      state.rows.splice(action.payload, 1)
    },
    setUpdatesLiveStatus: (state, action: PayloadAction<string>) => {
      state.rows = state.rows.map(row => {
        if (row.id !== action.payload) {
          return row
        }

        return {
          ...row,
          hasNewUpdates: false,
        }
      })
    },
  },
})

const { actions } = adminOngoingEmailCampaignTemplatesTableSlice
export const {
  setAdminOngoingEmailCampaignTemplatesTableRows,
  removeAdminOngoingEmailCampaignTemplatesTableRowByIndex,
  setUpdatesLiveStatus,
} = actions
export const { reducer: adminEmailCampaignTemplatesTableSliceReducer, name: adminEmailCampaignTemplatesTableSliceName } =
  adminOngoingEmailCampaignTemplatesTableSlice
