import { useGetVenueHistoryTrackingDataQuery } from '@sevenrooms/core/api'
import { Loader } from '@sevenrooms/core/ui-kit/layout'
import { HistoryTrackingForm } from './HistoryTrackingForm'

interface VenueHistoryTrackingProps {
  venueId: string
  venueName: string
}
export function VenueHistoryTracking({ venueId, venueName }: VenueHistoryTrackingProps) {
  const { data, isLoading } = useGetVenueHistoryTrackingDataQuery({ venueId })
  return (
    <>
      {isLoading && <Loader />}
      {data && <HistoryTrackingForm history={data} venueId={venueId} venueName={venueName} />}
    </>
  )
}
