import { useCallback } from 'react'
import { type Field, useController } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FileDropZone, type FormImageObject, type FileInlineProps, FileInline } from '@sevenrooms/core/ui-kit/form'
import { Checker, Clickable, Flex, PreviewImage, Box } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { venueInteractiveFloorplanImagesMessages } from './locales'

interface FileUploaderProps {
  field: Field<FormImageObject | null>
  removeImage: () => void
  addImage: () => void
}
export function FileUploader({ field, removeImage, addImage }: FileUploaderProps) {
  const controller = useController(field)
  const onAddFile = useCallback<Required<FileInlineProps>['onChangeFile']>(
    acceptedFiles => {
      if (acceptedFiles[0]) {
        if (controller.field.value === null) {
          addImage()
        }
        controller.field.onChange({
          name: acceptedFiles[0].name,
          fileData: acceptedFiles[0],
          rawUrl: URL.createObjectURL(acceptedFiles[0]),
        })
      }
    },
    [addImage, controller.field]
  )
  const MAX_SIZE = 10 * 1000 * 1000
  const { formatMessage } = useLocales()
  return (
    <Box width="300px">
      <FileInline
        accept="image/svg+xml"
        maxSize={MAX_SIZE}
        name={controller?.field.name}
        fileName={controller?.field.value?.name || ''}
        replaceText=""
        onChangeFile={onAddFile}
        onBlur={controller?.field.onBlur}
        hasReplace
        onDelete={removeImage}
        deleteText=""
      />
      <Flex borderColor="borders" borderBottomLeftRadius="s" borderBottomRightRadius="s" justifyContent="center">
        {controller.field.value ? (
          <Checker>
            <PreviewImage maxHeight="212px" alt={controller.field.value.name} src={controller.field.value.rawUrl} />
          </Checker>
        ) : (
          <FileDropZone
            accept="image/svg+xml"
            maxSize={MAX_SIZE}
            caption={
              <Clickable cursor="pointer">
                {formatMessage(venueInteractiveFloorplanImagesMessages.imageUploadCaption, {
                  span: (chunks: never) => (
                    <Text cursor="pointer" color="primaryButton">
                      {chunks}
                    </Text>
                  ),
                })}
              </Clickable>
            }
            onDrop={onAddFile}
          />
        )}
      </Flex>
    </Box>
  )
}
