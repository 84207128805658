import { render } from 'react-dom'
import { AdminPage } from '@sevenrooms/admin'
import { svrExport } from '@sevenrooms/core/ui-kit/utils'
import { VenueInteractiveFloorplanImages } from './VenueInteractiveFloorplanImages'

function renderVenueInteractiveFloorplanImages(id: string, venueId: string, venueName: string, venueUrlKey: string) {
  return render(
    <AdminPage>
      <VenueInteractiveFloorplanImages venueId={venueId} venueName={venueName} venueUrlKey={venueUrlKey} />
    </AdminPage>,
    document.getElementById(id)
  )
}

svrExport('InteractiveFloorplanImages', 'render', renderVenueInteractiveFloorplanImages)
