import { FormInput } from '@sevenrooms/core/ui-kit/form'
import { type FluidControlProps, FluidControl } from './FluidControl'

interface FluidInputProps extends FluidControlProps<string | null> {
  placeholder?: string
  disabled?: boolean
}

export function FluidInput({ placeholder, disabled, ...props }: FluidInputProps) {
  return (
    <FluidControl {...props}>
      <FormInput field={props.field} placeholder={placeholder} disabled={disabled} readOnly={disabled} />
    </FluidControl>
  )
}
