import { render } from 'react-dom'
import { AdminPage } from '@sevenrooms/admin'
import { Switch, Route } from '@sevenrooms/core/navigation'
import { svrExport } from '@sevenrooms/core/ui-kit/utils'
import { routes } from '@sevenrooms/routes'
import { AdminOneTimeEmailCampaignTemplates, AdminOneTimeEmailCampaignTemplate } from './components'

function renderAdminOneTimeEmailCampaignTemplates(el: HTMLElement) {
  return render(
    <AdminPage>
      <Switch>
        <Route path={routes.admin.oneTimeEmailCampaignsTemplates.create.path}>
          <AdminOneTimeEmailCampaignTemplate />
        </Route>

        <Route path={routes.admin.oneTimeEmailCampaignsTemplates.duplicate.path}>
          <AdminOneTimeEmailCampaignTemplate />
        </Route>

        <Route path={routes.admin.oneTimeEmailCampaignsTemplates.edit.path}>
          <AdminOneTimeEmailCampaignTemplate />
        </Route>

        <Route path={routes.admin.oneTimeEmailCampaignsTemplates.path}>
          <AdminOneTimeEmailCampaignTemplates />
        </Route>
      </Switch>
    </AdminPage>,
    el
  )
}

svrExport('AdminOneTimeEmailCampaignTemplates', 'render', renderAdminOneTimeEmailCampaignTemplates)
