import { useMemo } from 'react'
import type { OngoingEmailCampaignTemplate } from '@sevenrooms/core/api'
import { OngoingEmailCampaignStatusEnum } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { getCharacterCount, useAudienceForm, getAdminAudienceDefaultValues } from '@sevenrooms/marketing'
import {
  useOngoingSendingScheduleForm,
  getOngoingSendingScheduleDefaultValues,
} from '@sevenrooms/mgr-marketing-ongoing-email-center/components'
import { adminOngoingEmailCampaignTemplateMessages } from '../../locales'
import { getAdminEmailContentDefaultValues, useAdminEmailContentForm } from '../AdminEmailContent'
import { getAdminEmailDetailsDefaultValues, useAdminEmailDetailsForm } from '../AdminEmailDetails'

export type AdminOngoingEmailCampaignTemplateFormData = ZodSchema<typeof useAdminOngoingEmailCampaignTemplateForm>
export type AdminOngoingEmailCampaignTemplateFormField = Field<AdminOngoingEmailCampaignTemplateFormData>

export function useAdminOngoingEmailCampaignTemplateForm() {
  const { formatMessage } = useLocales()
  const requiredMessage = formatMessage(commonMessages.required)
  const maxCharCountExceededMessage = formatMessage(adminOngoingEmailCampaignTemplateMessages.maxCharacterCountExceeded)
  const sendingSchedule = useOngoingSendingScheduleForm()
  const audience = useAudienceForm()
  const emailDetails = useAdminEmailDetailsForm()
  const emailContent = useAdminEmailContentForm()

  return useMemo(
    () =>
      z
        .object({
          status: z.nativeEnum(OngoingEmailCampaignStatusEnum),
        })
        .merge(emailDetails)
        .merge(audience)
        .merge(sendingSchedule)
        .merge(emailContent)
        .superRefine((formData, ctx) => {
          if (!formData.campaignName) {
            ctx.addIssue({
              code: z.ZodIssueCode.invalid_type,
              expected: 'string',
              received: 'null',
              path: ['campaignName'],
              message: requiredMessage,
            })
          }
          // Validate required fields if template is published
          if ([OngoingEmailCampaignStatusEnum.ACTIVE, OngoingEmailCampaignStatusEnum.INACTIVE].includes(formData.status)) {
            if (!formData.emailReplyTo) {
              ctx.addIssue({
                code: z.ZodIssueCode.invalid_type,
                expected: 'string',
                received: 'null',
                path: ['emailReplyTo'],
                message: requiredMessage,
              })
            }
            if (!formData.campaignProductType) {
              ctx.addIssue({
                code: z.ZodIssueCode.invalid_type,
                expected: 'string',
                received: 'null',
                path: ['campaignProductType'],
                message: requiredMessage,
              })
            }
            if (!formData.campaignCategory) {
              ctx.addIssue({
                code: z.ZodIssueCode.invalid_type,
                expected: 'string',
                received: 'null',
                path: ['campaignCategory'],
                message: requiredMessage,
              })
            }
            if (!formData.campaignVenueType) {
              ctx.addIssue({
                code: z.ZodIssueCode.invalid_type,
                expected: 'string',
                received: 'null',
                path: ['campaignVenueType'],
                message: requiredMessage,
              })
            }
            if (!formData.campaignRegion) {
              ctx.addIssue({
                code: z.ZodIssueCode.invalid_type,
                expected: 'string',
                received: 'null',
                path: ['campaignRegion'],
                message: requiredMessage,
              })
            }
            if (!formData.recipientAutotags || formData.recipientAutotags.length < 1) {
              ctx.addIssue({
                code: z.ZodIssueCode.too_small,
                type: 'array',
                minimum: 1,
                inclusive: true,
                path: ['recipientAutotags'],
                message: requiredMessage,
              })
            }
            if (!formData.emailSender.value) {
              ctx.addIssue({
                code: z.ZodIssueCode.invalid_type,
                expected: 'string',
                received: 'null',
                path: ['emailSender.value'],
                message: requiredMessage,
              })
            }
            if (!formData.emailSubject.value) {
              ctx.addIssue({
                code: z.ZodIssueCode.invalid_type,
                expected: 'string',
                received: 'null',
                path: ['emailSubject.value'],
                message: requiredMessage,
              })
            }
            formData.emailBodySections.forEach((bodySection, index) => {
              if (!bodySection.value) {
                ctx.addIssue({
                  code: z.ZodIssueCode.invalid_type,
                  expected: 'string',
                  received: 'null',
                  path: [`emailBodySections.${index}.value`],
                  message: requiredMessage,
                })
              }
            })
          }

          if (formData.emailSender.maxLen && getCharacterCount(formData.emailSender.value ?? '') > formData.emailSender.maxLen) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: maxCharCountExceededMessage,
              path: ['emailSender.value'],
            })
          }
          if (formData.emailSubject.maxLen && getCharacterCount(formData.emailSubject.value ?? '') > formData.emailSubject.maxLen) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: maxCharCountExceededMessage,
              path: ['emailSubject.value'],
            })
          }
          if (formData.previewText.maxLen && getCharacterCount(formData.previewText.value ?? '') > formData.previewText.maxLen) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: maxCharCountExceededMessage,
              path: ['previewText.value'],
            })
          }
          if (formData.greeting.maxLen && getCharacterCount(formData.greeting.value ?? '') > formData.greeting.maxLen) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: maxCharCountExceededMessage,
              path: ['greeting.value'],
            })
          }
          formData.emailBodySections.forEach((bodySection, index) => {
            if (bodySection.maxLen && getCharacterCount(bodySection.value ?? '') > bodySection.maxLen) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: [`emailBodySections.${index}.value`],
                message: maxCharCountExceededMessage,
              })
            }
          })
          if (formData.signature.maxLen && getCharacterCount(formData.signature.value ?? '') > formData.signature.maxLen) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: maxCharCountExceededMessage,
              path: ['signature.value'],
            })
          }
          if (formData.signoff.maxLen && getCharacterCount(formData.signoff.value ?? '') > formData.signoff.maxLen) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: maxCharCountExceededMessage,
              path: ['signoff.value'],
            })
          }
          if (formData.footer.maxLen && getCharacterCount(formData.footer.value ?? '') > formData.footer.maxLen) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: maxCharCountExceededMessage,
              path: ['footer.value'],
            })
          }
        }),
    [maxCharCountExceededMessage, requiredMessage, audience, sendingSchedule, emailDetails, emailContent]
  )
}

export const getAdminOngoingEmailCampaignTemplateFormDefaultValues = (
  emailCampaignTemplate?: OngoingEmailCampaignTemplate
): AdminOngoingEmailCampaignTemplateFormData => ({
  status: emailCampaignTemplate?.status ?? OngoingEmailCampaignStatusEnum.INACTIVE,
  ...getAdminEmailDetailsDefaultValues(emailCampaignTemplate),
  ...getAdminAudienceDefaultValues(emailCampaignTemplate),
  ...getOngoingSendingScheduleDefaultValues(emailCampaignTemplate),
  ...getAdminEmailContentDefaultValues(emailCampaignTemplate),
})
