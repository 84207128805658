import { useMemo } from 'react'
import { z, type ZodSchema } from '@sevenrooms/core/form'

export type AdminTimeslotViewerForm = ZodSchema<typeof useAdminTimeslotViewForm>

export function useAdminTimeslotViewForm() {
  return useMemo(
    () =>
      z.object({
        date: z.date(),
        time: z.string().min(1),
        partySize: z.number().min(1).nullable(),
        audience: z.string().min(1),
        manuallyEnterAudience: z.boolean(),
      }),
    []
  )
}
