import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useMemo } from 'react'
import { useGetAdminEmailCampaignTemplateQuery, type OngoingEmailCampaignTemplate } from '@sevenrooms/core/api'
import { EmailCampaignStatusEnum } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useParams } from '@sevenrooms/core/navigation'
import { Loader } from '@sevenrooms/core/ui-kit/layout'
import { adminOngoingEmailCampaignTemplateMessages } from '../../locales'
import { AdminOngoingEmailCampaignTemplateForm } from './AdminOngoingEmailCampaignTemplateForm'

interface AdminOngoingEmailCampaignTemplateProps {
  isEdit?: boolean
  isDuplicate?: boolean
}

export function AdminOngoingEmailCampaignTemplate({ isEdit, isDuplicate }: AdminOngoingEmailCampaignTemplateProps) {
  const { formatMessage } = useLocales()
  const { templateId: emailCampaignTemplateId } = useParams<{ templateId: string }>()
  const { data: fetchedEmailCampaignTemplate, isLoading } = useGetAdminEmailCampaignTemplateQuery(
    emailCampaignTemplateId ? { emailCampaignTemplateId } : skipToken
  )
  const emailCampaignTemplate = useMemo(() => {
    if (isDuplicate && fetchedEmailCampaignTemplate) {
      return {
        ...fetchedEmailCampaignTemplate,
        campaignName: `${fetchedEmailCampaignTemplate.campaignName} (${formatMessage(adminOngoingEmailCampaignTemplateMessages.copy)})`,
        status: EmailCampaignStatusEnum.INACTIVE,
      }
    }
    return fetchedEmailCampaignTemplate
  }, [isDuplicate, fetchedEmailCampaignTemplate, formatMessage])

  return isLoading ? (
    <Loader />
  ) : (
    <AdminOngoingEmailCampaignTemplateForm
      emailCampaignTemplate={emailCampaignTemplate as OngoingEmailCampaignTemplate}
      isEdit={isEdit}
      isDuplicate={isDuplicate}
    />
  )
}
