import { format } from 'date-fns-tz'
import { srGet, throwFetchError } from '@sevenrooms/core/api'

export interface GetTimeslotsParams {
  venueId: string
  date: Date
  time: string
  partySize: number
  audience: string
}

export interface GetTimeslotsResponse {
  timeslots: Record<string, unknown>[]
}

export namespace TimeslotViewerService {
  export async function getTimeslots(params: GetTimeslotsParams) {
    const { data } = await srGet<GetTimeslotsResponse>(`/api-yoa/admin/timeslot_viewer`, {
      venue_id: params.venueId,
      date: format(params.date, 'yyyy-MM-dd'),
      time: params.time,
      party_size: params.partySize,
      audience: params.audience,
    }).then(throwFetchError)
    return data.timeslots
  }
}
