import { render } from 'react-dom'
import { AdminPage } from '@sevenrooms/admin'
import { svrExport } from '@sevenrooms/core/ui-kit/utils'
import { ApiIntegrationTypesManager } from './components/ApiIntegrationTypesManager'

function renderApiIntegrationTypes(elementId: string) {
  return render(
    <AdminPage>
      <ApiIntegrationTypesManager />
    </AdminPage>,
    document.getElementById(elementId)
  )
}

svrExport('ApiIntegrationTypes', 'render', renderApiIntegrationTypes)
