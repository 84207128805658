import { Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@sevenrooms/core/ui-kit/layout'

export interface PhoneNumberModalProps {
  titleText: string
  bodyText: string
  onClose: () => void
  actions?: React.ReactNode
}

export function PhoneNumberModal({ titleText, bodyText, onClose, actions }: PhoneNumberModalProps) {
  return (
    <Modal ariaLabel="Modal">
      <ModalHeader onClose={onClose}>
        <ModalTitle title={titleText} />
      </ModalHeader>
      <ModalBody>{bodyText}</ModalBody>
      <ModalFooter>
        <ModalActions>{actions}</ModalActions>
      </ModalFooter>
    </Modal>
  )
}
