import { createApi } from '@reduxjs/toolkit/query/react'
import { RTKUtils } from '@sevenrooms/core/api'
import { BulkUpdateSettingsService } from '../services'
import type { BulkUpdateSettingsData, BulkUpdateSettingsRequest } from '../domain'

export const bulkUpdateSettingsApi = createApi({
  reducerPath: 'admin.bulkUpdateSettings',
  tagTypes: ['BulkUpdateSettingsData'],
  baseQuery: RTKUtils.noBaseQuery,
  endpoints: builder => ({
    getBulkUpdateSettingsData: builder.query<BulkUpdateSettingsData, null>({
      providesTags: ['BulkUpdateSettingsData'],
      queryFn: RTKUtils.rtkQuery(BulkUpdateSettingsService.getBulkUpdateSettingsData),
    }),
    bulkUpdateSettings: builder.mutation<boolean, BulkUpdateSettingsRequest>({
      queryFn: RTKUtils.rtkQuery(BulkUpdateSettingsService.saveBulkUpdateSettings),
    }),
  }),
})

export const { useGetBulkUpdateSettingsDataQuery, useBulkUpdateSettingsMutation } = bulkUpdateSettingsApi
