import { useGetVenueGroupHistoryTrackingDataQuery } from '@sevenrooms/core/api'
import { Loader } from '@sevenrooms/core/ui-kit/layout'
import { VenueGroupHistoryTrackingForm } from './VenueGroupHistoryTrackingForm'

interface VenueGroupHistoryTrackingProps {
  venueGroupId: string
  venueGroupName: string
}
export function VenueGroupHistoryTracking({ venueGroupId, venueGroupName }: VenueGroupHistoryTrackingProps) {
  const { data, isLoading } = useGetVenueGroupHistoryTrackingDataQuery({ venueGroupId })
  return (
    <>
      {isLoading && <Loader />}
      {data && <VenueGroupHistoryTrackingForm history={data} venueGroupId={venueGroupId} venueGroupName={venueGroupName} />}
    </>
  )
}
