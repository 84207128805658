/* eslint newline-per-chained-call: 0 */
/* this is a catch-22 in this file -- it warns about wanting a new line ahead of ".default" but then has a linter error when you do so */
import { ParseError, parsePhoneNumber } from 'libphonenumber-js'
import { useMemo } from 'react'
import { SMSPhonePurpose, SMSProvider, TollFreeVerificationStatus, TwilioAutocreateStatus, TwilioPhoneType } from '@sevenrooms/core/api'
import type { CountryCode } from '@sevenrooms/core/domain'
import { z, type ZodSchema } from '@sevenrooms/core/form'

export type TollFreeVerificationFormSchema = ZodSchema<typeof TollFreeVerificationForm>
export type PhoneNumberFormSchema = ZodSchema<typeof usePhoneNumberForm>
export type VenuePhoneNumbersForm = ZodSchema<typeof useVenuePhoneNumbersForm>

const DEFAULT_SMS_PROVIDER = SMSProvider.TWILIO
const DEFAULT_PURPOSE = SMSPhonePurpose.INACTIVE
const DEFAULT_TWILIO_PHONE_TYPE = TwilioPhoneType.TOLL_FREE

export const US_CA_COUNTRY_CODES: CountryCode[] = ['us', 'ca']

export const isAutocreated = (number: { twilioAutocreatedStatus: TwilioAutocreateStatus | null }): boolean =>
  number.twilioAutocreatedStatus === TwilioAutocreateStatus.AUTOCREATE_COMPLETE ||
  number.twilioAutocreatedStatus === TwilioAutocreateStatus.AUTOCREATE_PENDING

function validateLongcodeNumber(smsPhoneNumber: string, ctx: z.RefinementCtx): string {
  try {
    const pn = parsePhoneNumber(smsPhoneNumber)
    if (!pn.isValid()) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid number',
        path: [`smsPhoneNumber`],
      })
      return smsPhoneNumber
    }
    return pn.format('E.164')
  } catch (err) {
    return smsPhoneNumber
  }
}

export const TollFreeVerificationForm = z.object({
  /* Toll Free Verification fields */
  businessStreetAddress: z.string().trim().min(1).nullable().default(null),
  businessCity: z.string().trim().min(1).nullable().default(null),
  businessStateProvinceRegion: z.string().trim().min(1).nullable().default(null),
  businessPostalCode: z.string().trim().min(1).nullable().default(null),
  businessCountry: z.string().trim().min(1).nullable().default(null),
  businessContactFirstName: z.string().trim().min(1).nullable().default(null),
  businessContactLastName: z.string().trim().min(1).nullable().default(null),
  businessContactEmail: z.string().trim().min(1).email().nullable().default(null),
  businessContactPhone: z.string().trim().min(1).transform(validateLongcodeNumber).nullable().default(null),
  businessName: z.string().trim().min(1).nullable().default(null),
  businessWebsite: z.string().trim().min(1).url().nullable().default(null),
  notificationEmail: z.string().trim().min(1).email().nullable().default(null),
})

const StatusInfo = z.object({
  status: z.nativeEnum(TollFreeVerificationStatus),
  description: z.string().optional(),
  updatedAt: z.date(),
})

export type StatusInfoType = ZodSchema<typeof StatusInfo>

const PhoneNumberFields = z.object({
  id: z.string().nullable().default(null),
  twilioMessagingCampaignId: z.string().nullable().default(null),
  provider: z.nativeEnum(SMSProvider),
  purpose: z.nativeEnum(SMSPhonePurpose),
  smsPhoneNumber: z.string().trim().min(1).nullable().default(null),
  allowForceSendAsMms: z.boolean().default(true),
  smsProviderUsername: z.string().min(1).nullable().default(null),
  smsProviderPassword: z.string().min(1).nullable().default(null),
  twilioAccountSid: z.string().nullable().default(null), // these are sometimes empty strings from the server, which we don't want to mutate
  twilioAuthToken: z.string().nullable().default(null), // these are sometimes empty strings from the server, which we don't want to mutate
  twilioAutocreate: z.boolean().default(false),
  twilioAutocreatedStatus: z.nativeEnum(TwilioAutocreateStatus).nullable().default(null),
  twilioPhoneType: z.nativeEnum(TwilioPhoneType).nullable().default(null),
  tollFreeVerification: TollFreeVerificationForm,
  statusInfo: StatusInfo.nullable(),
  isWhatsappRegistrationComplete: z.boolean().nullable().default(null),
})
type PhoneNumberSchema = ZodSchema<typeof PhoneNumberFields>
const VALID_ALPHANUMERIC_PHONE_REGEX = /^[a-z0-9 +&_-]{1,11}$/i
const usCAOnlyPhoneTypeSet = new Set([TwilioPhoneType.TOLL_FREE, TwilioPhoneType.A2P])
const numericLongcodePhoneTypeSet = new Set([TwilioPhoneType.TOLL_FREE, TwilioPhoneType.A2P, TwilioPhoneType.INTERNATIONAL])

// TO DO GET THESE FROM BE
export function usePhoneNumberForm() {
  return useMemo(
    () =>
      PhoneNumberFields.transform((data: PhoneNumberSchema, ctx): PhoneNumberSchema => {
        if (data.smsPhoneNumber === null || !numericLongcodePhoneTypeSet || !data.twilioPhoneType) {
          return data
        }
        if (data.provider === SMSProvider.TWILIO && numericLongcodePhoneTypeSet.has(data.twilioPhoneType)) {
          return { ...data, smsPhoneNumber: validateLongcodeNumber(data.smsPhoneNumber, ctx) }
        }

        return data
      }).superRefine((data: PhoneNumberSchema, ctx) => {
        const {
          id,
          twilioPhoneType,
          smsPhoneNumber,
          provider,
          twilioAutocreate,
          tollFreeVerification,
          smsProviderUsername,
          smsProviderPassword,
          twilioAccountSid,
          twilioAuthToken,
          statusInfo,
          purpose,
        } = data
        if (id) {
          if (
            (statusInfo?.status === TollFreeVerificationStatus.READY_FOR_VERIFICATION ||
              statusInfo?.status === TollFreeVerificationStatus.PENDING) &&
            purpose === SMSPhonePurpose.INACTIVE
          ) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Phone numbers in the process of being verified cannot be deactivated.',
              path: [`purpose`],
              fatal: true,
            })
          }
          return data
        }
        if (provider === SMSProvider.SMS_GLOBAL) {
          if (!smsPhoneNumber) {
            ctx.addIssue({ code: z.ZodIssueCode.custom, message: 'Required', path: [`smsPhoneNumber`], fatal: true })
          }
          if (!smsProviderUsername) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Required',
              path: [`smsProviderUsername`],
              fatal: true,
            })
          }
          if (!smsProviderPassword) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Required',
              path: [`smsProviderPassword`],
              fatal: true,
            })
          }
          return data
        }

        if (!twilioPhoneType) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'You must select a phone type when using Twilio',
            path: [`twilioPhoneType`],
            fatal: true,
          })
          return data
        }
        if (twilioAutocreate) {
          if (twilioPhoneType === TwilioPhoneType.TOLL_FREE) {
            if (purpose === SMSPhonePurpose.INACTIVE) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Auto-created toll-free numbers must have a purpose',
                path: [`purpose`],
                fatal: true,
              })
            }
            for (const [k, v] of Object.entries(tollFreeVerification)) {
              if (!v) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: 'Required to auto-create toll-free numbers',
                  path: [`tollFreeVerification.${k}`],
                })
              }
            }
            return data
          }
        }
        if (!twilioAccountSid) {
          ctx.addIssue({ code: z.ZodIssueCode.custom, message: 'Required', path: [`twilioAccountSid`], fatal: true })
        }
        if (!twilioAuthToken) {
          ctx.addIssue({ code: z.ZodIssueCode.custom, message: 'Required', path: [`twilioAuthToken`], fatal: true })
        }
        for (const [k, v] of Object.entries(tollFreeVerification)) {
          if (v) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Invalid field',
              path: [`tollFreeVerification.${k}`],
            })
          }
        }
        if (!smsPhoneNumber) {
          ctx.addIssue({ code: z.ZodIssueCode.custom, message: 'Required', path: [`smsPhoneNumber`], fatal: true })
          return data
        }

        if (!usCAOnlyPhoneTypeSet || !numericLongcodePhoneTypeSet) {
          return data
        }
        if (numericLongcodePhoneTypeSet.has(twilioPhoneType)) {
          let pn
          try {
            pn = parsePhoneNumber(smsPhoneNumber)
          } catch (err) {
            if (err instanceof ParseError && err.message !== 'INVALID_COUNTRY') {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Invalid number',
                path: [`smsPhoneNumber`],
              })
            }
            return data
          }
          const country = (pn.country?.toLowerCase() as CountryCode) || ''
          if (!pn.isValid()) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Invalid number',
              path: [`smsPhoneNumber`],
            })
          }
          if (usCAOnlyPhoneTypeSet.has(twilioPhoneType) && (!country || !US_CA_COUNTRY_CODES.includes(country))) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Phone number is not a valid US or Canadian number',
              path: [`smsPhoneNumber`],
            })
          } else if (twilioPhoneType === TwilioPhoneType.INTERNATIONAL && (!country || US_CA_COUNTRY_CODES.includes(country))) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Phone number intended for international usage, US/Canadian phone numbers are not valid',
              path: [`smsPhoneNumber`],
            })
          }
          return data
        }

        if (!VALID_ALPHANUMERIC_PHONE_REGEX.test(smsPhoneNumber)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Phone number is not a valid alphanumeric format',
            path: [`smsPhoneNumber`],
          })
        }
        return data
      }),
    []
  )
}

export function useVenuePhoneNumbersForm() {
  const phoneNumberSchema = usePhoneNumberForm()
  return useMemo(
    () =>
      z.object({
        phoneNumbers: z.array(phoneNumberSchema),
      }),
    [phoneNumberSchema]
  )
}

export const tollFreeDefaults: TollFreeVerificationFormSchema = {
  businessStreetAddress: null,
  businessCity: null,
  businessStateProvinceRegion: null,
  businessPostalCode: null,
  businessCountry: null,
  businessContactFirstName: null,
  businessContactLastName: null,
  businessContactEmail: null,
  businessContactPhone: null,
  businessName: null,
  businessWebsite: null,
  notificationEmail: null,
}

export const newPhoneNumberDefaults: PhoneNumberFormSchema = {
  id: null,
  twilioMessagingCampaignId: null,
  provider: DEFAULT_SMS_PROVIDER,
  purpose: DEFAULT_PURPOSE,
  smsPhoneNumber: null,
  allowForceSendAsMms: true,
  smsProviderUsername: null,
  smsProviderPassword: null,
  twilioAccountSid: null,
  twilioAuthToken: null,
  twilioAutocreate: false,
  twilioAutocreatedStatus: null,
  twilioPhoneType: DEFAULT_TWILIO_PHONE_TYPE,
  tollFreeVerification: tollFreeDefaults,
  statusInfo: null,
  isWhatsappRegistrationComplete: null,
}
