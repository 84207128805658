import { useDispatch } from 'react-redux'
import type { AutoTagTierAttributeType } from '@sevenrooms/core/domain'
import { z, useForm } from '@sevenrooms/core/form'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { FormSelect } from '@sevenrooms/core/ui-kit/form'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { updateTierConfigAttribute } from '../../autoTagSlideOutSlice'

export function DefaultAutoTagSelect({
  index,
  attribute,
  initialValue,
  options,
}: {
  index: number
  attribute: AutoTagTierAttributeType
  initialValue?: string
  options: SelectOption[]
}) {
  const dispatch = useDispatch()

  const formValues = z.object({
    value: z.string(),
  })
  const form = useForm(formValues)
  const { field } = form

  const updateStore = (value: string) => dispatch(updateTierConfigAttribute({ index, attribute, value }))

  if (initialValue === undefined && options[0]) {
    dispatch(updateStore(options[0].id))
  }

  form.setValue('value', options[0] ? options[0].id : '')

  return (
    <Box width="300px" display="inline-block">
      <FormSelect
        options={options}
        field={field.prop('value')}
        onChange={selected => {
          if (!selected || selected === initialValue) {
            return
          }
          updateStore(selected)
        }}
      />
    </Box>
  )
}
