import { useCallback } from 'react'
import type { EmailCampaignTemplate } from '@sevenrooms/core/api'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { VStack, HStack, Box, Modal, ModalHeader, ModalTitle, ModalFooter, ModalActions } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { adminOneTimeEmailCampaignTemplatesMessages } from '../../locales'

export interface AdminOneTimeEmailCampaignTemplateSaveDeactivateModalProps {
  closeHref: string
  emailCampaignTemplate: EmailCampaignTemplate
  onSaveDeactivate: (emailCampaignTemplate: EmailCampaignTemplate) => void
  onDiscardDeactivate: (emailCampaignTemplate: EmailCampaignTemplate) => void
  isLoading: boolean
}

export function AdminOneTimeEmailCampaignTemplateSaveDeactivateModal({
  emailCampaignTemplate,
  onSaveDeactivate,
  onDiscardDeactivate,
  closeHref,
  isLoading,
}: AdminOneTimeEmailCampaignTemplateSaveDeactivateModalProps) {
  const { formatMessage } = useLocales()

  const onDiscardDeactivateConfirm = useCallback(() => {
    onDiscardDeactivate(emailCampaignTemplate)
  }, [emailCampaignTemplate, onDiscardDeactivate])

  const onSaveDeactivateConfirm = useCallback(() => {
    onSaveDeactivate(emailCampaignTemplate)
  }, [emailCampaignTemplate, onSaveDeactivate])

  return (
    <Modal data-test="save-deactivate-modal" ariaLabel={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.saveDeactivateModalTitle)}>
      <ModalHeader closeHref={closeHref}>
        <VStack spacing="s">
          <HStack spacing="s">
            <Box>
              <Icon name="VMSWeb-warning" color="error" size="lg" />
            </Box>
            <Text color="error" fontWeight="bold">
              {formatMessage(commonMessages.unsavedChanges)}
            </Text>
          </HStack>
          <ModalTitle title={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.saveDeactivateModalTitle)} />
        </VStack>
      </ModalHeader>

      <ModalFooter>
        <ModalActions>
          <Button variant="secondary" onClick={onSaveDeactivateConfirm} data-test="save-deactivate-modal-save">
            {formatMessage(adminOneTimeEmailCampaignTemplatesMessages.saveDeactivate)}
          </Button>
          <Button
            data-test="save-deactivate-modal-discard"
            variant="primary-warning"
            onClick={onDiscardDeactivateConfirm}
            disabled={isLoading}
          >
            {formatMessage(adminOneTimeEmailCampaignTemplatesMessages.discardDeactivate)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
