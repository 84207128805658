import { skipToken } from '@reduxjs/toolkit/dist/query'
import {
  useCreateAdminEmailCampaignTemplateMutation,
  useGetAdminEmailCampaignTemplateQuery,
  useUpdateAdminEmailCampaignTemplateMutation,
  type OneTimeEmailCampaignTemplate,
} from '@sevenrooms/core/api'
import { OneTimeEmailCampaignStatusEnum } from '@sevenrooms/core/domain'
import { useDestination } from '@sevenrooms/core/navigation'
import { Loader } from '@sevenrooms/core/ui-kit/layout'
import { routes } from '@sevenrooms/routes'
import { AdminOneTimeEmailCampaignTemplateForm } from '../AdminOneTimeEmailCampaignTemplateForm'

export interface AdminOneTimeEmailCampaignTemplateProps {}

export function AdminOneTimeEmailCampaignTemplate() {
  const { params: updateParams } = useDestination(routes.admin.oneTimeEmailCampaignsTemplates.edit)
  const { params: duplicateParams } = useDestination(routes.admin.oneTimeEmailCampaignsTemplates.duplicate)

  const duplicateEmailCampaignId = duplicateParams ? duplicateParams.templateId : null
  const getEmailCampaignTemplateId = updateParams ? updateParams.templateId : null
  const emailCampaignTemplateId = getEmailCampaignTemplateId || duplicateEmailCampaignId

  const { data: emailCampaignTemplate, isFetching } = useGetAdminEmailCampaignTemplateQuery(
    emailCampaignTemplateId ? { emailCampaignTemplateId } : skipToken
  )

  const [updateAdminEmailCampaignTemplate, { isLoading: isUpdateAdminEmailTemplateUpdating }] =
    useUpdateAdminEmailCampaignTemplateMutation()

  const [createAdminEmailCampaignTemplate, { isLoading: isCreateAdminEmailTemplateUpdating }] =
    useCreateAdminEmailCampaignTemplateMutation()

  const isViewMode = emailCampaignTemplate?.status === OneTimeEmailCampaignStatusEnum.INACTIVE && !duplicateEmailCampaignId
  const isUpdating = isUpdateAdminEmailTemplateUpdating || isCreateAdminEmailTemplateUpdating

  return isFetching ? (
    <Loader />
  ) : (
    <AdminOneTimeEmailCampaignTemplateForm
      isViewMode={isViewMode}
      emailCampaignTemplate={emailCampaignTemplate as OneTimeEmailCampaignTemplate}
      isUpdating={isUpdating}
      duplicateEmailCampaignId={duplicateEmailCampaignId}
      getEmailCampaignTemplateId={getEmailCampaignTemplateId}
      /* eslint-disable @typescript-eslint/no-explicit-any */
      onEmailCampaignTemplateCreate={(emailCampaignTemplate: any): any =>
        createAdminEmailCampaignTemplate({ emailCampaignTemplate }).unwrap()
      }
      onEmailCampaignTemplateUpdate={(emailCampaignTemplate: any): any =>
        updateAdminEmailCampaignTemplate({ emailCampaignTemplate, emailCampaignTemplateIds: [emailCampaignTemplate.id] }).unwrap()
      }
      /* eslint-enable @typescript-eslint/no-explicit-any */
    />
  )
}
