import { useMemo } from 'react'
import type { OngoingEmailCampaignTemplate } from '@sevenrooms/core/api'
import { MarketingCampaignRegionEnum } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'

export type AdminEmailDetailsFormData = ZodSchema<typeof useAdminEmailDetailsForm>
export type AdminEmailDetailsFormField = Field<AdminEmailDetailsFormData>

export function useAdminEmailDetailsForm() {
  const { formatMessage } = useLocales()
  const requiredMessage = formatMessage(commonMessages.required)

  return useMemo(
    () =>
      z.object({
        campaignName: z.string().trim().min(1, requiredMessage),
        campaignDescription: z.string().trim().min(1, requiredMessage),
        emailReplyTo: z.string().trim().min(1, requiredMessage),
        campaignProductType: z.string().nullable(),
        campaignCategory: z.string().nullable(),
        campaignVenueType: z.string().nullable(),
        campaignRegion: z.nativeEnum(MarketingCampaignRegionEnum).nullable(),
      }),
    [requiredMessage]
  )
}

export const getAdminEmailDetailsDefaultValues = (source?: OngoingEmailCampaignTemplate): AdminEmailDetailsFormData => ({
  campaignName: source?.campaignName ?? '',
  campaignDescription: source?.campaignDescription ?? '',
  emailReplyTo: source?.emailReplyTo ?? '<sender_email>',
  campaignProductType: source?.campaignProductType ?? null,
  campaignCategory: source?.campaignCategory ?? null,
  campaignVenueType: source?.campaignVenueType ?? null,
  campaignRegion: (source?.campaignRegion as MarketingCampaignRegionEnum) ?? null,
})
