import { useState } from 'react'
import { CollapseButton } from '@sevenrooms/core/ui-kit/form'
import { Box, Collapsible, Flex, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import type { KeyValueType } from './interfaces'

export function niceJson(data: string | object[]): string {
  try {
    return JSON.stringify(Array.isArray(data) ? data : JSON.parse(data), null, 2)
  } catch (e) {
    return data.toString()
  }
}

export function DisplayResultEntityProperties(props: { title: string; items: KeyValueType; shown?: boolean }) {
  const { title, items, shown } = props
  const [collapsed, setCollapsed] = useState(!shown)

  return (
    <Box>
      <HStack pl="sm" pr="sm" onClick={() => setCollapsed(!collapsed)}>
        <Flex alignItems="stretch" flex="auto 1">
          <Flex flexGrow={1} pt="m">
            <Text textStyle="h4">{title}</Text>
          </Flex>
          <CollapseButton collapsed={collapsed} />
        </Flex>
      </HStack>
      <Collapsible collapsed={collapsed}>
        <VStack p="sm">
          {Object.entries(items).map(([key, value]) => (
            <HStack spacing="s" key={key}>
              <Box>
                <Text fontWeight="bold">{key}</Text>:
              </Box>
              <pre>{niceJson(value)}</pre>
            </HStack>
          ))}
        </VStack>
      </Collapsible>
    </Box>
  )
}
