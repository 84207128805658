import { useMemo, useEffect } from 'react'
import type { VenuePackageProvisioningResponse } from '@sevenrooms/core/api'
import { useForm, useWatchMany } from '@sevenrooms/core/form'
import { Route, Switch } from '@sevenrooms/core/navigation'
import { Form } from '@sevenrooms/core/ui-kit/form'
import { routes } from '@sevenrooms/routes'
import { usePackageProvisioningForm } from './PackageProvisioning.zod'
import { PackageProvisioningComponentForm } from './PackageProvisioningForm'
import { PackageProvisioningReviewForm } from './PackageProvisioningReviewForm'

interface VenuePackageProvisioningProps {
  venueId: string
  venueName: string
  data: VenuePackageProvisioningResponse
}
export function VenuePackageProvisioning({ venueId, venueName, data }: VenuePackageProvisioningProps) {
  const packageProvisioningForm = usePackageProvisioningForm()
  const defaultValues = useMemo(
    () => ({
      packageProvisioning: data?.packageProvisioning,
      addonApi: data?.addons.api,
      addonOnlineOrdering: data?.addons.onlineOrdering,
      addonEmailCampaigns: data?.addons.emailCampaigns,
      addonPms: data?.addons.pms,
      addonSmsMarketing: data?.addons.smsMarketing,
      addonPremiumIntegrations: data?.addons.premiumIntegrations,
      betas: data?.betas,
    }),
    [data]
  )
  const form = useForm(packageProvisioningForm, {
    defaultValues,
    mode: 'onChange',
  })
  const { field, reset } = form
  const [
    selectedPackage,
    selectedBetas,
    apiEnabled,
    onlineOrderingEnabled,
    emailCampaignsEnabled,
    pmsEnabled,
    smsMarketingEnabled,
    selectedPremiumIntegrations,
  ] = useWatchMany(field, [
    'packageProvisioning',
    'betas',
    'addonApi',
    'addonOnlineOrdering',
    'addonEmailCampaigns',
    'addonPms',
    'addonSmsMarketing',
    'addonPremiumIntegrations',
  ])

  const selectedAddons = {
    api: apiEnabled,
    onlineOrdering: onlineOrderingEnabled,
    emailCampaigns: emailCampaignsEnabled,
    pms: pmsEnabled,
    smsMarketing: smsMarketingEnabled,
    premiumIntegrations: selectedPremiumIntegrations,
  }

  // Reset the form state when new defaultValues are determined,
  // which happens after data is invalidated and re-fetched
  useEffect(() => {
    reset(defaultValues)
  }, [reset, defaultValues])

  return (
    <>
      {data && (
        <Form {...form} onSubmit={() => {}} onInvalid={() => {}}>
          <Switch>
            <Route path={routes.admin.packageProvisioning.review.path}>
              <PackageProvisioningReviewForm
                venueId={venueId}
                venueName={venueName}
                currentPackage={data.packageProvisioning}
                newPackage={selectedPackage}
                selectedBetas={selectedBetas}
                selectedAddons={selectedAddons}
              />
            </Route>
            <Route path={routes.admin.packageProvisioning.path}>
              <PackageProvisioningComponentForm
                packageProvisioningData={data}
                venueId={venueId}
                venueName={venueName}
                selectedPackage={selectedPackage}
                selectedBetas={selectedBetas}
              />
            </Route>
          </Switch>
        </Form>
      )}
    </>
  )
}
