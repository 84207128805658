import { defineMessages } from '@sevenrooms/core/locales'

export const adminManageBlocklistMessages = defineMessages({
  title: {
    id: 'admin.manageBlocklist.title',
    defaultMessage: 'Manage Email Blocklist',
  },
  emailLabel: {
    id: 'admin.manageBlocklist.emailLabel',
    defaultMessage: 'Email',
  },
  unblockButton: {
    id: 'admin.manageBlocklist.unblockButton',
    defaultMessage: 'Unblock {email}',
  },
  checkBlockStatusButton: {
    id: 'admin.manageBlocklist.checkBlockStatusButton',
    defaultMessage: 'Check Block Status',
  },
  blockStatusLabel: {
    id: 'admin.manageBlocklist.blockStatus',
    defaultMessage: '{email} is blocked',
  },
  blockStatusSublabel: {
    id: 'admin.manageBlocklist.blockStatusSublabel',
    defaultMessage:
      'They will not receive any emails from SevenRooms until they are unblocked, either manually or automatically once the block expires.',
  },
  unblockedStatusLabel: {
    id: 'admin.manageBlocklist.unblockedStatus',
    defaultMessage: '{email} is not blocked',
  },
  unblockedStatusSublabel: {
    id: 'admin.manageBlocklist.unblockedStatusSublabel',
    defaultMessage: 'They should receive emails from SevenRooms.',
  },
  blockUpdatedAt: {
    id: 'admin.manageBlocklist.blockUpdatedAt',
    defaultMessage: 'Blocked on {date} UTC',
  },
  blockExpiresAt: {
    id: 'admin.manageBlocklist.blockExpiresAt',
    defaultMessage: `Block expires {date, select, NEVER {never} other {on {date} UTC}}`,
  },
  toastUnblockSuccess: {
    id: 'admin.manageBlocklist.toastUnblockSuccess',
    defaultMessage: 'Successfully unblocked {email}',
  },
  toastError: {
    id: 'admin.manageBlocklist.toastError',
    defaultMessage: 'Something went wrong...',
  },
})
