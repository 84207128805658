import { useCallback } from 'react'
import type { EmailCampaignTemplate } from '@sevenrooms/core/api'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { VStack, HStack, Box, Modal, ModalHeader, ModalTitle, ModalFooter, ModalActions } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { adminOneTimeEmailCampaignTemplatesMessages } from '../../locales'

export interface AdminOneTimeEmailCampaignTemplateDuplicateModalProps {
  closeHref: string
  onPublish: (emailCampaignTemplate: EmailCampaignTemplate) => void
  onSaveInAdmin: (emailCampaignTemplate: EmailCampaignTemplate) => void
  isLoading: boolean
  emailCampaignTemplate: EmailCampaignTemplate
}

export function AdminOneTimeEmailCampaignTemplateDuplicateModal({
  closeHref,
  onPublish,
  onSaveInAdmin,
  isLoading,
  emailCampaignTemplate,
}: AdminOneTimeEmailCampaignTemplateDuplicateModalProps) {
  const { formatMessage } = useLocales()

  const onSaveInAdminConfirm = useCallback(() => {
    onSaveInAdmin(emailCampaignTemplate)
  }, [onSaveInAdmin, emailCampaignTemplate])

  const onPublishConfirm = useCallback(() => {
    onPublish(emailCampaignTemplate)
  }, [onPublish, emailCampaignTemplate])

  return (
    <Modal data-test="duplicate-modal" ariaLabel={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.duplicateModalTitle)}>
      <ModalHeader closeHref={closeHref}>
        <VStack spacing="s">
          <HStack spacing="s">
            <Box>
              <Icon name="VMSWeb-warning" color="error" size="lg" />
            </Box>
            <Text color="error" fontWeight="bold">
              {formatMessage(commonMessages.warning)}
            </Text>
          </HStack>
          <ModalTitle title={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.duplicateModalTitle)} />
        </VStack>
      </ModalHeader>

      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" href={closeHref} data-test="duplicate-modal-cancel">
            {formatMessage(commonMessages.cancel)}
          </Button>
          <Button variant="secondary" href={closeHref} onClick={onSaveInAdminConfirm} data-test="duplicate-modal-save-in-admin">
            {formatMessage(adminOneTimeEmailCampaignTemplatesMessages.saveChangesInAdmin)}
          </Button>
          <Button data-test="duplicate-modal-save-and-publish" variant="primary" onClick={onPublishConfirm} disabled={isLoading}>
            {formatMessage(commonMessages.saveAndPublish)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
