import { render } from 'react-dom'
import { AdminPage } from '@sevenrooms/admin'
import { svrExport } from '@sevenrooms/core/ui-kit/utils'
import { PaymentsDebugger } from './components'

function renderPaymentsDebugger(id: string, venueId: string) {
  return render(
    <AdminPage>
      <PaymentsDebugger venueId={venueId} />
    </AdminPage>,
    document.getElementById(id)
  )
}

svrExport('PaymentsDebugger', 'render', renderPaymentsDebugger)
