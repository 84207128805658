import { createApi } from '@reduxjs/toolkit/query/react'
import { RTKUtils } from '@sevenrooms/core/api'
import { ManageBlocklistService } from '../services'
import type { BlockStatus } from '../domain'

export const manageBlocklistApi = createApi({
  reducerPath: 'admin.manageBlocklist',
  tagTypes: ['ManageBlocklist'],
  baseQuery: RTKUtils.noBaseQuery,
  endpoints: builder => ({
    getEmailStatus: builder.query<BlockStatus, string>({
      providesTags: ['ManageBlocklist'],
      queryFn: RTKUtils.rtkQuery(ManageBlocklistService.getEmailStatus),
    }),
    unblockEmail: builder.mutation<BlockStatus, string>({
      queryFn: RTKUtils.rtkQuery(ManageBlocklistService.unblockEmail),
    }),
  }),
})

export const { useLazyGetEmailStatusQuery, useUnblockEmailMutation } = manageBlocklistApi
