import { defineMessages } from '@sevenrooms/core/locales'

export const adminVenueGroupMergeMessages = defineMessages({
  venueGroupMergeTitle: {
    id: 'admin.venueGroupMerge.venueGroupMergeTitle',
    defaultMessage: 'Admin Venue Group Merge',
  },
  completedTrackerLabel: {
    id: 'admin.venueGroupMerge.completedTrackerLabel',
    defaultMessage: 'Yes, I filled out the Venue Group Merge Tracker',
  },
  agreedToScheduledMergeLabel: {
    id: 'admin.venueGroupMerge.agreedToScheduledMergeLabel',
    defaultMessage: 'Yes, this is part of a scheduled merge with consent from the merging groups',
  },
  venueGroupMergeWarning: {
    id: 'admin.venueGroupMerge.venueGroupMergeWarning',
    defaultMessage:
      "WARNING!! Merging two venue groups can be extremely dangerous. This merge will attempt to combine all of the existing venues and their data from the origin group into the destination group. Core entities across groups like client profiles will be merged while other entities will be left behind. Only do this if you're absolutely certain it's the right path forward as there's no ability to undo the merge once activated. This should be done in close collaboration with the merging venue groups and the Sevenrooms product team as part of a scheduled plan. Manual prep work before and after merge clean-up is likely required.",
  },
  readThingsToKnowLabel: {
    id: 'admin.venueGroupMerge.readThingsToKnowLabel',
    defaultMessage: 'Read the "Things to know" tab and fill out the google sheet ',
  },
  venueGroupMergeTrackerLink: {
    id: 'admin.venueGroupMerge.venueGroupMergeTrackerLink',
    defaultMessage: 'Venue Group Merge Tracker',
  },
  carryOnText: {
    id: 'admin.venueGroupMerge.carryOnText',
    defaultMessage: "If this is a scheduled move and you know what you're doing, then carry on!",
  },
  venueGroupMergeButtonText: {
    id: 'admin.venueGroupMerge.venueGroupMergeButtonText',
    defaultMessage: 'Merge Venue Groups',
  },
  sourceVenueGroupRequiredMsg: {
    id: 'admin.venueGroupMerge.sourceVenueGroupRequiredMsg',
    defaultMessage: 'Source Venue Group required',
  },
  destinationVenueGroupRequiredMsg: {
    id: 'admin.venueGroupMerge.destinationVenueGroupRequiredMsg',
    defaultMessage: 'Destination Venue Group required',
  },
  sameVenueGroupMsg: {
    id: 'admin.venueGroupMerge.sameVenueGroupMsg',
    defaultMessage: "Destination Venue Group can't be the same as the source",
  },
  trackerRequiredMsg: {
    id: 'admin.venueGroupMerge.trackerRequiredMsg',
    defaultMessage: 'Tracker must be filled out to proceed with venue group merge',
  },
  mergeConsentRequireMsg: {
    id: 'admin.venueGroupMerge.mergeConsentRequireMsg',
    defaultMessage: 'Merge must be scheduled with consent from groups to proceed with venue group merge',
  },
} as const)
