import { useCallback } from 'react'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { VStack, HStack, Box, Modal, ModalHeader, ModalTitle, ModalFooter, ModalActions, ModalBody } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { adminOneTimeEmailCampaignTemplatesMessages } from '../../locales'

export interface AdminOneTimeEmailCampaignTemplatePublishModalProps {
  closeHref: string
  onPublish: () => void
  isLoading: boolean
  templateName: string
}

export function AdminOneTimeEmailCampaignTemplatePublishModal({
  closeHref,
  onPublish,
  isLoading,
  templateName,
}: AdminOneTimeEmailCampaignTemplatePublishModalProps) {
  const { formatMessage } = useLocales()

  const onPublishConfirm = useCallback(() => {
    onPublish()
  }, [onPublish])

  return (
    <Modal
      data-test="publish-modal"
      ariaLabel={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.publishModalTitle, { templateName })}
    >
      <ModalHeader closeHref={closeHref}>
        <VStack spacing="s">
          <HStack spacing="s">
            <Box>
              <Icon name="VMSWeb-warning" color="error" size="lg" />
            </Box>
            <Text color="error" fontWeight="bold">
              {formatMessage(commonMessages.warning)}
            </Text>
          </HStack>
          <ModalTitle
            title={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.publishModalTitle, {
              templateName,
            })}
          />
        </VStack>
      </ModalHeader>

      <ModalBody>
        <Text>{formatMessage(adminOneTimeEmailCampaignTemplatesMessages.publishModalBody)}</Text>
      </ModalBody>

      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" href={closeHref} data-test="publish-modal-cancel">
            {formatMessage(commonMessages.cancel)}
          </Button>
          <Button data-test="publish-modal-confirm" variant="primary" onClick={onPublishConfirm} disabled={isLoading}>
            {formatMessage(commonMessages.saveAndPublish)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
