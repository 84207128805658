import { render } from 'react-dom'
import { AdminPage } from '@sevenrooms/admin'
import { svrExport } from '@sevenrooms/core/ui-kit/utils'
import { VenuePhoneNumbers } from './components'

function renderVenuePhoneNumbers(id: string, venueGroupId: string, venueId: string, venueName: string) {
  return render(
    <AdminPage>
      <VenuePhoneNumbers venueGroupId={venueGroupId} venueId={venueId} venueName={venueName} />
    </AdminPage>,
    document.getElementById(id)
  )
}

svrExport('VenuePhoneNumbers', 'render', renderVenuePhoneNumbers)
