import { useState } from 'react'
import { AdminPageContent, AdminPageMeta } from '@sevenrooms/admin/components'
import type { VenuePackageProvisioningResponse } from '@sevenrooms/core/api'
import { type UseForm, useFormContext, type z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Toggle } from '@sevenrooms/core/ui-kit/core'
import { FormSelect, FormMultiSelect, Button, ErrorMessage, Label, FormToggle } from '@sevenrooms/core/ui-kit/form'
import { Box, Breadcrumbs, DividerLine, BaseSection, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { routes } from '@sevenrooms/routes'
import { adminVenuePackageProvisioningMessages } from '../../locales'
import type { PackageProvisioningFormSchema } from './PackageProvisioning.zod'

interface ProductProvisioningFormProps {
  packageProvisioningData: VenuePackageProvisioningResponse
  venueId: string
  venueName: string
  selectedPackage: string
  selectedBetas: string[]
}

export function PackageProvisioningComponentForm({
  packageProvisioningData,
  venueId,
  venueName,
  selectedPackage,
  selectedBetas,
}: ProductProvisioningFormProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const [showPremiumIntegrations, setShowPremiumIntegrations] = useState(
    packageProvisioningData.addons.premiumIntegrations && packageProvisioningData.addons.premiumIntegrations.length > 0
  )
  const [previousPremiumIntegrations, setPreviousPremiumIntegrations] = useState(packageProvisioningData.addons.premiumIntegrations)
  const {
    field,
    formState: { isDirty },
    getValues,
    setValue,
  } = useFormContext() as UseForm<z.ZodType<PackageProvisioningFormSchema>>

  const onTogglePremiumIntegrations = () => {
    if (showPremiumIntegrations) {
      setPreviousPremiumIntegrations(getValues('addonPremiumIntegrations'))
      setValue('addonPremiumIntegrations', [], { shouldDirty: true })
    } else {
      setValue('addonPremiumIntegrations', previousPremiumIntegrations, { shouldDirty: true })
    }
    setShowPremiumIntegrations(!showPremiumIntegrations)
  }

  const allowedBetasForPackage = packageProvisioningData.betaChoicesByPackage[selectedPackage] || []
  const betasToBeRemoved = packageProvisioningData.betaChoiceList.filter(
    c => !allowedBetasForPackage.some(a => a.id === c.id) && selectedBetas?.includes(c.id)
  )

  return (
    <>
      <AdminPageMeta title={formatMessage(adminVenuePackageProvisioningMessages.title)} />
      <AdminPageContent
        breadcrumbs={
          <Breadcrumbs>
            <Button
              href={nav.href(routes.admin.venueEdit, { params: { venueId } })}
              variant="tertiary"
              target="_self"
              isExternal
              noPadding
              data-test="venue-configuration-button"
            >
              {formatMessage(adminVenuePackageProvisioningMessages.venueConfigurationBreadcrumb)}
            </Button>
            <Text>{formatMessage(adminVenuePackageProvisioningMessages.title)}</Text>
          </Breadcrumbs>
        }
        title={formatMessage(adminVenuePackageProvisioningMessages.modifyPageTitle)}
        actions={
          <Button
            disabled={!isDirty || !!betasToBeRemoved.length}
            variant="primary"
            onClick={() => {
              nav.push(routes.admin.packageProvisioning.review, {
                params: { venueId },
              })
            }}
            data-test="venue-provisioning-review-button"
          >
            {formatMessage(adminVenuePackageProvisioningMessages.reviewButtonText)}
          </Button>
        }
      >
        <VStack pt="l" spacing="l">
          <h1>{venueName}</h1>
          <BaseSection
            title={formatMessage(adminVenuePackageProvisioningMessages.package)}
            description={formatMessage(adminVenuePackageProvisioningMessages.viewPackageBreakdown)}
          >
            <VStack p="lm" spacing="xs">
              <Box pb="lm">
                <DividerLine margin="none" />
              </Box>
              <Label
                width="50%"
                primary={formatMessage(adminVenuePackageProvisioningMessages.package)}
                secondary={formatMessage(adminVenuePackageProvisioningMessages.packageWarning)}
              >
                <FormSelect
                  options={packageProvisioningData.packageProvisioningChoiceList}
                  field={field.prop('packageProvisioning')}
                  data-test="package-provisioning-select"
                  hideFilterIcon
                />
              </Label>
            </VStack>
          </BaseSection>
          <BaseSection
            title={formatMessage(adminVenuePackageProvisioningMessages.addons)}
            description={formatMessage(adminVenuePackageProvisioningMessages.viewAddonBreakdown)}
          >
            <VStack p="lm" spacing="s">
              <Box pb="lm">
                <DividerLine margin="none" />
              </Box>
              <FormToggle label={formatMessage(adminVenuePackageProvisioningMessages.addonApi)} field={field.prop('addonApi')} />
              <FormToggle
                label={formatMessage(adminVenuePackageProvisioningMessages.addonOnlineOrdering)}
                field={field.prop('addonOnlineOrdering')}
              />
              <FormToggle
                label={formatMessage(adminVenuePackageProvisioningMessages.addonEmailCampaigns)}
                field={field.prop('addonEmailCampaigns')}
              />
              <FormToggle label={formatMessage(adminVenuePackageProvisioningMessages.addonPms)} field={field.prop('addonPms')} />
              <FormToggle
                label={formatMessage(adminVenuePackageProvisioningMessages.addonSmsMarketing)}
                field={field.prop('addonSmsMarketing')}
              />
              <VStack pt="s" spacing="s" width="50%">
                <Toggle
                  label={formatMessage(adminVenuePackageProvisioningMessages.addonPremiumIntegrations)}
                  description={formatMessage(adminVenuePackageProvisioningMessages.selectMultiple)}
                  onChange={onTogglePremiumIntegrations}
                  checked={showPremiumIntegrations}
                />
                {showPremiumIntegrations && (
                  <FormMultiSelect options={packageProvisioningData.addonsChoiceList} field={field.prop('addonPremiumIntegrations')} />
                )}
              </VStack>
            </VStack>
          </BaseSection>
          <BaseSection
            title={formatMessage(adminVenuePackageProvisioningMessages.betas)}
            description={formatMessage(adminVenuePackageProvisioningMessages.viewBetasBreakdown)}
          >
            <VStack p="lm" spacing="s">
              <Box pb="lm">
                <DividerLine margin="none" />
              </Box>
              <Label
                primary={formatMessage(adminVenuePackageProvisioningMessages.betasAvailable)}
                secondary={formatMessage(adminVenuePackageProvisioningMessages.selectMultiple)}
                width="50%"
              >
                <FormMultiSelect options={allowedBetasForPackage} field={field.prop('betas')} />
                {betasToBeRemoved.map(beta => (
                  <ErrorMessage key={beta.id}>
                    {formatMessage(adminVenuePackageProvisioningMessages.betaUnavailable, { name: beta.label })}
                  </ErrorMessage>
                ))}
              </Label>
            </VStack>
          </BaseSection>
        </VStack>
      </AdminPageContent>
    </>
  )
}
