import { defineMessages } from '@sevenrooms/core/locales'

export const adminOneTimeEmailCampaignTemplatesMessages = defineMessages({
  title: {
    id: 'admin.oneTimeEmailCampaignTemplates.title',
    defaultMessage: 'Email Campaign Templates',
  },
  createNewTemplate: {
    id: 'admin.oneTimeEmailCampaignTemplates.createNewTemplate',
    defaultMessage: 'Create New Template',
  },
  inactiveDraftTemplates: {
    id: 'admin.oneTimeEmailCampaignTemplates.inactiveDraftTemplates',
    defaultMessage: 'Inactive/Draft Templates',
  },
  VMSLiveTemplates: {
    id: 'admin.oneTimeEmailCampaignTemplates.VMSLiveTemplates',
    defaultMessage: 'VMS Live Templates',
  },
  archivedTemplates: {
    id: 'admin.oneTimeEmailCampaignTemplates.archivedTemplates',
    defaultMessage: 'Archived Templates',
  },
  categoryFilterPlaceholder: {
    id: 'admin.oneTimeEmailCampaignTemplates.categoryFilterPlaceholder',
    defaultMessage: 'Filter by category',
  },
  searchPlaceholder: {
    id: 'admin.oneTimeEmailCampaignTemplates.searchPlaceholder',
    defaultMessage: 'Search by template name',
  },
  edit: {
    id: 'admin.oneTimeEmailCampaignTemplates.edit',
    defaultMessage: 'Edit Template',
  },
  view: {
    id: 'admin.oneTimeEmailCampaignTemplates.edit',
    defaultMessage: 'View Template',
  },
  lastEdited: {
    id: 'admin.oneTimeEmailCampaignTemplates.lastEdited',
    defaultMessage: 'Last edited by:',
  },
  filtersEmptyState: {
    id: 'admin.oneTimeEmailCampaignTemplates.filtersEmptyState',
    defaultMessage: 'No email campaign templates match the selected filters',
  },
  newsletter: {
    id: 'admin.oneTimeEmailCampaignTemplates.newsletter',
    defaultMessage: 'Newsletter',
  },
  promotion: {
    id: 'admin.oneTimeEmailCampaignTemplates.promotion',
    defaultMessage: 'Promotion',
  },
  announcement: {
    id: 'admin.oneTimeEmailCampaignTemplates.announcement',
    defaultMessage: 'Announcement',
  },
  loyalty: {
    id: 'admin.oneTimeEmailCampaignTemplates.loyalty',
    defaultMessage: 'Loyalty',
  },
  holidaySeasonal: {
    id: 'admin.oneTimeEmailCampaignTemplates.holidaySeasonal',
    defaultMessage: 'Holiday & Seasonal',
  },
  postCard: {
    id: 'admin.oneTimeEmailCampaignTemplates.postCard',
    defaultMessage: 'Post Card',
  },
  singleColumnLayout: {
    id: 'admin.oneTimeEmailCampaignTemplates.singleColumnLayout',
    defaultMessage: 'Single Column Layout',
  },
  doubleColumnLayout: {
    id: 'admin.oneTimeEmailCampaignTemplates.doubleColumnLayout',
    defaultMessage: 'Double Column Layout',
  },
  navigationBar: {
    id: 'admin.oneTimeEmailCampaignTemplates.navigationBar',
    defaultMessage: 'Navigation Bar',
  },
  templateDetails: {
    id: 'admin.emailCampaignsTemplates.templateDetails',
    defaultMessage: 'Template Details',
  },
  templateNameTitle: {
    id: 'admin.emailCampaignsTemplates.templateNameTitle',
    defaultMessage: 'Template Name',
  },
  templateNameDescription: {
    id: 'admin.emailCampaignsTemplates.templateNameDescription',
    defaultMessage: 'This will not be client-facing.',
  },
  templateCategoryTitle: {
    id: 'admin.emailCampaignsTemplates.templateCategoryTitle',
    defaultMessage: 'Template Category',
  },
  templateCategoryDescription: {
    id: 'admin.emailCampaignsTemplates.templateCategoryDescription',
    defaultMessage: 'What are some use cases venue clients can use this template for?',
  },
  templateDesign: {
    id: 'admin.emailCampaignsTemplates.templateDesign',
    defaultMessage: 'Template Design',
  },
  discardDraft: {
    id: 'admin.emailCampaignsTemplates.discardDraft',
    defaultMessage: 'Discard Draft',
  },
  publishModalTitle: {
    id: 'admin.emailCampaignsTemplates.publishModalTitle',
    defaultMessage: 'Want to publish {templateName} to VMS?',
  },
  publishModalBody: {
    id: 'admin.emailCampaignsTemplates.publishModalTitle',
    defaultMessage:
      'This template will immediately display in the VMS template gallery. Please double-check the content before publishing.',
  },
  updateWarningBanner: {
    id: 'admin.emailCampaignsTemplates.updateWarningBanner',
    defaultMessage: 'When changes are saved and published, the template will be updated in VMS side immediately.',
  },
  archiveModalTitle: {
    id: 'admin.emailCampaignsTemplates.archiveModalTitle',
    defaultMessage: 'Are you sure you want to archive this template?',
  },
  unarchiveModalTitle: {
    id: 'admin.emailCampaignsTemplates.unarchiveModalTitle',
    defaultMessage: 'Unarchiving the template will transition it to the Draft state.',
  },
  duplicateAsDraft: {
    id: 'admin.emailCampaignsTemplates.duplicateAsDraft',
    defaultMessage: 'Duplicate As Draft',
  },
  deactivateModalTitle: {
    id: 'admin.emailCampaignsTemplates.deactivateModalTitle',
    defaultMessage: 'Are you sure you want to deactivate the template?',
  },
  deactivateModalBody: {
    id: 'admin.emailCampaignsTemplates.deactivateModalBody',
    defaultMessage:
      'Deactivating the template will remove it from the gallery in VMS and change its status to “Draft”. It will not affect any email campaigns built from this template.',
  },
  deactivateTemplate: {
    id: 'admin.emailCampaignsTemplates.deactivateTemplate',
    defaultMessage: 'Deactivate template',
  },
  saveDeactivateModalTitle: {
    id: 'admin.emailCampaignsTemplates.saveDeactivateModalTitle',
    defaultMessage: 'Do you want to save the changes before deactivating the template?',
  },
  saveDeactivate: {
    id: 'admin.emailCampaignsTemplates.saveDeactivate',
    defaultMessage: 'Save Changes & Deactivate',
  },
  discardDeactivate: {
    id: 'admin.emailCampaignsTemplates.discardDeactivate',
    defaultMessage: 'Discard Changes & Deactivate',
  },
  templateStatus: {
    id: 'admin.emailCampaignsTemplates.templateStatus',
    defaultMessage: 'Template Status:',
  },
  lastChangesPushed: {
    id: 'admin.emailCampaignsTemplates.lastChangesPushed',
    defaultMessage: 'Last pushed changes to VMS:',
  },
  saveChangesInAdmin: {
    id: 'admin.emailCampaignsTemplates.saveChangesInAdmin',
    defaultMessage: 'Save changes in Admin',
  },
  duplicateModalTitle: {
    id: 'admin.emailCampaignsTemplates.duplicateModalTitle',
    defaultMessage: 'Want to save changes in Admin or publish changes to VMS before duplicating this template?',
  },
  designYourEmailContent: {
    id: 'admin.emailCampaignsTemplates.designYourEmailContent',
    defaultMessage: 'Design Your Email Content',
  },
  designYourEmailContentDescription: {
    id: 'admin.emailCampaignsTemplates.designYourEmailContentDescription',
    defaultMessage: 'Start constructing your email content by clicking in the editor below.',
  },
  sendTestEmailButtonText: {
    id: 'admin.emailCampaignsTemplates.sendATestEmailButtonText',
    defaultMessage: 'Send test email',
  },
  publishSuccess: {
    id: 'admin.emailCampaignsTemplates.publishSuccess',
    defaultMessage: '{title} has been successfully published to VMS.',
  },
  publishError: {
    id: 'admin.emailCampaignsTemplates.publishError',
    defaultMessage: 'Error during publishing {title} to VMS.',
  },
  saveSuccess: {
    id: 'admin.emailCampaignsTemplates.saveSuccess',
    defaultMessage: 'Changes made in {title} has been successfully saved in Admin.',
  },
  saveError: {
    id: 'admin.emailCampaignsTemplates.saveError',
    defaultMessage: 'Error during saving {title} in Admin.',
  },
  deactivateSuccess: {
    id: 'admin.emailCampaignsTemplates.deactivateSuccess',
    defaultMessage: '{title} is successfully deactivated.',
  },
  deactivateError: {
    id: 'admin.emailCampaignsTemplates.deactivateError',
    defaultMessage: 'Error during deactivcation of {title}.',
  },
  archiveSuccess: {
    id: 'admin.emailCampaignsTemplates.archiveSuccess',
    defaultMessage: '{title} has been successfully archived.',
  },
  archiveError: {
    id: 'admin.emailCampaignsTemplates.archiveError',
    defaultMessage: 'Error during archiving {title}.',
  },
  unarchiveSuccess: {
    id: 'admin.emailCampaignsTemplates.unarchiveSuccess',
    defaultMessage: '{title} has been successfully unarchived.',
  },
  unarchiveError: {
    id: 'admin.emailCampaignsTemplates.unarchiveError',
    defaultMessage: 'Error during unarchiving {title}.',
  },
  copyOf: {
    id: 'admin.emailCampaignsTemplates.copyOf',
    defaultMessage: 'Copy of',
  },
  live: {
    id: 'admin.emailCampaignsTemplates.live',
    defaultMessage: 'Live',
  },
  unpublished: {
    id: 'admin.emailCampaignsTemplates.unpublished',
    defaultMessage: 'Live - Changes Unpublished',
  },
  adminSubjectLine: {
    id: 'admin.emailCampaignsTemplates.adminSubjectLine',
    defaultMessage: 'INSERT SUBJECT LINE',
  },
} as const)
